import React, { useEffect } from "react";
import MUIDataTable from "mui-datatables";
import MiniDrawer from "../components/Minidrawer";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import report_loader from "../Media/ezgif.com-optimize.gif";
import dayjs from "dayjs";
import moment from "moment";
import { DatePicker, Space } from "antd";
import { kae_planning } from "../Redux/actions";
// import styles from "../Styles/Table.module.css"
import styles from "../Styles/Kae.module.css";
import CountUp from "react-countup";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const { RangePicker } = DatePicker;

function KAE_planning() {
  const dateFormat = "DD-MM-YYYY";
  const fromattedDate = dayjs(new Date());
  let new_date = moment(fromattedDate.$d).format("YYYY-MM-DD");

  const [kae_data, setKae_data] = useState([]);
  const [selectedFromDate, setSelectedFromDate] = useState(dayjs(new Date()));
  const [send_from_date, setSend_from_Date] = useState(new_date);
  const [selectedToDate, setSelectedToDate] = useState(dayjs(new Date()));
  const [send_to_date, setSend_to_Date] = useState(new_date);
  const [showBtn, setShowBtn] = useState(false);
  const [oh_count, setOh_Count] = useState(0);

  const dispatch = useDispatch();
  const data = useSelector((state) => state.data.data);
  const check_loading = useSelector((state) => state.data.loading);
  const check_error = useSelector((state) => state.data.error);

  const storage = window.sessionStorage;
  let USER_GUID = storage.getItem("USER_GUID");
  let Role_id = storage.getItem("Role_id");

  const navigate = useNavigate();

  // React.useEffect(() => {
  //   if (USER_GUID == "" || USER_GUID == null) {
  //     navigate("/");
  //   }
  // }, [storage]);

  useEffect(() => {
    if(Role_id != "" && Role_id != null && Role_id != "null"){
    dispatch(
      kae_planning(setKae_data, send_from_date, send_to_date)
    );
  }
  }, []);

  const handleDateChange = (new_val) => {
    if (new_val !== null) {
      setSelectedFromDate(new_val[0]);
      setSelectedToDate(new_val[1]);
      let newStartDate = moment(new_val[0]?.$d)?.format("YYYY-MM-DD");
      let newEndDate = moment(new_val[1]?.$d)?.format("YYYY-MM-DD");
      setSend_from_Date(newStartDate);
      setSend_to_Date(newEndDate);
      setShowBtn(true);
    }

    // dispatch(operation_report(setOperationData, newStartDate, newEndDate));
  };



  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      setSelectedFromDate(dayjs(dates[0]?.$d))
      setSelectedToDate(dayjs(dates[1]?.$d))
      setSend_from_Date(dateStrings[0]);
      setSend_to_Date(dateStrings[1]);
      // setSelectedFromDate(dates[0]?.$d)
      // setSelectedToDate(dates[1]?.$d)
      setShowBtn(true);
    } else {
      setSelectedFromDate(dayjs(new Date()))
      setSelectedToDate(dayjs(new Date()))
      setSend_from_Date(new_date);
      setSend_to_Date(new_date);
      dispatch(
        kae_planning(setKae_data, new_date, new_date)
      );
    }
  };
  const rangePresets = [
    {
      label: 'Last Week',
      value: [dayjs().add(-7, 'd'), dayjs()],
    },
    {
      label: 'Last 14 Days',
      value: [dayjs().add(-14, 'd'), dayjs()],
    },
    {
      label: 'Last 30 Days',
      value: [dayjs().add(-30, 'd'), dayjs()],
    },
    {
      label: 'Last 90 Days',
      value: [dayjs().add(-90, 'd'), dayjs()],
    },
  ];

  

  const handleClick = () => {
    if(send_from_date == "" || send_to_date == ""){
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        text: "Please Select Dates",
        customClass: {
          htmlContainer: 'custom-html-container',
        },
      });
    }else{
      dispatch(kae_planning(setKae_data, send_from_date, send_to_date));
      setShowBtn(false);
    }

  };

  const columns = [
    {
      name: "total_oh_count",
      label: "Total OH Count",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row) => {
          return row == "" || row == null ? (
            <p
            // style={{ width: "80px", marginTop: "17px", textAlign: "center" }}
            >
              0
            </p>
          ) : (
            <p
            // style={{ width: "80px", marginTop: "17px", textAlign: "center" }}
            >
              {row}
            </p>
          );
        },
      },
    },
    {
      name: "on_time_count",
      label: "On Time Count",
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRender: (row) => {
          return row == "" || row == null ? (
            <p
            // style={{ width: "80px", marginTop: "17px", textAlign: "center" }}
            >
              0
            </p>
          ) : (
            <p
            // style={{ width: "80px", marginTop: "17px", textAlign: "center" }}
            >
              {row}
            </p>
          );
        },
      },
    },
    {
      name: "delay_count",
      label: "delay Count",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row) => {
          return row == "" || row == null ? (
            <p
            // style={{ width: "80px", marginTop: "17px", textAlign: "center" }}
            >
              0
            </p>
          ) : (
            <p
            // style={{ width: "80px", marginTop: "17px", textAlign: "center" }}
            >
              {row}
            </p>
          );
        },
      },
    },
    {
      name: "overdue_count",
      label: "Overdue Count",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row) => {
          return row == "" || row == null ? (
            <p
            // style={{ width: "80px", marginTop: "17px", textAlign: "center" }}
            >
              0
            </p>
          ) : (
            <p
            // style={{ width: "80px", marginTop: "17px", textAlign: "center" }}
            >
              {row}
            </p>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
  };

  return (
    <>
      <MiniDrawer />

      <div className="container-fluid">
        <div className="row">
          <h4 className="mt-4">KAE Insights Report</h4>
        </div>
        {check_loading ? (
          <img src={report_loader} />
        ) : (
          <>
            {" "}
            <div className="row mt-4">
              <div style={{ display: "inline-flex", justifyContent: "end" }}>
                <Space direction="vertical" size={12}>
                  {/* <RangePicker
                    // defaultValue={[dayjs(new Date()), dayjs(new Date())]}
                    format={dateFormat}
                    value={[selectedFromDate, selectedToDate]}
                    onChange={(new_val) => handleDateChange(new_val)}
                  /> */}
                        <RangePicker presets={rangePresets} value={[selectedFromDate, selectedToDate]} onChange={onRangeChange} />
                </Space>
                <button
                  className="btn btn-primary btn-sm ml-2"
                  onClick={handleClick}
                  style={{
                    display: showBtn ? "block" : "none",
                    marginLeft: "5px",
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
            <div className="row" id={styles.count_contain}>
              <div className="col-sm-12 col-md-2">
                <p>Total OH Count</p>
                {/* <h1><CountUp end={kae_data[0]?.total_oh_count} /></h1>  */}
                <h1>
                  <CountUp end={kae_data[0]?.total_oh_count} />
                </h1>
              </div>
              <div className="col-sm-12 col-md-2">
                <p>On Time Count</p>
                <h1>
                  <CountUp end={kae_data[0]?.on_time_count} />
                </h1>
              </div>
              <div className="col-sm-12 col-md-2">
                <p>Delay Count</p>
                <h1>
                  <CountUp end={kae_data[0]?.delay_count} />
                </h1>
              </div>
              <div className="col-sm-12 col-md-2">
                <p>Overdue Count</p>
                <h1>
                  <CountUp end={kae_data[0]?.overdue_count} />
                </h1>
              </div>
            </div>
            {/* <div className="row mt-5">

              <PieChart
      series={[
        {
          data: [
            // { id: 0, value: Number(kae_data[0]?.total_oh_count), label: 'Total OH Count' },
            { id: 0, value: kae_data[0]?.total_oh_count, label: 'Total OH Count' },
            { id: 1, value: kae_data[0]?.on_time_count, label: 'On Time Count' },
            { id: 2, value: kae_data[0]?.delay_count, label: 'Delay Count' },
            { id: 3, value: kae_data[0]?.overdue_count, label: 'Overdue Count' },
          ],
          // highlightScope: { faded: 'global', highlighted: 'item' },
          // faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
          cx: 90
        },
      ]}
      width={450}
      height={200}
    />
            </div> */}
          </>
        )}
      </div>

      {/* 
      {check_loading ? (
        <img src={report_loader} />
      ) : (
        <>

          <div className={styles.opertion_tbl} style={{ marginLeft: "2%", marginTop: "2%", width: "96%" }}>
            <MUIDataTable
              title={<h4 style={{ textAlign: "left" }}>KAE Insights Report</h4>}
              data={kae_data}
              columns={columns}
              options={{
                options: options,
                selectableRows: "none",
                viewColumns: false,
                print: false,
                responsive: "standard",
                download: false,
                search: false,
                filter: false,
                fixedHeader: true,
                tableBodyMaxHeight: "600px",
                pagination:false,
                setTableProps: () => {
                  return {
                    padding: "default",
                  };
                },
                customToolbar: () => {
                  return (
                    <>
                      <div style={{ display: "inline-flex" }}>
                        <Space direction="vertical" size={12}>
                          <RangePicker
                            // defaultValue={[dayjs(new Date()), dayjs(new Date())]}
                            format={dateFormat}
                            value={[selectedFromDate, selectedToDate]}
                            onChange={(new_val) => handleDateChange(new_val)}
                          />
                        </Space>
                        <button
                          className="btn btn-primary btn-sm ml-2"
                          onClick={handleClick}
                          style={{
                            display: showBtn ? "block" : "none",
                            marginLeft: "5px",
                          }}
                        >
                          Apply
                        </button>
                      </div>
                    </>
                  );
                },
                textLabels: {
                  body: {
                    noMatch: "Data Not Available",
                  },
                },
              }}
            />
          </div>
        </>
      )} */}
    </>
  );
}
export default KAE_planning;
