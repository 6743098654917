import React, { useEffect, useState } from "react";
import MiniDrawer from "../components/Minidrawer";
import { DateRangePicker, Stack } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import moment from "moment";
import dayjs from "dayjs";
import Salespersonwise_sample_graph from "../components/Salespersonwise_sample_graph";
import Top_services_tbl from "../components/Top_services_tbl";
import report_loader from "../Media/ezgif.com-optimize.gif";

import styles from "../Styles/Table.module.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";


import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import addWeeks from 'date-fns/addWeeks';
import WebPage_info from "../components/WebPage_info";

function Sample_business_report() {
  const storage = window.sessionStorage;
  let Role_id = storage.getItem("Role_id");
  let dept = storage.getItem("DEPT");
  let emp_code = storage.getItem("EMP_CODE");

  if(window.location.pathname == "/sample_business_report"){
    storage.setItem("admin_param","2")
  }else{
    storage.setItem("admin_param","1")
  }
  let admin_param = storage.getItem("admin_param");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const [sample_data, setSample_Data] = useState([]);
  const [top_service_data, setTop_Service_Data] = useState([]);
  const [total_service_data, setTotal_Service_Data] = useState(0);
  const [salesperson_data, setSalesperson_Data] = useState([]);
  const [salesperson_value, setSalesperson_Value] = useState([]);
  const [salesperson_label, setSalesperson_Label] = useState([]);

  const [top_service_load,setTop_Service_Load] = useState(false);
  const [salesperson_load,setSalesperson_Load] = useState(false);
  const [sample_data_load,setSample_Data_Load] = useState(false);
  const [tab_load,setTab_Load] = useState(false)


  let date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();
  let firstDay = new Date(y, m, 1);
  let lastDay = new Date(y, m + 1, 0);

  const [selectedFromDate, setSelectedFromDate] = useState(new Date(y, m, 1));
  const [selectedToDate, setSelectedToDate] = useState(new Date(y, m + 1, 0));

  const [from_date, setFrom_Date] = useState(
    moment(firstDay).format("YYYY-MM-DD")
  );
  const [to_date, setTo_Date] = useState(moment(lastDay).format("YYYY-MM-DD"));

  const [tab_data, setTab_Data] = useState([]);

  const handleDateChange = (new_val) => {
    if (new_val !== null) {
      setSelectedFromDate(new_val[0]);
      setSelectedToDate(new_val[1]);
      setFrom_Date(moment(new_val[0]).format("YYYY-MM-DD"));
      setTo_Date(moment(new_val[1]).format("YYYY-MM-DD"));
    } else {
      //   setParams("MTD");
      //   setRange_Flag(false);
      let date = new Date(),
        y = date.getFullYear(),
        m = date.getMonth();

      setSelectedFromDate(new Date(y, m, 1));
      setSelectedToDate(new Date(y, m + 1, 0));

      setFrom_Date(moment(new Date(y, m, 1)).format("YYYY-MM-DD"));
      setTo_Date(moment(new Date(y, m + 1, 0)).format("YYYY-MM-DD"));
    }
  };

  const get_sample_countData = async () => {
    setSample_Data_Load(true)
    try {
      let res = await fetch(
        `https://reportapi.equinoxlab.com/service_count_by_state_city_deptwise_tl`,
        // `https://reportapi.equinoxlab.com/service_count_by_state_city_deptwise`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/json" },
          body: JSON.stringify({
            start_date: from_date,
            end_date: to_date,
            emp_code_input:emp_code,
            dept_id: (dept == "Tech" || dept == "Admin" || dept == "Management")? admin_param :dept == "FnB Sales"? "1":dept == "Corporate Sales" ? "2":"all"
          }),
        }
      );
      let data = await res.json();
      setSample_Data_Load(false)
      setSample_Data(data?.Data);
    } catch (error) {setSample_Data_Load(false)}
  };

  const get_sample_count = async () => {
    setTab_Load(true)
    try {
      let res = await fetch(
        `https://reportapi.equinoxlab.com/all_service_count_details_by_dept_tl`,
        // `https://reportapi.equinoxlab.com/all_service_count_details_by_dept`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/json" },
          body: JSON.stringify({
            start_date: from_date,
            end_date: to_date,
            emp_code_input:emp_code,
            dept_id: (dept == "Tech" || dept == "Admin" || dept == "Management")? admin_param :dept == "FnB Sales"? "1":dept == "Corporate Sales" ? "2":"all"
          }),
        }
      );
      let data = await res.json();
      setTab_Load(false)
      setTab_Data(data?.Data);
    } catch (error) {setTab_Load(false)}
  };

  const get_top_service_data = async () => {
    setTop_Service_Load(true)
    try {
      let res = await fetch(
        "https://reportapi.equinoxlab.com/order_value_by_service_depwise_tl",
        // "https://reportapi.equinoxlab.com/order_value_by_service_depwise",
        {
          method: "POST",
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            start_date: from_date,
            end_date: to_date,
            emp_code_input:emp_code,
            dept_id: (dept == "Tech" || dept == "Admin" || dept == "Management")? admin_param :dept == "FnB Sales"? "1":dept == "Corporate Sales" ? "2":"all"
          }),
        }
      );
      let data = await res.json();
      setTop_Service_Load(false)
      setTop_Service_Data(data?.Data);
      let total_count = data?.Data?.reduce((acc, ele) => {
        return acc + Number(ele?.total_amount);
      }, 0);
      setTotal_Service_Data(total_count);
    } catch (error) {setTop_Service_Load(false)}
  };

  const get_salespersonWise_data = async () => {
    setSalesperson_Load(true)
    try {
      let res = await fetch(
        "https://reportapi.equinoxlab.com/sample_count_by_dept_salesperson_tl",
        // "https://reportapi.equinoxlab.com/sample_count_by_dept_salesperson",
        {
          method: "POST",
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            start_date: from_date,
            end_date: to_date,
            emp_code_input:emp_code,
            dept_id: (dept == "Tech" || dept == "Admin" || dept == "Management")? admin_param :dept == "FnB Sales"? "1":dept == "Corporate Sales" ? "2":"all"
          }),
        }
      );
      let data = await res.json();
      setSalesperson_Load(false)
      setSalesperson_Data(data?.Data);
      let value_arr = [];
      let label_arr = [];
      for (let i = 0; i < data?.Data?.length; i++) {
        value_arr.push(data?.Data[i]?.sample_count);
        label_arr.push(data?.Data[i]?.sales_person);
      }
      setSalesperson_Value(value_arr);
      setSalesperson_Label(label_arr);
    } catch (error) {setSalesperson_Load(false)}
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - sample_data?.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if(Role_id != "" && Role_id != null && Role_id != "null"){
    get_sample_countData();
    get_sample_count();
    get_top_service_data();
    get_salespersonWise_data();
    }
  }, [selectedToDate]);

  const get_total_sample = () => {
    let total_count = sample_data?.reduce((acc, ele) => {
      return acc + Number(ele?.sample_count);
    }, 0);
    return total_count;
  };


  const predefinedRanges = [
    {
      label: 'Today',
      value: [new Date(), new Date()],
      placement: 'left'
    },
    {
      label: 'Yesterday',
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
      placement: 'left'
    },
    {
      label: 'This week',
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
      placement: 'left'
    },
    {
      label: 'Last week',
      value: [startOfWeek(addWeeks(new Date(), -1)), endOfWeek(addWeeks(new Date(), -1))],
      placement: 'left'
    },
    // {
    //   label: 'Last 7 days',
    //   value: [subDays(new Date(), 6), new Date()],
    //   placement: 'left'
    // },
    // {
    //   label: 'Last 30 days',
    //   value: [subDays(new Date(), 29), new Date()],
    //   placement: 'left'
    // },
    {
      label: 'This month',
      value: [startOfMonth(new Date()), new Date()],
      placement: 'left'
    },
    {
      label: 'Last month',
      value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'left'
    },
    {
      label: 'This year',
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      placement: 'left'
    },
    {
      label: 'Last year',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
      placement: 'left'
    },
    {
      label: 'All time',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
      placement: 'left'
    },
    // {
    //   label: 'Last week',
    //   closeOverlay: false,
    //   value: value => {
    //     const [start = new Date()] = value || [];
    //     return [
    //       addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
    //       addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
    //     ];
    //   },
    //   appearance: 'default'
    // },
    // {
    //   label: 'Next week',
    //   closeOverlay: false,
    //   value: value => {
    //     const [start = new Date()] = value || [];
    //     return [
    //       addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
    //       addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
    //     ];
    //   },
    //   appearance: 'default'
    // }
  ];

  return (
    <>
      <MiniDrawer />
     {tab_load?<div className="container-fluid">
      <img src={report_loader} id={styles.loader} />
     </div>: <div className="container-fluid pt-3">
        <div className="row">
          <div className="col-sm-12 col-md-9">
          {admin_param == "1"?<h4 style={{ textAlign: "left" }}>Service & Sample Level Business Report - ( FnB Sales )</h4>:<h4 style={{ textAlign: "left" }}>Service & Sample Level Business Report - ( Corporate Sales )</h4>}

            {/* <h4 style={{ textAlign: "left" }}>
              Service & Sample Level Business Report (Monthly Digest)
            </h4> */}
          </div>
          <div className="col-sm-12 col-md-3">
            <DateRangePicker
     
              // defaultValue={[new Date(), new Date()]}
              // ranges={predefinedRanges}
              format="dd MMM yyyy"
              value={[selectedFromDate, selectedToDate]}
              onChange={handleDateChange}
              size="md"
              placement="bottomEnd"
              preventOverflow
            />
          </div>
        </div>
        <div className="row mt-2 mb-5">
          <div className="col-sm-12 col-md-6">
            <div className="row" id={styles.sample_count_contain}>
              <div className="col-md-3 col-sm-6">
                <p>Total Services</p>
                <h5>{tab_data[0]?.total_service}</h5>
              </div>
              <div className="col-md-3 col-sm-6">
                <p>Total Amount (₹)</p>
                <>
                  {tab_data[0]?.total_amount.toString()?.length > 10 ? (
                    <marquee id={styles.moving_no} style={{marginTop:"-7px"}} scrollamount="2">
                      {" "}
                      {tab_data[0]?.total_amount == undefined ||
                      tab_data[0]?.total_amount == null
                        ? 0
                        : new Intl.NumberFormat("en-IN").format(
                            tab_data[0]?.total_amount
                          )}
                    </marquee>
                  ) : (
                    <h5>
                      {tab_data[0]?.total_amount == undefined ||
                      tab_data[0]?.total_amount == null
                        ? 0
                        : new Intl.NumberFormat("en-IN").format(
                            tab_data[0]?.total_amount
                          )}
                    </h5>
                  )}
                </>
                {/* <p style={{ color: "red" }}>-42.9%</p> */}
              </div>
              <div className="col-md-3 col-sm-6">
                <p>Total Order Booked</p>
                <h5>{tab_data[0]?.total_order_booked}</h5>
                {/* <p style={{ color: "red" }}>-42.9%</p> */}
              </div>
              <div className="col-md-3 col-sm-6">
                <p>Total Samples</p>
                <h5>{tab_data[0]?.total_sample}</h5>
                {/* <p style={{ color: "red" }}>-42.9%</p> */}
              </div>
            </div>

            <div className="row mt-4">
             {sample_data_load?<img src={report_loader} id={styles.loader} />: <Paper>
                <TableContainer id={styles.tbl_Container} >
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead
                      sx={{
                        backgroundColor: "#007cc3",
                        color: "#444a4f !important",
                        position: "sticky",
                        top: 0,
                        zIndex: "999",
                      }}
                    >
                      <TableRow>
                        <TableCell
                          id={styles.th_unit1}
                          style={{ borderBottom: "1px solid #89BBE0" }}
                          colspan={3}
                        >
                          <h5
                            className="ml-2 mt-2"
                            style={{
                              color: "white",
                              textAlign: "left",
                              marginLeft: "1%",
                            }}
                          >
                            {" "}
                            State/City/Sample Count
                          </h5>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          id={styles.th_unit1}
                          style={{ borderBottom: "1px solid #89BBE0" }}
                        >
                          {" "}
                          <h6
                            className="h6 mt-2"
                            style={{ color: "white", textAlign: "left" }}
                          >
                            State
                          </h6>
                        </TableCell>
                        <TableCell
                          id={styles.th_unit1}
                          style={{ borderBottom: "1px solid #89BBE0" }}
                        >
                          {" "}
                          <h6
                            className="h6 mt-2"
                            style={{ color: "white", textAlign: "left" }}
                          >
                            City
                          </h6>
                        </TableCell>
                        <TableCell
                          id={styles.th_unit1}
                          style={{ borderBottom: "1px solid #89BBE0" }}
                        >
                          {" "}
                          <h6
                            className="h6 mt-2"
                            style={{ color: "white", textAlign: "left" }}
                          >
                            Sample Count
                          </h6>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {sample_data?.length == "0" ? (
                      <TableBody>
                        {" "}
                        <TableRow>
                          <TableCell colSpan={11} className={styles.th_unit}>
                            {/* <div style={{ margin: "auto", border: "1px solid red" }}> */}
                            <p style={{ textAlign: "center" }}>
                              No Data Available
                            </p>
                          </TableCell>
                          {/* </div> */}
                        </TableRow>
                      </TableBody>
                    ) : (
                      <TableBody id={styles.body_content}>
                        {(rowsPerPage > 0
                          ? sample_data?.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                          : sample_data
                        )?.map((ele,index) => (
                          <TableRow>
                            <TableCell
                              sx={{
                                border:
                                  ele?.flag != "1" ? "1px solid white" : "",
                                borderLeft:
                                  ele?.flag != "1"
                                    ? "1px solid rgb(201, 201, 201);"
                                    : "",
                                borderBottom:
                                  (ele?.flag == "1" && (index != sample_data?.length-1 && index != rowsPerPage)) ? "1px solid white" : "",
                                borderRight: ele?.flag == "1" ? "0px" : "",
                              }}
                              className={styles.th_unit}
                            >
                              <p
                                className="p mt-2"
                                style={{
                                  textAlign: "left",
                                  display: ele?.flag == "1" ? "block" : "none",
                                }}
                              >
                                {ele.state}
                              </p>
                            </TableCell>
                            <TableCell className={styles.th_unit}>
                              <p
                                className="p mt-2"
                                style={{ textAlign: "left" }}
                              >
                                {ele.city}
                              </p>
                            </TableCell>
                            <TableCell className={styles.th_unit}>
                              <p
                                className="p mt-2"
                                style={{ textAlign: "left" }}
                              >
                                {ele.sample_count}
                              </p>
                            </TableCell>
                          </TableRow>
                        ))}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                    )}
                    <TableFooter id={styles.table_footer} >
                      <TableRow sx={{height:"40px"}}>
                        <TableCell  colSpan={2} className={styles.th_unit}>
                          <h5 style={{ textAlign: "left", paddingLeft:"10px" }}>Grand Total (₹)</h5>
                        </TableCell>
                        <TableCell className={styles.th_unit}>
                          <h5 style={{ textAlign: "left", paddingLeft:"10px" }}>
                            {get_total_sample()}
                          </h5>
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
                <TablePagination
                  id={styles.footer_div}
                  class="footer_div"
                  sx={{ backgroundColor: "#007cc3", color: "white" }}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  component="div"
                  count={sample_data?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>}
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="row" style={{ height: "80vh", overflow: "auto" }}>
             {salesperson_load?<img src={report_loader} id={styles.graph_loader}/>: <Salespersonwise_sample_graph
                data={salesperson_data}
                xLabels={salesperson_label}
              />}
            </div>
            <div className="row mt-3">
            {top_service_load?<img src={report_loader}  id={styles.graph_loader}/>: <Top_services_tbl
                data={top_service_data}
                service_amount_total={total_service_data}
              />}
            </div>
          </div>
        </div>
      </div>}
      <WebPage_info
       data={
        ["This dashboard displays the sample count categorized by state and city, along with the sample count for each salesperson. It also highlights the top 10 services used within these sample counts. By default, it shows data for the current month, but you can use the date picker to select and view data for a different date range."]
       }
      />
    </>
  );
}

export default Sample_business_report;
