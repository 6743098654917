//    try {
//     dispatch(get_req())
//     let res = await fetch("http://betahrms.equinoxlab.com/revenue");
//     let data = await res.json();
//     dispatch(get_succ(data))
//    } catch (error) {
//     dispatch(get_err())
//    }

import {
  BILLING_DATA_REQUEST,
  BILLING_DATA_SUCCESS,
  BILLING_DATA_ERROR,
  CATEGORY_DATA_ERROR,
  CATEGORY_DATA_REQUEST,
  CATEGORY_DATA_SUCCESS,
  GEOGRAPHY_DATA_ERROR,
  GEOGRAPHY_DATA_REQUEST,
  GEOGRAPHY_DATA_SUCCESS,
  GET_ERROR,
  GET_REQUEST,
  GET_SUCCESS,
  HUB_DATA_ERROR,
  HUB_DATA_REQUEST,
  HUB_DATA_SUCCESS,
  INDIVIDUAL_USER_ERROR,
  INDIVIDUAL_USER_REQUEST,
  INDIVIDUAL_USER_SUCCESS,
  KAE_DATA_ERROR,
  KAE_DATA_REQUEST,
  KAE_DATA_SUCCESS,
  LAB_DATA_ERROR,
  LAB_DATA_REQUEST,
  LAB_DATA_SUCCESS,
  LEADS_ERROR,
  LEADS_REQUEST,
  LEADS_SUCCESS,
  LOGISTICS_DATA_ERROR,
  LOGISTICS_DATA_REQUEST,
  LOGISTICS_DATA_SUCCESS,
  NPS_DATA_ERROR,
  NPS_DATA_REQUEST,
  NPS_DATA_SUCCESS,
  OPERATION_DATA_ERROR,
  OPERATION_DATA_REQUEST,
  ORDER_ERROR,
  ORDER_REQUEST,
  ORDER_SUCCESS,
  PAYMENT_DATA_ERROR,
  PAYMENT_DATA_REQUEST,
  PAYMENT_DATA_SUCCESS,
  REGISTRATION_DATA_ERROR,
  REGISTRATION_DATA_REQUEST,
  REGISTRATION_DATA_SUCCESS,
  TRANSECTION_DATA_ERROR,
  TRANSECTION_DATA_REQUEST,
  TRANSECTION_DATA_SUCCESS,
  USER_DATA_ERROR,
  USER_DATA_REQUEST,
  USER_DATA_SUCCESS,
  TEAM_S6_REQUEST,
  TEAM_S6_SUCCESS,
  TEAM_S6_ERROR,
  CATEGORY_DATA_CREATED_ON_ERROR,
  CATEGORY_DATA_CREATED_ON_SUCCESS,
  CATEGORY_DATA_CREATED_ON_REQUEST,
  CATEGORY_DATA_UPDATED_ON_REQUEST,
  CATEGORY_DATA_UPDATED_ON_SUCCESS,
  CATEGORY_DATA_UPDATED_ON_ERROR,
  CATEGORY_DATA_REVENUE_REQUEST,
  CATEGORY_DATA_REVENUE_SUCCESS,
  CATEGORY_DATA_REVENUE_ERROR,
} from "./actionTypes";
import Swal from "sweetalert2";
import { OPERATION_DATA_SUCCESS } from "./actionTypes";

let base_url = "https://reportapi.equinoxlab.com";

const swal_msg = (icon, msg) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1500,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  Toast.fire({
    icon: icon,
    text: msg,
    customClass: {
      htmlContainer: 'custom-html-container',
    },
  });
};

export const get_req = () => ({
  type: GET_REQUEST,
});
export const get_succ = (data) => ({
  type: GET_SUCCESS,
  payload: data,
});
export const get_err = () => ({
  type: GET_ERROR,
});

function formatDate(string) {
  var options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(string).toLocaleDateString([], options);
}

export const get_data = (date,setTested_Data) => async (dispatch) => {
  // let new_date = formatDate(date)
  // let date_arr = new_date.split(" ")

  try {
    dispatch(get_req());
    let res = await fetch(`${base_url}/revenue_year`)
    let data = await res.json();
    if (data?.response?.code == 200) {
      setTested_Data(data?.Data)
      dispatch(get_succ(data?.Data));
    } else {
      setTested_Data([])
      dispatch(get_err());
      swal_msg("error", data?.response?.message)

    }
  } catch (error) {
    setTested_Data([])
    dispatch(get_err());
    swal_msg("error", "Something Went Wrong")
  }
};

export const order_req = () => ({
  type: ORDER_REQUEST,
});
export const order_succ = (data) => ({
  type: ORDER_SUCCESS,
  payload: data,
});
export const order_err = () => ({
  type: ORDER_ERROR,
});

export const order_data = (department, year,setNew_Repeat_Data,emp_code) => async (dispatch) => {
   
   let latest_year = year-1
  try {
    dispatch(order_req());
    let res = await fetch(`${base_url}/order_department_year_atl`, {
    // let res = await fetch(`${base_url}/order_department_year`, {
      method: "POST",
      headers: { "Content-Type": "Application/Json" },
      body: JSON.stringify({
        department:
          (department == "Tech" || department == "Admin" || department == "Management") ? "ALL" : department,
        // fy_year: Number(year)-1+"-"+year,
        fy_year: year,
        "emp_code_int":emp_code
        // fy_year: Number(year)-1,
      }),
    });
    let data = await res.json();
    if (data?.response?.code == 200) {
      setNew_Repeat_Data(data?.Data)
      dispatch(order_succ(data?.Data));
    } else {
      setNew_Repeat_Data([])
      dispatch(order_err());
      swal_msg("error", data?.response?.message)

    }
  } catch (error) {
    setNew_Repeat_Data([])
    dispatch(order_err());
    swal_msg("error", "Something Went Wrong")

  }
};

export const leads_req = () => ({
  type: LEADS_REQUEST,
});
export const leads_succ = (data) => ({
  type: LEADS_SUCCESS,
  payload: data,
});
export const leads_err = () => ({
  type: LEADS_ERROR,
});

export const leads_data = (setSales_Data, DEPT) => async (dispatch) => {
  try {
    dispatch(leads_req());
    let res = await fetch(`${base_url}/usp_s6_salesperson`, {
      method: "POST",
      headers: { "Content-Type": "Application/Json" },
      body: JSON.stringify({
        DEPARTMENT: (DEPT == "Tech" || DEPT == "Admin" || DEPT == "Management") ? "ALL" : DEPT,
      }),
    });
    let data = await res.json();
    if (data?.response?.code == 200) {
      dispatch(leads_succ(data?.Data));
      setSales_Data(data?.Data);
    } else {
      setSales_Data([])
      dispatch(leads_err());
      swal_msg("error", data?.response?.message)
     
    }
  } catch (error) {
    setSales_Data([])
    dispatch(leads_err());
    swal_msg("error", "Something Went Wrong")

  }
};


export const category_data_req = () => ({
  type: CATEGORY_DATA_REQUEST,
});
export const category_data_succ = (data) => ({
  type: CATEGORY_DATA_SUCCESS,
  payload: data,
});
export const category_data_err = () => ({
  type: CATEGORY_DATA_ERROR,
});

export const category_data = (setCat_Data, DEPT,selected_cat,selected_source, select_leadStage) => async (dispatch) => {
  try {
    dispatch(category_data_req());
    let res = await fetch(`${base_url}/usp_master_category`, {
      method: "POST",
      headers: { "Content-Type": "Application/Json" },
      body: JSON.stringify({
        DEPARTMENT: (DEPT == "Tech" || DEPT == "Admin" || DEPT == "Management" || DEPT == "Marketing")  ? "ALL" : DEPT,
        CATEGORY: selected_cat,
        LEADSOURCE:selected_source,
        STAGE: select_leadStage
      }),
    });
    let data = await res.json();
    if (data?.response?.code == 200) {
      dispatch(category_data_succ(data?.Data));
      setCat_Data(data?.Data);
    } else {
      setCat_Data([])
      dispatch(category_data_err());
      swal_msg("error", data?.response?.message)

    }
  } catch (error) {
    setCat_Data([])
    dispatch(category_data_err());
    swal_msg("error", "Something went wrong")
   
  }
};



export const category_data_updated_on_req = () => ({
  type: CATEGORY_DATA_UPDATED_ON_REQUEST,
});
export const category_data_updated_on_succ = (data) => ({
  type: CATEGORY_DATA_UPDATED_ON_SUCCESS,
  payload: data,
});
export const category_data_updated_on_err = () => ({
  type: CATEGORY_DATA_UPDATED_ON_ERROR,
});

export const category_data_updated_on = (setCat_Data, DEPT,selected_cat, selected_source, select_leadStatus) => async (dispatch) => {
  try {
    dispatch(category_data_updated_on_req());
    let res = await fetch(`${base_url}/usp_master_category_updated_on`, {
      method: "POST",
      headers: { "Content-Type": "Application/Json" },
      body: JSON.stringify({
        DEPARTMENT: (DEPT == "Tech" || DEPT == "Admin" || DEPT == "Management" || DEPT == "Marketing")  ? "ALL" : DEPT,
        CATEGORY: selected_cat,
        LEADSOURCE:selected_source,
        STATUS: select_leadStatus 
      }),
    });
    let data = await res.json();
    if (data?.response?.code == 200) {
      dispatch(category_data_updated_on_succ(data?.Data));
      setCat_Data(data?.Data);
    } else {
      setCat_Data([])
      dispatch(category_data_updated_on_err());
      swal_msg("error", data?.response?.message)
   
    }
  } catch (error) {
    setCat_Data([])
    dispatch(category_data_updated_on_err());
    swal_msg("error", "Something Went Wrong")
   
  }
};

export const category_data_revenue_req = () => ({
  type: CATEGORY_DATA_REVENUE_REQUEST,
});
export const category_data_revenue_succ = (data) => ({
  type: CATEGORY_DATA_REVENUE_SUCCESS,
  payload: data,
});
export const category_data_revenue_err = () => ({
  type: CATEGORY_DATA_REVENUE_ERROR,
});

export const category_data_revenue = (setCat_Data, DEPT,selected_cat, selected_source,setRevenue_load) => async (dispatch) => {
  try {
    dispatch(category_data_revenue_req());
    setRevenue_load(true)
    let res = await fetch(`${base_url}/order_amount_leadsource_category`, {
      method: "POST",
      headers: { "Content-Type": "Application/Json" },
      body: JSON.stringify({
        DEPARTMENT: (DEPT == "Tech" || DEPT == "Admin" || DEPT == "Management" || DEPT == "Marketing")  ? "ALL" : DEPT,
        CATEGORY: selected_cat,
        LEADSOURCE:selected_source
      }),
    });
    let data = await res.json();
    setRevenue_load(false)
    if (data?.response?.code == 200) {
      dispatch(category_data_revenue_succ(data?.Data));
      setCat_Data(data?.Data);
    } else {
      dispatch(category_data_revenue_err());
      setCat_Data([]);
      swal_msg("error", data?.response?.message)
   
    }
  } catch (error) {
    setCat_Data([])
    dispatch(category_data_revenue_err());
    setRevenue_load(false)
    swal_msg("error", "Something Went Wrong")
   
  }
};

// get user dropdown

export const user_data_req = () => ({
  type: USER_DATA_REQUEST,
});
export const user_data_succ = (data) => ({
  type: USER_DATA_SUCCESS,
  payload: data,
});
export const user_data_err = () => ({
  type: USER_DATA_ERROR,
});

export const user_data = (dept_id, setUserList) => async (dispatch) => {
  try {
    let res = await fetch("https://reportapi.equinoxlab.com/get_salesperson_for_performance",{
      method:"POST",
      headers: {"Content-Type": "Application/Json"},
      body: JSON.stringify({
        "dept_id":dept_id
      })
    })
    let data = await res.json();

    if (data?.response?.code == 200) {
      dispatch(user_data_succ(data?.data));
      setUserList(data?.Data);
    } else {
      dispatch(user_data_err());
      setUserList([]);
      swal_msg("error", data?.response?.message)
  
    }
  } catch (error) {
    setUserList([]);
    dispatch(user_data_err());
    swal_msg("error", "Something Went Wrong")

  }

};

export const individual_data_req = () => ({
  type: INDIVIDUAL_USER_REQUEST,
});
export const individual_data_succ = (data) => ({
  type: INDIVIDUAL_USER_SUCCESS,
  payload: data,
});
export const individual_data_err = () => ({
  type: INDIVIDUAL_USER_ERROR,
});

export const individual_data =
  (user, dept, setPerformance_Data, setUser_Load) => async (dispatch) => {
    try {
      setUser_Load(true);
      dispatch(individual_data_req());
      let res = await fetch(`${base_url}/user_performance_statistics_dummy`, {
      // let res = await fetch(`${base_url}/user_performance_statistics`, {
        method: "POST",
        headers: { "Content-Type": "Application/Json" },
        body: JSON.stringify({
          USER_GUID: user,
          ROLE: "ALL",
          DEPT_ID: dept,
          VERTICAL_ID: "ALL",
          SECTOR_ID: "ALL",
        }),
      });
      let data = await res.json();
      setUser_Load(false);
      setPerformance_Data(data?.Data);
      if (data?.response?.code == 200) {
        dispatch(individual_data_succ(data?.Data));
      } else {
        setPerformance_Data([])
        dispatch(individual_data_err());
        swal_msg("error", data?.response?.message)
       
      }
    } catch (error) {
      setPerformance_Data([])
      dispatch(individual_data_err());
      swal_msg("error",  "Something Went Wrong")
   
    }
  };

export const hub_data_req = () => ({
  type: HUB_DATA_REQUEST,
});
export const hub_data_succ = (data) => ({
  type: HUB_DATA_SUCCESS,
  payload: data,
});
export const hub_data_err = () => ({
  type: HUB_DATA_ERROR,
});

export const get_hub_data = (setHub_Data) => async (dispatch) => {
  try {
    dispatch(hub_data_req());
    let res = await fetch(`${base_url}/report_insight`);
    let data = await res.json();
    // setUserList(data?.data)
    if (data.response.code == 200) {
      dispatch(hub_data_succ(data?.Data));
      setHub_Data(data?.Data);
    } else {
      setHub_Data([])
      dispatch(hub_data_err());
      swal_msg("error",  data?.response?.message)


    }
  } catch (error) {
    setHub_Data([])
    dispatch(hub_data_err());
    swal_msg("error",  "Something Went Wrong")

  }
};

export const geo_req = () => ({
  type: GEOGRAPHY_DATA_REQUEST,
});
export const geo_succ = (data) => ({
  type: GEOGRAPHY_DATA_SUCCESS,
  payload: data,
});
export const geo_err = () => ({
  type: GEOGRAPHY_DATA_ERROR,
});

export const geo_hub_data = (setGeo_Data, department,selected_state,selected_city) => async (dispatch) => {
  try {
    dispatch(geo_req());
    let res = await fetch(`${base_url}/report_revenue_geography`, {
      method: "POST",
      headers: { "Content-Type": "Application/Json" },
      body: JSON.stringify({
        department_param:
        (department == "Tech" || department == "Admin" || department == "Management") ? "ALL" : department,
          state:selected_state,
          city:selected_city
      }),
    });
    let data = await res.json();

    if (data?.response?.code == 200) {
      dispatch(geo_succ(data?.Data));
      setGeo_Data(data?.Data);
    } else {
      setGeo_Data([])
      dispatch(geo_err());
      swal_msg("error",  data?.response?.message)
   
    }
  } catch (error) {
    setGeo_Data([])
    dispatch(geo_err());
    swal_msg("error",  "Something Went Wrong")
   
  }
};

export const lab_req = () => ({
  type: LAB_DATA_REQUEST,
});
export const lab_succ = (data) => ({
  type: LAB_DATA_SUCCESS,
  payload: data,
});
export const lab_err = () => ({
  type: LAB_DATA_ERROR,
});

export const get_lab_data =
  (data_func, param, load_func) => async (dispatch) => {
    load_func(true)
    try {
      dispatch(lab_req());
      let url = `${base_url}/report_insight_s6_chem_lab`;
      if (param == "micro_lab") {
        url = `${base_url}/report_insight_s6_micro_lab`;
      }
      if (param == "instru_lab") {
        url = `${base_url}/report_insight_s6_instru_lab`;
      }
      if (param == "outsource_lab") {
        url = `${base_url}/report_insight_s6_outsource_lab`;
      }
      if (param == "environment_lab") {
        url = `${base_url}/report_insight_s6_environment_lab`;
      }
      if (param == "HUB") {
        url = `${base_url}/report_insight_s6_HUB`;
      }
      // let res = await fetch(url,{
      //     method:"POST",
      //     headers:{"Content-Type" :"Application/Json"},
      //     body: JSON.stringify({
      //         "report_status": status
      //     })
      // })
      let res = await fetch(url);
      let data = await res.json();
load_func(false)
      if (data?.response?.code == 200) {
        dispatch(lab_succ(data?.Data));
        data_func(data?.Data);
      } else {
        dispatch(lab_err());
        swal_msg("error",  data?.response?.message)
      
      }
    } catch (error) {
      dispatch(lab_err());
      swal_msg("error", "Something Went Wrong")

    }
  };

export const hub_lab_req = () => ({
  type: GEOGRAPHY_DATA_REQUEST,
});
export const hub_lab_succ = (data) => ({
  type: GEOGRAPHY_DATA_SUCCESS,
  payload: data,
});
export const hub_lab_err = () => ({
  type: GEOGRAPHY_DATA_ERROR,
});

export const hub_wise_lab_data =
  (setHub_lab_data, hub_id) => async (dispatch) => {
    try {
      dispatch(hub_lab_req());
      let res = await fetch(`${base_url}/hub_wise_Lab`, {
        method: "POST",
        headers: { "Content-Type": "Application/Json" },
        body: JSON.stringify({
          HUB_ID: +hub_id,
        }),
      });
      let data = await res.json();

      if (data?.response?.code == 200) {
        dispatch(hub_lab_succ(data?.Data));
        setHub_lab_data(data?.Data);
      } else {
        setHub_lab_data([])
        dispatch(hub_lab_err());
        swal_msg("error", data?.response?.message)
    
      }
    } catch (error) {
      setHub_lab_data([])
      dispatch(hub_lab_err());
      swal_msg("error", "Something Went Wrong")
      
    }
  };

export const registration_req = () => ({
  type: REGISTRATION_DATA_REQUEST,
});
export const registration_success = (data) => ({
  type: REGISTRATION_DATA_SUCCESS,
  payload: data,
});
export const registration_error = () => ({
  type: REGISTRATION_DATA_ERROR,
});

export const registration_report =
  (setRegistration_Load,
    params,
   start_date,
   end_date,
   setRegistrationData) => async (dispatch) => {
    try {
      dispatch(registration_req());
      setRegistration_Load(true)
      // let res = await fetch(`${base_url}/registration_report`)
      let res = await fetch(`${base_url}/registration_report`,{
        method: "POST",
        headers:{"Content-Type" : "Application/Json"},
        body: JSON.stringify({
          get_date:params == "Custom Date"?"": params,
          start_date:params == "Custom Date"?start_date:"",
          end_date:params == "Custom Date"?end_date:""
        })
      });
      let data = await res.json();
      setRegistration_Load(false)
      if (data?.response?.code == 200) {
        dispatch(registration_success(data?.Data));
        setRegistrationData(data?.Data);
      } else {
        dispatch(registration_error());
        setRegistrationData([]);
        swal_msg("error", data?.response?.message)
 
      }
    } catch (error) {
      setRegistrationData([]);
      setRegistration_Load(false)
      dispatch(registration_error());
      swal_msg("error", "Something Went Wrong")
     
    }
  };

export const operation_req = () => ({
  type: OPERATION_DATA_REQUEST,
});
export const operation_succ = (data) => ({
  type: OPERATION_DATA_SUCCESS,
  payload: data,
});
export const operation_err = () => ({
  type: OPERATION_DATA_ERROR,
});

export const operation_report =
  (setOperationData, send_from_date, send_to_date) => async (dispatch) => {
    try {
      dispatch(operation_req());
      let res = await fetch(`${base_url}/logistic_schedule_reports_hub_wise`, {
        method: "POST",
        headers: { "Content-Type": "Application/Json" },
        body: JSON.stringify({
          start_date: send_from_date,
          end_date: send_to_date,
        }),
      });
      let data = await res.json();

      if (data?.response?.code == 200) {
        dispatch(operation_succ(data?.Data));
        setOperationData(data?.Data[0]?.hub_data);
      } else {
        setOperationData([])
        dispatch(operation_err());
        swal_msg("error", data?.response?.message)
      
      }
    } catch (error) {
      setOperationData([])
      dispatch(operation_err());
      swal_msg("error", "Something Went Wrong")
 
    }
  };

export const logistics_req = () => ({
  type: LOGISTICS_DATA_REQUEST,
});
export const logistics_succ = (data) => ({
  type: LOGISTICS_DATA_SUCCESS,
  payload: data,
});
export const logistics_err = () => ({
  type: LOGISTICS_DATA_ERROR,
});

export const logistics_report =
  (setLogisticsData, selectedFromDate, selectedToDate,setLogistic_Load) => async (dispatch) => {
    try {
      dispatch(logistics_req());
      setLogistic_Load(true)
      let res = await fetch(`${base_url}/logistic_execution_reports_hub_wise`, {
        method: "POST",
        headers: { "Content-Type": "Application/Json" },
        body: JSON.stringify({
          start_date: selectedFromDate,
          end_date: selectedToDate,
        }),
      });
      let data = await res.json();
      setLogistic_Load(false)
      if (data.response.code == 200) {

        dispatch(logistics_succ(data?.Data));
        setLogisticsData(data?.Data[0]?.hub_data);
      } else {
        setLogisticsData([])
        dispatch(logistics_err());
        swal_msg("error", data?.response?.message)
  
      }
    } catch (error) {
      setLogistic_Load(false)
      setLogisticsData([])
      dispatch(logistics_err());
      swal_msg("error", "Something Went Wrong")
 
    }
  };

export const nps_req = () => ({
  type: NPS_DATA_REQUEST,
});
export const nps_succ = (data) => ({
  type: NPS_DATA_SUCCESS,
  payload: data,
});
export const nps_err = () => ({
  type: NPS_DATA_ERROR,
});

export const nps_report =
  (setNps_Data, send_month, send_year) => async (dispatch) => {
    try {
      dispatch(nps_req());
      let res = await fetch(`${base_url}/NPS_rating_report`, {
        method: "POST",
        headers: { "Content-Type": "Application/Json" },
        body: JSON.stringify({
          month: send_month,
          year: send_year,
        }),
      });
      let data = await res.json();
      if (data?.response?.code == 200) {
        dispatch(nps_succ(data?.Data));
        if (data.Data[0] != undefined) {
          setNps_Data(data.Data[0]);
        } else {
          setNps_Data([]);
        }
      } else {
        dispatch(nps_err());
        swal_msg("error", data?.response?.message)
  
      }
    } catch (error) {
      dispatch(nps_err());
      swal_msg("error", "Something Went Wrong")

    }
  };

export const payment_req = () => ({
  type: PAYMENT_DATA_REQUEST,
});
export const payment_succ = (data) => ({
  type: PAYMENT_DATA_SUCCESS,
  payload: data,
});
export const payment_err = () => ({
  type: PAYMENT_DATA_ERROR,
});

export const payment_report = (deptId, setPaymentData) => async (dispatch) => {
  try {
    dispatch(payment_req());
    let res = await fetch(`${base_url}/payment_s6_report`, {
      method: "POST",
      headers: { "Content-Type": "Application/Json" },
      body: JSON.stringify({
        dept_id: deptId,
      }),
    });
    let data = await res.json();
    if (data?.response?.code == 200) {
      dispatch(payment_succ(data?.Data));
      setPaymentData(data?.Data);
    } else {
      setPaymentData([])
      dispatch(payment_err());
      swal_msg("error", data?.response?.message)
  
    }
  } catch (error) {
    setPaymentData([])
    dispatch(payment_err());
    swal_msg("error", "Something Went Wrong")
   
  }
};

// Transection report
export const transection_req = () => ({
  type: TRANSECTION_DATA_REQUEST,
});
export const transection_succ = (data) => ({
  type: TRANSECTION_DATA_SUCCESS,
  payload: data,
});
export const transection_err = () => ({
  type: TRANSECTION_DATA_ERROR,
});
export const transection_report = (setTransectionData) => async (dispatch) => {
  try {
    dispatch(transection_req());
    let res = await fetch(`${base_url}/transaction_s6_report`);
    let data = await res.json();
    if (data?.response?.code == 200) {
      dispatch(transection_succ(data));
      setTransectionData(data?.Data);
    } else {
      setTransectionData([])
      dispatch(transection_err());
      swal_msg("error", data?.response?.message)
 
    }
  } catch (error) {
    setTransectionData([])
    dispatch(transection_err());
    swal_msg("error", "Something Went Wrong")
  
  }
};

// KAE planning
export const kae_req = () => ({
  type: KAE_DATA_REQUEST,
});
export const kae_succ = (data) => ({
  type: KAE_DATA_SUCCESS,
  payload: data,
});
export const kae_err = () => ({
  type: KAE_DATA_ERROR,
});
export const kae_planning =
  (setKae_data, send_from_date, send_to_date) => async (dispatch) => {
    try {
      dispatch(kae_req());
      let res = await fetch(`${base_url}/insights_kae_planning_report`, {
        method: "POST",
        headers: { "Content-Type": "Application/Json" },
        body: JSON.stringify({
          start_date: send_from_date,
          end_date: send_to_date,
        }),
      });
      let data = await res.json();
      if (data?.response?.code == 200) {
        dispatch(kae_succ(data));
        setKae_data(data?.Data);
      } else {
        dispatch(kae_err());
        setKae_data([])
        swal_msg("error", data?.response?.message)
       
      }
    } catch (error) {
      dispatch(kae_err());
      setKae_data([])
      swal_msg("error", "Something Went Wrong")
      
    }
  };

// Daily Billing Report
export const billing_req = () => ({
  type: BILLING_DATA_REQUEST,
});
export const billing_succ = (data) => ({
  type: BILLING_DATA_SUCCESS,
  payload: data,
});
export const billing_err = () => ({
  type: BILLING_DATA_ERROR,
});
export const daily_billing_report =
  (setBilling_data, send_date) => async (dispatch) => {
    try {
      dispatch(billing_req());
      let res = await fetch(`${base_url}/daily_billing_report`, {
        method: "POST",
        headers: { "Content-Type": "Application/Json" },
        body: JSON.stringify({
          invoice_date: send_date,
        }),
      });
      let data = await res.json();
      if (data.response.code == 200) {
        dispatch(billing_succ(data));
        setBilling_data(data?.Data);
      } else {
        dispatch(billing_err());
        swal_msg("error", "Something Went Wrong")
        
      }
    } catch (error) {
      dispatch(billing_err());
      swal_msg("error", "Something Went Wrong")
    }
  };


  // HR Team S6
export const team_s6_req = () => ({
  type: TEAM_S6_REQUEST,
});
export const team_s6_succ = (data) => ({
  type: TEAM_S6_SUCCESS,
  payload: data,
});
export const team_s6_err = () => ({
  type: TEAM_S6_ERROR,
});
export const team_s6_data = (setTeam_s6) => async (dispatch) => {
  try {
    dispatch(team_s6_req());
    let res = await fetch(`https://venus.equinoxlab.com/api/Dashboard.svc/Get_Dashboard_S6`);
    let data = await res.json();
    setTeam_s6(data?.data);

    if (data?.response[0]?.CODE == "200") {
      dispatch(team_s6_succ(data?.data));
    } else {
      setTeam_s6([])
      dispatch(team_s6_err());
      swal_msg("error", data?.response[0]?.MESSAGE)
   
    }
    } catch (error) {
      setTeam_s6([])
      dispatch(team_s6_err());
      swal_msg("error", "Something Went Wrong")

    }
  };