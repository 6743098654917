import React, { useState, useMemo } from "react";
import styles from "../Styles/Average_ttf.module.css"
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import report_loader from "../Media/ezgif.com-optimize.gif";

// import type { DatePickerProps } from 'antd';
import { DatePicker, Space } from "antd";
import moment from "moment";
import { useEffect } from "react";
import dayjs from "dayjs";
import MiniDrawer from "../components/Minidrawer";
import WebPage_info from "../components/WebPage_info";

function Offer_sent_accept_dashboard() {
  // const format = moment().format("YYYY");
  const format = moment().subtract(1, "years").format("YYYY");
  const dateFormat = "YYYY";
// const [selectYear, setSelectYear] = useState(dayjs().subtract(1, "year"));
const [selectYear, setSelectYear] = useState(dayjs());
// const [sendYear, SetSendYear] = useState(moment().subtract(1, "years").format("YYYY"));
const [sendYear, SetSendYear] = useState(moment().format("YYYY"));
  const [offerData, setOfferData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [load, setLoad] = useState(false);
  const [direction, setDirection] = useState("desc");
 const storage = window.sessionStorage;
  let Role_id = storage.getItem("Role_id");


  const get_offer_data = async () => {
    setLoad(true);
    try {
      let res = await fetch(
        // `https://venus.equinoxlab.com/api/Dashboard.svc/Offer_Sent_Acc_Dashboard/${sendYear}`
        `https://venus.equinoxlab.com/api/Dashboard.svc/Offer_Sent_Acc_EmpType_Dashboard/${sendYear}`
      );
      let data = await res.json();
      setOfferData(data?.data);
      setLoad(false);
    } catch (error) {
      setOfferData([]);
      ;
    }
  };
  const onYearChange = (date, dateString) => {
    if (date) {
      SetSendYear(dateString);
      setSelectYear(date);
    } else {
      SetSendYear(moment().subtract(1, "years").format("YYYY"));
      setSelectYear(dayjs().subtract(1, "year"));
    }
  };

  useEffect(() => {
    if(Role_id != "" && Role_id != null && Role_id != "null"){
      get_offer_data();
    }

  }, [sendYear]);

  return (
    <>
     <MiniDrawer/>
      <div
        // style={{
        //   width: "94%",
        //   marginTop: "5%",
        //   marginLeft: "5%",
        //   paddingBottom: "20px",
        // }}
        className="container-fluid pt-2"
      >
        {load ? (
          <>
          <img src={report_loader} id={styles.loader} />
            {/* <Loader /> */}
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "10px",
              }}
            >

            </div>

            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer
           sx={{ maxHeight: "70vh" }}
                id={styles.offerSentTable}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead 
                   sx={{
                    backgroundColor: "#007cc3",
                    color: "#444a4f !important",
                    position: "sticky",
                    top: 0,
                    zIndex: "999",
                  }}
                  >
                       <TableRow>
                       <TableCell colSpan={8} >
                        <h5 style ={{padding :"10px 0px 10px 5px",}}>Offer Sent & Accepted Dashboard</h5>
                       </TableCell>
                       <TableCell  >
                       <Space direction="vertical">
                <DatePicker
                  onChange={onYearChange}
                  picker="year"
                  value={selectYear}
                  defaultValue={dayjs(format, dateFormat)}
                />
              </Space>
                       </TableCell>
                       </TableRow>
                    <TableRow>
                      <TableCell
                        id={styles.ceo}
                        rowSpan={3}
                        style={{ width: "170px" }}
                      >
                         <h6 className="h6 mt-2" style={{ color: "white" }}>Month</h6>
                        
                      </TableCell>

                      <TableCell colspan={8} id={styles.ceo}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>Employee Type</h6>
                        
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell id={styles.ceo} colSpan={2}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>Full Time</h6>
                        
                      </TableCell>
                      <TableCell id={styles.ceo} colSpan={2}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>Part Time</h6>
   
                      </TableCell>
                      <TableCell id={styles.ceo} colSpan={2}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>Intern</h6>
                        
                      </TableCell>
                      <TableCell id={styles.ceo} colSpan={2}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>Contract</h6>
                        
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell id={styles.ceo} style={{ width: "120px" }}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>Sent</h6>
                        
                      </TableCell>
                      <TableCell id={styles.ceo} style={{ width: "120px" }}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>Accepted</h6>
                        
                      </TableCell>
                      <TableCell id={styles.ceo} style={{ width: "120px" }}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>Sent</h6>
                        
                      </TableCell>
                      <TableCell id={styles.ceo} style={{ width: "120px" }}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>Accepted</h6>
                  
                      </TableCell>
                      <TableCell id={styles.ceo} style={{ width: "120px" }}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>Sent</h6>
                        
                      </TableCell>
                      <TableCell id={styles.ceo} style={{ width: "120px" }}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>Accepted</h6>
             
                      </TableCell>
                      <TableCell id={styles.ceo} style={{ width: "120px" }}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>Sent</h6>
                        
                      </TableCell>
                      <TableCell id={styles.ceo} style={{ width: "120px" }}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>Accepted</h6>
          
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  
                  <TableBody>
                    {offerData.length > 0 ? (
                      offerData.map((ele, id) => (
                        <TableRow>
                          <TableCell id={styles.ceo}>{ele.Slot}</TableCell>
                          <TableCell id={styles.ceo}>
                            {ele.Offer_Sent_FT}
                          </TableCell>
                          <TableCell id={styles.ceo}>
                            {ele.Offer_acc_FT}
                          </TableCell>
                          <TableCell id={styles.ceo}>
                            {ele.Offer_Sent_PT}
                          </TableCell>
                          <TableCell id={styles.ceo}>
                            {ele.Offer_acc_PT}
                          </TableCell>
                          <TableCell id={styles.ceo}>
                            {ele.Offer_Sent_INT}
                          </TableCell>
                          <TableCell id={styles.ceo}>
                            {ele.Offer_acc_INT}
                          </TableCell>
                          <TableCell id={styles.ceo}>
                            {ele.Offer_Sent_CT}
                          </TableCell>
                          <TableCell id={styles.ceo}>
                            {ele.Offer_acc_CT}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={9}
                          align="center"
                          id={styles.tabledata}
                        >
                          No data available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </>
        )}
      </div>
      <WebPage_info
       data={
        ["This dashboard shows how many job offers were sent and accepted each month. For each type of employment (Full-Time, Part-Time, Intern, and Contract), it tells you the number of offers sent and the number that were accepted."]
       }
      />
    </>
  );
}

export default Offer_sent_accept_dashboard;
