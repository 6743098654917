import React, { useEffect } from "react";
import MUIDataTable from "mui-datatables";
import MiniDrawer from "../components/Minidrawer";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import report_loader from "../Media/ezgif.com-optimize.gif";
import { transection_report } from "../Redux/actions";
import styles from "../Styles/Table.module.css"
import WebPage_info from "../components/WebPage_info";

function Transaction_report() {
  const storage = window.sessionStorage;
  let Role_id = storage.getItem("Role_id"); 

  const [transectionData, setTransectionData] = useState([]);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.data.data);
  const check_loading = useSelector((state) => state.data.loading);
  const check_error = useSelector((state) => state.data.error);

  useEffect(() => {
    if(Role_id != "" && Role_id != null && Role_id != "null"){
      dispatch(transection_report(setTransectionData));
    }
  }, []);

  
  const columns=[
    {
      name: "Status",
      label: "Status",
      options: {
        filter: true,
        sort: false,
        customHeadLabelRender: () => {
          return (
            <>
         
                {/* <div > */}
                  <p
                    style={{display: "flex",
                    alignItems: "center" ,fontSize: "15px",
                    marginTop: "8px",
                    marginBottom: "0.4rem",marginLeft:"4%"}}
                  >
                     Status
                 
                  </p>{" "}
                {/* </div> */}
              
            </>
          );
        },
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 0,
            minWidth:"100px",
            maxWidth:"100px"
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "white",
            zIndex: 1000
          }
        }),
  
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "left" }}>
              <p style={{ marginLeft: "-2%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "M",
      label: "M",
      options: {
        filter: false,
        sort: true,

       
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "M1",
      label: "M1",
      options: {
        filter: false,
        sort: true,
      
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "M2",
      label: "M2",
      options: {
        filter: false,
        sort: true,
      
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "M3",
      label: "M3",
      options: {
        filter: false,
        sort: true,
      
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "M4",
      label: "M4",
      options: {
        filter: false,
        sort: true,
      
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "M5",
      label: "M5",
      options: {
        filter: false,
        sort: true,
      
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "M6",
      label: "M6",
      options: {
        filter: false,
        sort: true,
      
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Q",
      label: "Q",
      options: {
        filter: false,
        sort: true,

      
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Q1",
      label: "Q1",
      options: {
        filter: false,
        sort: true,
      
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Q2",
      label: "Q2",
      options: {
        filter: false,
        sort: true,
      
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Q3",
      label: "Q3",
      options: {
        filter: false,
        sort: true,
      
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Q4",
      label: "Q4",
      options: {
        filter: false,
        sort: true,
      
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Q5",
      label: "Q5",
      options: {
        filter: false,
        sort: true,
     
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Q6",
      label: "Q6",
      options: {
        filter: false,
        sort: true,
     
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Y",
      label: "Y",
      options: {
        filter: false,
        sort: true,
     
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Y1",
      label: "Y1",
      options: {
        filter: false,
        sort: true,
     

        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Y2",
      label: "Y2",
      options: {
        filter: false,
        sort: true,
     
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Y3",
      label: "Y3",
      options: {
        filter: false,
        sort: true,
     
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Y4",
      label: "Y4",
      options: {
        filter: false,
        sort: true,
     
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Y5",
      label: "Y5",
      options: {
        filter: false,
        sort: true,
     

        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Y6",
      label: "Y6",
      options: {
        filter: false,
        sort: true,
     
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
  ]

  const options = {
    filterType: "checkbox",
  };
  return (
    <>
      <MiniDrawer />
      {check_loading ? (
        <img id={styles.loader_sample} src={report_loader} />
      ) : (
        <>
          <div
           className={styles.transaction_tbl}
            style={{
              marginLeft: "2%",
              marginTop: "2%",
              width: "96%",
              marginBottom: "3%",
            }}
          >
            <MUIDataTable
              title={<h5 style={{ textAlign: "left" }}>Transaction Report</h5>}
              data={transectionData}
              columns={columns}
              options={{
                options: options,
                selectableRows: "none",
                viewColumns: false,
                print: false,
                responsive: "standard",
                download: false,
                search: false,
                filter: false,
                fixedHeader: true,
                tableBodyMaxHeight: "60vh",
                pagination:false,
                textLabels: {
                  body: {
                    noMatch: "Data Not Available",
                  },
                },
                sortOrder: {
                  name: 'Q',
                  direction: 'desc'
                },
              }}
            />
          </div>
        </>
      )}
      <WebPage_info
       data={
        ["This dashboard displays Transaction amount made by each department, categorized by their status: approved, rejected, pending, and adjusted. It provides a clear breakdown of payment statuses."]
       }
      />
    </>
  );
}
export default Transaction_report;
