import React, { useEffect, useState } from "react";
import MiniDrawer from "../components/Minidrawer";
import Invoice_amt_graph from "../components/Invoice_amt_graph";
import Salesperson_tbl from "../components/Salesperson_tbl";
import Invoice_corporate_tbl from "../components/Invoice_corporate_tbl";
import styles from "../Styles/Table.module.css";
import report_loader from "../Media/ezgif.com-optimize.gif";
import { DateRangePicker, Stack } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import moment from "moment";
import dayjs from "dayjs";
import WebPage_info from "../components/WebPage_info";

function Invoice_corporate() {
  const storage = window.sessionStorage;
  let Role_id = storage.getItem("Role_id");
  let dept = storage.getItem("DEPT");
  if(window.location.pathname == "/invoice_corporate"){
    storage.setItem("admin_param","2")
  }else{
    storage.setItem("admin_param","1")
  }
  let admin_param = storage.getItem("admin_param");
  let emp_code = storage.getItem("EMP_CODE");

  const [status_data, setStatus_Data] = useState([]);
  const [sales_person_data, setSales_Person_Data] = useState([]);
  const [selected_status, setSelected_Status] = useState("all");
  const [selected_sales_person, setSelected_Sales_Person] = useState("all");
  const [selected_credit, setSelected_Credit] = useState("all");
  const [range_flag, setRange_Flag] = useState(false);
  const [params, setParams] = useState("MTD");
  const [selectedFromDate, setSelectedFromDate] = useState(new Date());
  const [selectedToDate, setSelectedToDate] = useState(new Date());
  const [corporate_invoice_data, setCorporate_Invoice_Data] = useState([]);
  const [label_arr, setLabel_Arr] = useState([]);
  const [invoice_tbl_data, setInvoice_Tbl_Data] = useState([]);
  const [salesperson_amt_data, setSalesperson_Amt_Data] = useState([]);
  const [sales_amount_total, setSales_Person_Total] = useState(0);
  const [graph_load, setGraph_Load] = useState(false);
  const [tabel_load, setTable_Load] = useState(false);
  const [status_load, setStatus_Load] = useState(false);
  const [sales_load, setSales_Load] = useState(false);

  const get_invoice_status = async () => {
    setStatus_Load(true);
    try {
      let res = await fetch(`https://reportapi.equinoxlab.com/invoice_status`);
      let data = await res.json();
      setStatus_Load(false);
      setStatus_Data(data?.Data);
    } catch (error) {
      setStatus_Load(false);
    }
  };
  // const get_sales_person = async()=>{
  //   setSales_Load(true)
  //   try {
  //     let res = await fetch(`https://reportapi.equinoxlab.com/corporate_salesperson`);
  //     let data = await res.json();
  //     setSales_Load(false)
  //     setSales_Person_Data(data?.Data)
  //   } catch (error) {
  //     setSales_Load(false)
  //   }
  // }
  const get_sales_person = async () => {
    setSales_Load(true);
    try {
      let res = await fetch(
        `https://reportapi.equinoxlab.com/get_unique_salesperson_tl`,
        // `https://reportapi.equinoxlab.com/get_unique_salesperson`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            emp_code_input: emp_code,
            dept_id:
              dept == "Tech" || dept == "Admin" || dept == "Management"
                ? admin_param
                : dept == "FnB Sales"
                ? "1"
                : dept == "Corporate Sales"
                ? "2"
                : "all",
          }),
        }
      );
      // let res = await fetch(`https://reportapi.equinoxlab.com/corporate_salesperson`);
      let data = await res.json();
      setSales_Load(false);
      setSales_Person_Data(data?.Data);
    } catch (error) {
      setSales_Load(false);
    }
  };

  const get_invoice_graph_data = async (val_params) => {
    setSalesperson_Amt_Data([]);
    setCorporate_Invoice_Data([]);
    setLabel_Arr([]);
    setGraph_Load(true);
    try {
      let res = await fetch(
        `https://reportapi.equinoxlab.com/invoice_yearly_dashboard_by_dept_tl`,
        // `https://reportapi.equinoxlab.com/invoice_yearly_dashboard_by_dept`,
        {
          // let res = await fetch(`https://reportapi.equinoxlab.com/corporate_invoice_yearly_dashboard`,{
          method: "POST",
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            // invoice_sts: "",
            // credit_sts: "",
            // salesperson: "",
            // get_date: "",
            // start_date: "2024-08-01",
            // end_date:  "2024-08-09"
            emp_code_input: emp_code,
            invoice_sts: selected_status,
            credit_sts: selected_credit,
            salesperson: selected_sales_person,
            get_date: val_params == "Custom Date" ? "" : val_params,
            start_date:
              val_params == "Custom Date"
                ? moment(selectedFromDate).format("YYYY-MM-DD")
                : "",
            end_date:
              val_params == "Custom Date"
                ? moment(selectedToDate).format("YYYY-MM-DD")
                : "",
            dept_id:
              dept == "Tech" || dept == "Admin" || dept == "Management"
                ? admin_param
                : dept == "FnB Sales"
                ? "1"
                : dept == "Corporate Sales"
                ? "2"
                : "all",
          }),
        }
      );
      let data = await res.json();
      setGraph_Load(false);
      if (data?.Data.length > 0) {
        setSalesperson_Amt_Data(data?.Data);
        let invoice_data = [];
        let invoice_label = [];
        for (let i = 0; i < data?.Data?.length; i++) {
          invoice_data.push(data?.Data[i]?.InvoiceAmount);
          invoice_label.push(data?.Data[i]?.SalesPerson);
        }
        setCorporate_Invoice_Data(invoice_data);
        setLabel_Arr(invoice_label);

        let total_count = data?.Data?.reduce((acc, ele) => {
          return acc + Number(ele?.InvoiceAmount);
        }, 0);
        setSales_Person_Total(total_count);
      } else {
        setSales_Person_Total(0);
      }
    } catch (error) {
      setGraph_Load(false);
    }
  };

  const get_invoice_tbl_data = async (val_params) => {
    setInvoice_Tbl_Data([]);
    setTable_Load(true);
    try {
      let res = await fetch(
        `https://reportapi.equinoxlab.com/yearly_invoice_summary_by_dept_tl`,
        // `https://reportapi.equinoxlab.com/yearly_invoice_summary_by_dept`,
        {
          // let res = await fetch(`https://reportapi.equinoxlab.com/corporate_yearly_invoice_summary`,{
          method: "POST",
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            emp_code_input: emp_code,
            invoice_sts: selected_status,
            credit_sts: selected_credit,
            salesperson: selected_sales_person,
            get_date: val_params == "Custom Date" ? "" : val_params,
            start_date:
              val_params == "Custom Date"
                ? moment(selectedFromDate).format("YYYY-MM-DD")
                : "",
            end_date:
              val_params == "Custom Date"
                ? moment(selectedToDate).format("YYYY-MM-DD")
                : "",
            dept_id:
              dept == "Tech" || dept == "Admin" || dept == "Management"
                ? admin_param
                : dept == "FnB Sales"
                ? "1"
                : dept == "Corporate Sales"
                ? "2"
                : "all",
          }),
        }
      );
      let data = await res.json();
      setTable_Load(false);
      setInvoice_Tbl_Data(data?.Data);
    } catch (error) {
      setTable_Load(false);
    }
  };

  const handleDateRange = (e) => {
    setParams(e.target.value);
    if (e.target.value == "Custom Date") {
      setRange_Flag(true);
    } else {
      setRange_Flag(false);
      get_invoice_graph_data(e.target.value);
      get_invoice_tbl_data(e.target.value);
    }
  };

  const handleDateChange = (new_val) => {
    if (new_val !== null) {
      setSelectedFromDate(new_val[0]);
      setSelectedToDate(new_val[1]);
    } else {
      setParams("MTD");
      setRange_Flag(false);
      setSelectedFromDate(new Date());
      setSelectedToDate(new Date());
      const fromattedDate = dayjs(new Date());
      let new_date = moment(fromattedDate.$d).format("YYYY-MM-DD");
    }
  };

  useEffect(() => {
    if (Role_id != "" && Role_id != null && Role_id != "null") {
      get_invoice_status();
      get_sales_person();
    }
  }, []);

  useEffect(() => {
    if (Role_id != "" && Role_id != null && Role_id != "null") {
      get_invoice_graph_data(params);
      get_invoice_tbl_data(params);
    }
  }, [
    selected_credit,
    selected_sales_person,
    selected_status,
    selectedFromDate,
  ]);

  return (
    <>
      <MiniDrawer />
      {sales_load || status_load ? (
        <div className="container-fluid pt-3">
          <img src={report_loader} id={styles.loader} />
        </div>
      ) : (
        <div className="container-fluid pt-3">
          {graph_load && tabel_load ? (
            <></>
          ) : (
            <div className="row">
              <div className={range_flag ? "col-2" : "col-4"}>
                {dept == "Tech" || dept == "Admin" || dept == "Management" ? (
                  <h4 style={{ textAlign: "left" }}>
                    Invoice Dashboard ({" "}
                    {admin_param == "1" ? "FnB" : "Corporate"} )
                  </h4>
                ) : (
                  <h4 style={{ textAlign: "left" }}>Invoice Dashboard</h4>
                )}
              </div>
              <div className="col-2">
                <select
                  onChange={(e) => setSelected_Status(e.target.value)}
                  value={selected_status}
                  className="form-select"
                  name=""
                  id=""
                >
                  <option value="all" selected>
                    All Invoice Status
                  </option>
                  {status_data?.map((ele) => (
                    <option value={ele?.invoice_status}>
                      {ele?.status_value}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-2">
                <select
                  onChange={(e) => setSelected_Credit(e.target.value)}
                  value={selected_credit}
                  className="form-select"
                  name=""
                  id=""
                >
                  <option value="all">All Credit Status</option>
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
              <div className="col-2">
                <select
                  onChange={(e) => setSelected_Sales_Person(e.target.value)}
                  value={selected_sales_person}
                  className="form-select"
                  name=""
                  id=""
                >
                  <option value="all">All Sales Person</option>
                  {sales_person_data?.map((ele) => (
                    <option value={ele?.salesperson}>{ele?.salesperson}</option>
                  ))}
                </select>
              </div>
              <div className="col-2">
                <select
                  onChange={handleDateRange}
                  value={params}
                  className="form-select"
                  style={{ marginRight: "20px", width: "100%" }}
                >
                  <option value="" selected disabled>
                    Select Date Range
                  </option>
                  <option value="Today">Today</option>
                  <option value="MTD">MTD</option>
                  <option value="QTD">QTD</option>
                  <option value="YTD">YTD</option>
                  <option value="FYTD">FYTD</option>
                  <option value="Custom Date">Custom Date</option>
                </select>
              </div>
              {range_flag ? (
                <div className="col-2">
                  <DateRangePicker
                    // defaultValue={[new Date(), new Date()]}
                    format="dd MMM yyyy"
                    value={[selectedFromDate, selectedToDate]}
                    onChange={handleDateChange}
                    size="md"
                    placement="bottomEnd"
                    preventOverflow
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          )}
          <div className="row mt-1">
            <div style={{ width: "90vw", overflow: "auto", margin: "auto" }}>
              {graph_load ? (
                <img src={report_loader} id={styles.graph_loader} />
              ) : (
                <Invoice_amt_graph
                  data={corporate_invoice_data}
                  xLabels={label_arr}
                />
              )}
            </div>
          </div>
          <div className="row mt-4 mb-5">
            <div className="col-3">
              {graph_load ? (
                <img src={report_loader} id={styles.graph_tbl_loader} />
              ) : (
                <Salesperson_tbl
                  data={salesperson_amt_data}
                  sales_amount_total={sales_amount_total}
                />
              )}
            </div>
            <div className="col-9">
              {tabel_load ? (
                <img src={report_loader} id={styles.graph_loader} />
              ) : (
                <Invoice_corporate_tbl data={invoice_tbl_data} />
              )}
            </div>
          </div>
        </div>
      )}
      <WebPage_info
       data={
        ["This dashboard displays the invoice amount by salesperson, organized month-wise. It also provides detailed invoice information for the selected salesperson. By default, the current month is displayed, but you can use the date picker to select and view data for a different date range."]
       }
      />
    </>
  );
}

export default Invoice_corporate;
