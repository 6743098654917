import React, { useEffect } from "react";
import MUIDataTable from "mui-datatables";
import MiniDrawer from "../components/Minidrawer";
import styles from "../Styles/Table.module.css";
import report_loader from "../Media/ezgif.com-optimize.gif";
import { useState } from "react";

import dayjs from "dayjs";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { logistics_report } from "../Redux/actions";
import Swal from "sweetalert2";
import { DatePicker, Space } from "antd";

import { DateRangePicker, Stack } from "rsuite";
import "rsuite/dist/rsuite.min.css";

const { RangePicker } = DatePicker;


function Logistics_Report({
  logisticsData,
  setLogisticsData,
  logistic_load,
  setLogistic_Load,
  send_from_date,
  send_to_date,
  setSend_from_Date,
  setSend_to_Date,
}) {
  const dateFormat = "DD-MM-YYYY";


  const formattedDate = dayjs(new Date());
  let new_date = moment(formattedDate.$d).format("YYYY-MM-DD");
  // let new_date = moment(formattedDate.$d).format("DD-MM-YYYY");

  const [selectedFromDate, setSelectedFromDate] = useState(new Date());
  const [selectedToDate, setSelectedToDate] = useState(new Date());

  const [showBtn, setShowBtn] = useState(false);
  // const [logistic_load,setLogistic_Load] = useState(false)

  const dispatch = useDispatch();
  const data = useSelector((state) => state.data.data);
  const check_loading = useSelector((state) => state.data.loading);
  const check_error = useSelector((state) => state.data.error);

  useEffect(() => {
    // dispatch(logistics_report(setLogisticsData, send_from_date, send_to_date,setLogistic_Load))
  }, []);

  const handleDateChange = (new_val) => {
    // if (new_val !== null) {
    //   setSelectedFromDate(new_val[0]);
    //   setSelectedToDate(new_val[1]);
    //   let newStartDate = moment(new_val[0]?.$d).format("YYYY-MM-DD");
    //   let newEndDate = moment(new_val[1]?.$d).format("YYYY-MM-DD");
    //   setSend_from_Date(newStartDate);
    //   setSend_to_Date(newEndDate);
    //   setShowBtn(true);
    // }

    if (new_val !== null) {
      setSelectedFromDate(new_val[0]);
      setSelectedToDate(new_val[1]);

      let newStartDate = moment(new_val[0]).format("YYYY-MM-DD");
      let newEndDate = moment(new_val[1]).format("YYYY-MM-DD");

      // let newStartDate = moment(new_val[0]?.$d).format("YYYY-MM-DD");
      // let newEndDate = moment(new_val[1]?.$d).format("YYYY-MM-DD");

      // setStart_Date(newStartDate);
      // setEnd_Date(newEndDate);

      dispatch(
        logistics_report(
          setLogisticsData,
          newStartDate,
          newEndDate,
          setLogistic_Load
        )
      );
      // setShowBtn(true);
    } else {
      setSelectedFromDate(new Date());
      setSelectedToDate(new Date());
      const fromattedDate = dayjs(new Date());
      let new_date = moment(fromattedDate.$d).format("YYYY-MM-DD");
      dispatch(
        logistics_report(
          setLogisticsData,
          new_date,
          new_date,
          setLogistic_Load
        )
      );
    }

  };

    const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      setSelectedFromDate(dayjs(dates[0]?.$d))
      setSelectedToDate(dayjs(dates[1]?.$d))
      setSend_from_Date(dateStrings[0]);
      setSend_to_Date(dateStrings[1]);
      // setSelectedFromDate(dates[0]?.$d)
      // setSelectedToDate(dates[1]?.$d)

      setShowBtn(true);
    } else {
      setSelectedFromDate(dayjs(new Date()))
      setSelectedToDate(dayjs(new Date()))
      setSend_from_Date(new_date);
      setSend_to_Date(new_date)
      dispatch(
        logistics_report(
          setLogisticsData,
          new_date,
          new_date,
          setLogistic_Load
        )
      );
    }
  };
  const rangePresets = [
    {
      label: 'Last Week',
      value: [dayjs().add(-7, 'd'), dayjs()],
    },
    {
      label: 'Last 14 Days',
      value: [dayjs().add(-14, 'd'), dayjs()],
    },
    {
      label: 'Last 30 Days',
      value: [dayjs().add(-30, 'd'), dayjs()],
    },
    {
      label: 'Last 90 Days',
      value: [dayjs().add(-90, 'd'), dayjs()],
    },
  ];



  const handleClick = () => {

    if(send_from_date == "" || send_to_date == ""){
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        text: "Please Select Dates",
        customClass: {
          htmlContainer: 'custom-html-container',
        },
      });
    }else{
      dispatch(
        logistics_report(
          setLogisticsData,
          send_from_date,
          send_to_date,
          setLogistic_Load
        )
      );
      setShowBtn(false);
    }

  };

  // const handleStartDate = (new_val) => {
  //   setSelectedFromDate(new_val);
  //   let new_date = moment(new_val.$d).format("DD-MM-YYYY");
  //   setSend_from_Date(new_date);
  //   dispatch(logistics_report(setLogisticsData, new_date, send_to_date));
  // };

  // const handleEndDate = (new_val) => {
  //   setSelectedToDate(new_val);
  //   let new_date = moment(new_val.$d).format("DD-MM-YYYY");
  //   setSend_to_Date(new_date);
  //   dispatch(logistics_report(setLogisticsData, send_from_date, new_date));
  // };

  const columns = [
    {
      name: "hub_name",
      label: "Hub",
      options: {
        filter: false,
        sort: false,
        download: false,
      },
    },
    {
      name: "vr_count",
      label: "Total VR",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "on_time_count",
      label: "On-Time",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "delay_count",
      label: "Delayed",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "overdue_count",
      label: "Overdue",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (row) => {
          return <p style={{ marginLeft: "15%", marginTop: "15px" }}>{row}</p>;
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
  };

  return (
    <>
      <MiniDrawer />
      {logistic_load ? (
        <img src={report_loader} id={styles.loader_operation} />
      ) : (
        <div
          // className={styles.opertion_tbl}
          className="opertion_tbl"
          style={{
            marginLeft: "2%",
            marginTop: "2%",
            marginBottom: "5%",
            width: "96%",
          }}
        >
          <MUIDataTable
            title={<h5 style={{ textAlign: "left" }}>Collection Report</h5>}
            data={logisticsData}
            columns={columns}
            options={{
              options: options,
              selectableRows: "none",
              viewColumns: false,
              print: false,
              search: false,
              filter: false,
              responsive: "standard",
              download: false,
              fixedHeader: true,
              pagination: false,
              tableBodyMaxHeight: "60vh",
              setTableProps: () => {
                return {
                  padding: "default",
                };
              },
              customToolbar: () => {
                return (
                  <>
                    <div style={{ display: "inline-flex" }}>
                      <Space direction="vertical" size={12}>
                        {/* <RangePicker
                          // defaultValue={dayjs(value, "YYYY-MM-DD")} format={dateFormat}
                          format={"DD-MM-YYYY"}
                          value={[selectedFromDate, selectedToDate]}
                          onChange={(new_val) => handleDateChange(new_val)}
                        /> */}
                                                  <DateRangePicker
                                                  format = "dd MMM yyyy"
                            defaultValue={[new Date(), new Date()]}
                            value={[selectedFromDate, selectedToDate]}
                            onChange={handleDateChange}
                            size='md'
                          placement='bottomEnd'
                          preventOverflow
                          />
                          {/* <RangePicker presets={rangePresets} value={[selectedFromDate, selectedToDate]} onChange={onRangeChange} /> */}
                      </Space>
                      {/* <button
                        className="btn btn-primary btn-sm ml-2"
                        onClick={handleClick}
                        style={{
                          display: showBtn ? "block" : "none",
                          marginLeft: "5px",
                          backgroundColor:"white",
                          color:"#007cc3"
                        }}
                      >
                        Apply
                      </button> */}
                    </div>
                  </>
                );
              },
              textLabels: {
                body: {
                  noMatch: "Data Not Available",
                },
              },
            }}
          />
        </div>
      )}
    </>
  );
}
export default Logistics_Report;
