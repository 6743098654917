import React, { useState } from 'react'
import InfoIcon from "@mui/icons-material/Info";
import { Menu, MenuItem } from "@mui/material";
import styles from "../Styles/Login.module.css";

function WebPage_info({data}) {

    const [anchorEl, setAnchorEl] = useState(null);

    function handleClose() {
        setAnchorEl(null);
      }
    
      const handleView = (e) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
      };
  return (
    <>
    <div onMouseEnter={handleView} id={styles.info_icon_contain}>
    <InfoIcon sx={{ height: "3vh", color: "white" }} />
  </div>
  <Menu
    id="menu-appbar"
    anchorEl={anchorEl}
    MenuListProps={{ onMouseLeave: handleClose }}
    anchorOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    keepMounted
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    open={Boolean(anchorEl)}
    onClose={handleClose}
  >
    {/* <MenuItem > */}
    <div id={styles.process_contain}>
      {data?.map((ele)=>(
        <p>{ele}</p>
      ))}
    </div>
    {/* {data?.map((ele)=>{
        let single_info = ele?.split(":")
        return(
          <>
          <p><span style={{fontWeight:"bold"}}>{single_info[0]} :</span>{single_info[1]}</p>
         </>
        ) */}
    {/* </MenuItem> */}
  </Menu>
  </>
  )
}

export default WebPage_info
