import React from 'react'
import MUIDataTable from 'mui-datatables';
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import styles from "../Styles/Table.module.css";


function Assigned_leads_tbl({data,flag}) {
    const get_total = (val)=>{
        if(val == "Assigned (COR)"){
          let total_count = data?.reduce((acc,ele)=>{
            return acc+Number(ele?.total_assigned_leads)
         },0)
         return total_count;
        }  else if(val == "Relevant"){
          let total_count = data?.reduce((acc,ele)=>{
            return acc+Number(ele?.total_relevant_leads)
         },0)
         return total_count;
        }
        // else if(val == "Relevance Ratio"){
        else if(val == "Total No Contact Lead"){
          let total_count = data?.reduce((acc,ele)=>{
            return acc+Number(ele?.total_no_contact_leads)
         },0)
         if(total_count == 0){
          return Number(total_count);
        }else{
          return Number(total_count.toFixed(2));
        }
        }
        else if(val == "Coverted"){
          let total_count = data?.reduce((acc,ele)=>{
            return acc+Number(ele?.total_converted_leads)
         },0)
         return total_count;
        }
        else{
          let total_count = data?.reduce((acc,ele)=>{
            return acc+Number(ele?.proposal_amount)
         },0)
         if(total_count == 0){
          return total_count;
        }else{
          return `${new Intl.NumberFormat("en-IN").format(total_count.toFixed(2))}`;
        }
        }
      }
    
        const options = {
            filterType: "checkbox",
          };
          const columns = [
        
            {
              name: "category",
              label: `Category`,
              options: {
                filter: false,
                sort: false,
                customHeadLabelRender: () => {
                  return (
                    <>
                        <div style={{ display: "flex",justifyContent:"left",paddingLeft:"10px" }} >
                          <span id="name_bde">Category</span>
                        </div>
  
                    </>
                  );
                },
                  customBodyRender:(row)=>{
                    return(
                       <>
                          <div style={{display:"flex",justifyContent:"left",paddingLeft:"8px"}}>
                            <span style={{textAlign:"left"}}>{row}</span>
                          </div>
                       </>
                    )
                  }
              }
            },
          
            {
              name: "total_assigned_leads",
              label: flag == "fnb"?`Assigned (FnB)`:`Assigned (COR)`,
              options: {
                filter: false,
                sort: false,
              },
            },
          
            {
              name: "total_relevant_leads",
              label: `Relevant`,
              options: {
                filter: false,
                sort: false,
               
              },
            },
            // {
            //   name: "relevant_ratio",
            //   label: `Relevance Ratio`,
            //   options: {
            //     filter: false,
            //     sort: false,
            //     customBodyRender:(row)=>{
            //       if(row == 0 || row == null || row == undefined){
            //         return(
            //           <>
            //           {row}
            //           </>
            //         )
            //       }else{
            //         return(
            //           <>
            //           {row.toFixed(2)}%
            //           </>
            //         )
            //       }
          
            //      }


            //   },
            // },
            {
              name: "total_no_contact_leads",
              label: `No Contact`,
              options: {
                filter: false,
                sort: false,
                customBodyRender:(row)=>{
                  if(row == 0 || row == null || row == undefined){
                    return(
                      <>
                      0
                      </>
                    )
                  }else{
                    return(
                      <>
                      {row}
                      </>
                    )
                  }
          
                 }


              },
            },
            {
              name: "total_converted_leads",
              label: `Converted`,
              options: {
                filter: false,
                sort: false,
               
              },
            },
            // {
            //   name: "converted_ratio",
            //   label: `Conversion Ratio`,
            //   options: {
            //     filter: false,
            //     sort: false,
            //     customBodyRender:(row)=>{
            //       if(row == 0 || row == null || row == undefined){
            //         return(
            //           <>
            //           {row}
            //           </>
            //         )
            //       }else{
            //         return(
            //           <>
            //           {row.toFixed(2)}%
            //           </>
            //         )
            //       }
          
            //      }
            //   },
            // },
            {
              name: "proposal_amount",
              label: `Proposal Amount`,
              options: {
                filter: false,
                sort: false,
                customHeadLabelRender: () => {
                  return (
                    <>
                        <div style={{ display: "flex",justifyContent:"center", }}>
                          <p
                            style={{display: "flex",
                            justifyContent:"center",
                            alignItems: "center" ,fontSize: "15px",
                            marginTop: "8px",
                            marginBottom: "0.4rem"}}
                          >Proposal Amount (&#8377;)</p>
                        </div>
                    </>
                  );
                },
                customBodyRender:(row)=>{
                  if(row == 0 || row == null || row == undefined){
                    return(
                      <>
                      0
                      </>
                    )
                  }else{
                    return(
                      <>
                      {new Intl.NumberFormat("en-IN").format(row)}
                      </>
                    )
                  }
          
                 }
              },
            },
          
      
          ];
  return (
    <div>
       <MUIDataTable
                title={<h5 style={{ textAlign: "left" }}>{flag =="fnb"? "FnB Sales Assigned Leads":"Corporate Sales Assigned Leads"}</h5>}
                data={data}
                columns={columns}
                options={{
                  options: options,
                  selectableRows: "none",
                  viewColumns: false,
                  filter: false,
                  print: false,
                  responsive: "standard",
                  download: false,
                  fixedHeader: true,
                  // tableBodyMaxHeight: "50vh",
                  rowsPerPageOptions:[10,30,50,100],
                  // rowsPerPage: "",
                  sortOrder: {
                    name: 'InvoiceAmount',
                    direction: 'desc'
                  },
                  setTableProps: () => {
                    return {
                      padding: 0,
                    };
                  },
                  textLabels: {
                    body: {
                      noMatch: "Data Not Available"
                    },
                  },
                  customTableBodyFooterRender: (
                      count,
                      page,
                      rowsPerPage,
                      changeRowsPerPage,
                      changePage,
                      data
                    ) => {
                      const startIndex = page * rowsPerPage;
                      const endIndex = (page + 1) * rowsPerPage;
                      return (
                        <TableFooter id={styles.table_footer}>
                          <TableRow>
                        
                            <TableCell ><p style={{textAlign:"left",marginLeft:"6px"}}>Grand Total</p></TableCell>
                            <TableCell><p style={{textAlign:"center"}}>{get_total("Assigned (COR)")}</p></TableCell>
                            <TableCell><p style={{textAlign:"center"}}>{get_total("Relevant")}</p></TableCell>
                            <TableCell><p style={{textAlign:"center"}}>{get_total("Total No Contact Lead")}</p></TableCell>
                            <TableCell><p style={{textAlign:"center"}}>{get_total("Coverted")}</p></TableCell>
                            <TableCell><p style={{textAlign:"center"}}>{get_total("Proposal Amount")}</p></TableCell>
                          
                          </TableRow>
                        </TableFooter>
                      );
                    },
                 
                }}
              />
    </div>
  )
}

export default Assigned_leads_tbl
