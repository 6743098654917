import React, { useEffect, useState } from "react";
import MiniDrawer from "../components/Minidrawer";
import { DateRangePicker, Stack } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import moment from "moment";
import dayjs from "dayjs";
import styles from "../Styles/Table.module.css";
import MUIDataTable from "mui-datatables";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import report_loader from "../Media/ezgif.com-optimize.gif";
import WebPage_info from "../components/WebPage_info";

function Billing_report_monthly_digest({ data }) {
  let date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();

  const storage = window.sessionStorage;

  let Role_id = storage.getItem("Role_id");
  let emp_code = storage.getItem("EMP_CODE");

  const [selectedFromDate, setSelectedFromDate] = useState(new Date(y, m, 1));
  const [selectedToDate, setSelectedToDate] = useState(new Date());

  const [billing_data, setBilling_Data] = useState([]);
  const [billing_count_data, setBilling_Count_Data] = useState([]);

  const [billing_load, setBilling_Load] = useState(false);
  const [count_load, setCount_Load] = useState(false);

  const [collected_total, setCollected_Total] = useState(0);
  const [estimated_total, setEstimated_Total] = useState(0);
  const [actual_total, setActual_Total] = useState(0);

  const handleDateChange = (new_val) => {
    if (new_val !== null) {
      setSelectedFromDate(new_val[0]);
      setSelectedToDate(new_val[1]);
    } else {
      //   setParams("MTD");
      //   setRange_Flag(false);
      let date = new Date(),
        y = date.getFullYear(),
        m = date.getMonth();
      setSelectedFromDate(new Date(y, m, 1));
      setSelectedToDate(new Date());
    }
  };

  const get_total = (param) => {
    if (param == "pmtd_collected") {
      let total_count = billing_data?.reduce((acc, ele) => {
        return acc + Number(ele?.collected_samples_p);
      }, 0);
      return total_count;
    } else if (param == "mtd_collected") {
      let total_count = billing_data?.reduce((acc, ele) => {
        return acc + Number(ele?.collected_sample_mtd);
      }, 0);
      return total_count;
    } else if (param == "pmtd_estimated") {
      let total_count = billing_data?.reduce((acc, ele) => {
        return acc + Number(ele?.estimated_amount_p);
      }, 0);
      return new Intl.NumberFormat("en-IN").format(total_count);
    } else if (param == "mtd_estimated") {
      let total_count = billing_data?.reduce((acc, ele) => {
        return acc + Number(ele?.estimated_amount_mtd);
      }, 0);
      return new Intl.NumberFormat("en-IN").format(total_count);
    } else if (param == "pmtd_billing") {
      let total_count = billing_data?.reduce((acc, ele) => {
        return acc + Number(ele?.actual_billing_p);
      }, 0);
      return new Intl.NumberFormat("en-IN").format(total_count);
    } else {
      let total_count = billing_data?.reduce((acc, ele) => {
        return acc + Number(ele?.actual_billing_mtd);
      }, 0);
      return new Intl.NumberFormat("en-IN").format(total_count);
    }
  };

  const get_billing_data = async () => {
    setBilling_Load(true);
    try {
      let res = await fetch(
        `https://reportapi.equinoxlab.com/estimated_billing_report_tl`,
        {
          // let res = await fetch(`https://reportapi.equinoxlab.com/estimated_billing_report`,{
          method: "POST",
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            start_date: moment(selectedFromDate).format("YYYY-MM-DD"),
            end_date: moment(selectedToDate).format("YYYY-MM-DD"),
            emp_code_input: emp_code,
          }),
        }
      );
      let data = await res.json();
      setBilling_Load(false);
      setBilling_Data(data?.Data);

      let total_actual = data?.Data?.reduce((acc, ele) => {
        return acc + Number(ele?.collected_sample_mtd);
      }, 0);

      let total_est = data?.Data?.reduce((acc, ele) => {
        return acc + Number(ele?.estimated_amount_mtd);
      }, 0);

      let total_bill = data?.Data?.reduce((acc, ele) => {
        return acc + Number(ele?.actual_billing_mtd);
      }, 0);

      setCollected_Total(total_actual);
      setEstimated_Total(total_est);
      setActual_Total(total_bill);
    } catch (error) {
      setBilling_Load(false);
    }
  };

  const get_billing_count = async () => {
    setCount_Load(true);
    try {
      let res = await fetch(
        `https://reportapi.equinoxlab.com/corporate_collected_actual_billing`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            start_date: moment(selectedFromDate).format("YYYY-MM-DD"),
            end_date: moment(selectedToDate).format("YYYY-MM-DD"),
          }),
        }
      );
      let data = await res.json();
      setCount_Load(false);
      setBilling_Count_Data(data?.Data);
    } catch (error) {
      setCount_Load(false);
    }
  };

  useEffect(() => {
    if (Role_id != "" && Role_id != null && Role_id != "null") {
      get_billing_data();
    }
    // get_billing_count();
  }, [selectedFromDate]);

  const options = {
    filterType: "checkbox",
  };
  const columns = [
    {
      name: "salesperson",
      label: `Sales Person`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "left" }}>
              <p>{row}</p>
            </div>
          );
        },
      },
    },

    {
      name: "collected_samples_p",
      label: "PMTD - Collected Samples",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "collected_sample_mtd",
      label: "MTD - Collected Samples",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "estimated_amount_p",
      label: "PMTD - Estimated Billing (₹)",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "estimated_amount_mtd",
      label: "MTD - Estimated Billing (₹)",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "actual_billing_p",
      label: "PMTD - Actual Billing (₹)",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "actual_billing_mtd",
      label: "MTD - Actual Billing",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
  ];

  return (
    <>
      <MiniDrawer />
      {billing_load ? (
        <div className="container-fluid pt-3">
          <img
            src={report_loader}
            style={{ marginTop: "50px" }}
            id={styles.graph_loader}
          />
        </div>
      ) : (
        <div className="container-fluid pt-3">
          <div className="row">
            <div className="col-9">
              <h4 style={{ textAlign: "left" }}>
                Estimated Billing Report (Monthly Digest)
              </h4>
            </div>
            <div className="col-3">
              <DateRangePicker
                // defaultValue={[new Date(), new Date()]}
                format="dd MMM yyyy"
                value={[selectedFromDate, selectedToDate]}
                onChange={handleDateChange}
                size="md"
                placement="bottomEnd"
                preventOverflow
              />
            </div>
          </div>
          <div className="row mt-3" id={styles.billing_tabs_container}>
            <div>
              <div>
                <p>Collected Samples</p>
              </div>
              {collected_total == undefined || collected_total == null ? (
                <h5>0</h5>
              ) : collected_total.toString()?.length > 10 ? (
                <marquee id={styles.billing_count} scrollamount="2">
                  {collected_total == undefined || collected_total == null
                    ? 0
                    : collected_total}
                </marquee>
              ) : (
                <h5>
                  {collected_total == undefined || collected_total == null
                    ? 0
                    : collected_total}
                </h5>
              )}
            </div>
            <div>
              <div>
                <p>Estimated Billing (₹)</p>
              </div>
              {estimated_total == undefined || estimated_total == null ? (
                <h5>0</h5>
              ) : estimated_total.toString()?.length > 10 ? (
                <marquee id={styles.billing_count} scrollamount="2">
                  {estimated_total == undefined || estimated_total == null
                    ? 0
                    : new Intl.NumberFormat("en-IN").format(estimated_total)}
                </marquee>
              ) : (
                <h5>
                  {estimated_total == undefined || estimated_total == null
                    ? 0
                    : new Intl.NumberFormat("en-IN").format(estimated_total)}
                </h5>
              )}
              {/* <p>-1,42,14,412</p> */}
            </div>
            <div>
              <div>
                <p>Actual Billing (₹)</p>
              </div>
              {actual_total == undefined || actual_total == null ? (
                <h5>0</h5>
              ) : actual_total.toString()?.length > 10 ? (
                <marquee id={styles.billing_count} scrollamount="2">
                  {actual_total == undefined || actual_total == null
                    ? 0
                    : new Intl.NumberFormat("en-IN").format(actual_total)}
                </marquee>
              ) : (
                <h5>
                  {actual_total == undefined || actual_total == null
                    ? 0
                    : new Intl.NumberFormat("en-IN").format(actual_total)}
                </h5>
              )}
            </div>
            {/* <div>
            <div>
              <p>Actual Billing</p>
            </div>
            <h5>4,58,74,452</h5>
            <p>1,18,54,352</p>
          </div> */}
          </div>
          <div className="row mt-4">
            <div id="billing_tbl" style={{ marginBottom: "40px" }}>
              <MUIDataTable
                title={
                  <h5 style={{ textAlign: "left" }}>Billing Report Details</h5>
                }
                data={billing_data}
                columns={columns}
                options={{
                  options: options,
                  selectableRows: "none",
                  viewColumns: false,
                  print: false,
                  responsive: "standard",
                  download: false,
                  fixedHeader: true,
                  // tableBodyMaxHeight: "40vh",
                  rowsPerPageOptions: [10, 25, 50, 100],
                  filter: false,
                  setTableProps: () => {
                    return {
                      padding: 0,
                    };
                  },
                  textLabels: {
                    body: {
                      noMatch: "Data Not Available",
                    },
                  },
                  customTableBodyFooterRender: (
                    count,
                    page,
                    rowsPerPage,
                    changeRowsPerPage,
                    changePage,
                    data
                  ) => {
                    const startIndex = page * rowsPerPage;
                    const endIndex = (page + 1) * rowsPerPage;
                    return (
                      <TableFooter id={styles.table_footer}>
                        <TableRow>
                          <TableCell id={styles.first_col}>
                            <p style={{ textAlign: "left" }}>Grand Total</p>
                          </TableCell>
                          <TableCell>
                            <p style={{ textAlign: "center" }}>
                              {get_total("pmtd_collected")}
                            </p>
                          </TableCell>
                          <TableCell>
                            <p style={{ textAlign: "center" }}>
                              {get_total("mtd_collected")}
                            </p>
                          </TableCell>
                          <TableCell>
                            <p style={{ textAlign: "center" }}>
                              {get_total("pmtd_estimated")}
                            </p>
                          </TableCell>
                          <TableCell>
                            <p style={{ textAlign: "center" }}>
                              {get_total("mtd_estimated")}
                            </p>
                          </TableCell>
                          <TableCell>
                            <p style={{ textAlign: "center" }}>
                              {get_total("pmtd_billing")}
                            </p>
                          </TableCell>
                          <TableCell>
                            <p style={{ textAlign: "center" }}>
                              {get_total("mtd_billing")}
                            </p>
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    );
                  },
                }}
              />
            </div>
          </div>
        </div>
      )}
      <WebPage_info
        data={[
          "This dashboard displays salesperson-wise data on projected and collected sample counts, estimated billing, and actual billing. It provides insights into both projected and collected values, helping track performance against targets. By default, the dashboard shows data for the current month, but you can use the date picker to view data for a different date range.",
        ]}
      />
    </>
  );
}

export default Billing_report_monthly_digest;
