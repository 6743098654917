import React, { useEffect, useState } from 'react'
import MiniDrawer from '../components/Minidrawer'
import Swal from 'sweetalert2'
import MUIDataTable from 'mui-datatables';
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import styles from "../Styles/Table.module.css";
import report_loader from "../Media/ezgif.com-optimize.gif";
import ReactReadMoreReadLess from "react-read-more-read-less";

import { DateRangePicker, Stack } from "rsuite";
import "rsuite/dist/rsuite.min.css";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Select2 from "react-select";
import moment from 'moment';
import WebPage_info from '../components/WebPage_info';

const customStyles = {
    option: (provided) => ({
      ...provided,
      textAlign: "left",
      fontSize: "14px",
      color:"black"
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "14px",
      textAlign: "left",
    }),
    valueContainer: (base) => ({
      ...base,
      maxHeight: "60px",
      overflowY: "auto",
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 9999, // Set this to the desired z-index
      }),
      menuPortal: (base) => ({ 
        ...base, 
        zIndex: 9999 
      }),
  };


function Fnb_order_details() {
  const storage = window.sessionStorage;
  let Role_id = storage.getItem("Role_id");
  
const [fnb_details,setFnb_Details] = useState([]);
const [data_load,setData_Load] = useState(false)
const [params, setParams] = useState("MTD");
const [range_flag, setRange_Flag] = useState(false);
const [selectedFromDate, setSelectedFromDate] = useState(new Date());
const [selectedToDate, setSelectedToDate] = useState(new Date());
const [date_change_flag,setDate_change_Flag] = useState(false);
const [total_oh_value,setTotal_Oh_Value] = useState(0);
const [salesperson_list,setSalesperson_List] = useState([]);
const [selected_salesperson,setSelected_salesperson] = useState([])

    const get_fnb_order_details = async()=>{
        setData_Load(true)
        let send_salesperson = [];
        for(let i=0; i<selected_salesperson?.length; i++){
            send_salesperson.push(selected_salesperson[i]?.label)
        }

        try {
            let res = await fetch(`https://reportapi.equinoxlab.com/fnb_order_details`,{
                method:"POST",
                headers: {"Content-Type" : "Application/JSON"},
                body: JSON.stringify({
                    "start_date":params == "Custom Date"?moment(selectedFromDate).format("YYYY-MM-DD"):"",
                    "end_date":params == "Custom Date"?moment(selectedToDate).format("YYYY-MM-DD"):"",
                    "get_date":params == "Custom Date"?"":params,
                    "salesperson":selected_salesperson?.length == "0"?"all":send_salesperson?.toString()
                })
            })
            let data = await res.json();
            setData_Load(false)
             if(data?.response?.code == "200"){
                setFnb_Details(data?.Data);
                let total = 0;
                for(let i=0; i<data?.Data?.length; i++){
                   total+= Number(data?.Data[i]?.oh_value)
                }
                setTotal_Oh_Value(new Intl.NumberFormat("en-IN").format(total))
             }else{
                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 1500,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener("mouseenter", Swal.stopTimer);
                      toast.addEventListener("mouseleave", Swal.resumeTimer);
                    },
                  });
                  Toast.fire({
                    icon: "error",
                    text: data.response?.message,
                    customClass: {
                      htmlContainer: 'custom-html-container',
                    },
                  });
             }
        } catch (error) {
            setData_Load(false)
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 1500,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });
              Toast.fire({
                icon: "error",
                text: "Something Went Wrong",
                customClass: {
                  htmlContainer: 'custom-html-container',
                },
              });
        }
    }
    const get_salesperson = async()=>{
        // setData_Load(true)
        try {
            let res = await fetch(`https://reportapi.equinoxlab.com/get_unique_salesperson`,{
                method:"POST",
                headers: {"Content-Type" : "Application/JSON"},
                body: JSON.stringify({
                  "dept_id":"1"
                })
            })
            let data = await res.json();
            // setData_Load(false)
             if(data?.response?.code == "200"){
                let dropdown_data = [];
                for (let i = 0; i < data?.Data?.length; i++) {
                  dropdown_data.push({
                    label: data?.Data[i]?.salesperson,
                    value: data?.Data[i]?.salesperson,
                  });
                }

         
                setSalesperson_List(dropdown_data);
       
             }else{
                setSalesperson_List([])
                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 1500,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener("mouseenter", Swal.stopTimer);
                      toast.addEventListener("mouseleave", Swal.resumeTimer);
                    },
                  });
                  Toast.fire({
                    icon: "error",
                    text: data.response?.message,
                    customClass: {
                      htmlContainer: 'custom-html-container',
                    },
                  });
             }
        } catch (error) {
            // setData_Load(false)
            setSalesperson_List([])
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 1500,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });
              Toast.fire({
                icon: "error",
                text: "Something Went Wrong",
                customClass: {
                  htmlContainer: 'custom-html-container',
                },
              });
        }
    }

    const handleDateRange = (e) => {
        if (e.target.value == "Custom Date") {
          setParams("Custom Date");
          setRange_Flag(true);
        } else {
          setRange_Flag(false);
          setParams(e.target.value);
          setSelectedFromDate(new Date());
          setSelectedToDate(new Date());

     
        }
      };
    

      const handleSelectDateRange = (new_val) => {
        if (new_val !== null) {
            setDate_change_Flag(true)
          setSelectedFromDate(new_val[0]);
          setSelectedToDate(new_val[1]);

        } else {
          setParams("MTD");
          setRange_Flag(false);
          setSelectedFromDate(new Date());
          setSelectedToDate(new Date());
          setDate_change_Flag(false)
      
        }
      };

      function getText(data) {
        var divContainer = document.createElement("div");
        divContainer.innerHTML = data;
        return divContainer.innerText || divContainer.textContent || "";
      }
    

    const options = {
        filterType: "checkbox",
      };
      const columns = [
    
        {
          name: "company_name",
          label: `Company Name`,
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({
              style: {
                padding: "10px",
              },
            }),
            customBodyRender:(row)=>{
                return(
                    <>
                    {/* <div style={{display:"flex",justifyContent:"left",padding:"7px"}}> */}
                        <h6 id={styles.order_data_units}>{row}</h6>
                    {/* </div> */}
                    </>
                )
            }
          },
        },
      
        {
          name: "contact_person_1",
          label: `Contact Person 1`,
          options: {
            filter: false,
            sort: false,
            customBodyRender:(row)=>{
                return(
                    <>
<h6 id={styles.order_data_units}>{row}</h6>
                    </>
                )
            }
          },
        },
        {
          name: "email",
          label: `Email`,
          options: {
            filter: false,
            sort: false,
            customBodyRender:(row)=>{
                return(
                    <>
    <h6 id={styles.order_data_units}>{row}</h6>
                    </>
                )
            }
          },
        },
        {
          name: "mobile",
          label: `Mobile`,
          options: {
            filter: false,
            sort: false,
            customBodyRender:(row)=>{
              return(
                  <>
  <h6 id={styles.order_data_units}>{row}</h6>
                  </>
              )
          }
          },
        },
        {
          name: "oh_no",
          label: `OH No`,
          options: {
            filter: false,
            sort: false,
   
          },
        },
        {
          name: "oh_value",
          label: `OH Value (₹)`,
          options: {
            filter: false,
            sort: true,
            customBodyRender:(row)=>{
              if(row == null){
                return <></>
              }
              return(
                  <>
   <h6 style={{textAlign:"center"}} id={styles.order_data_units}>{new Intl.NumberFormat("en-IN").format(row)}</h6>
                  </>
              )
          }
          },
        },
        {
          name: "salesperson",
          label: `Salesperson`,
          options: {
            filter: false,
            sort: false,
            customBodyRender:(row)=>{
                return(
                    <>
     <h6 id={styles.order_data_units}>{row}</h6>
                    </>
                )
            }
          },
        },
      
        {
          name: "testing_service_name",
          label: `Testing Service Name`,
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({
              style: { width: "250px", maxWidth: "250px" },
            }),
            customBodyRender:(row)=>{
                return(
                    <>
           <ReactReadMoreReadLess
                  sx={{ marginTop: "-100px" }}
                  charLimit={40}
                  readMoreText={"Read more ▼"}
                  readLessText={"Read less ▲"}
                  readMoreClassName={styles.readMoreClassName}
                  readLessClassName={styles.readLessClassName}
                >
                  {getText(row)}
                </ReactReadMoreReadLess>
                    </>
                )
            }
          },
        },
      
    
      ];


    useEffect(()=>{
      if(Role_id != "" && Role_id != null && Role_id != "null"){
    get_fnb_order_details();
      }
    },[selectedFromDate,selectedToDate,selected_salesperson])

    useEffect(()=>{
      if(Role_id != "" && Role_id != null && Role_id != "null"){
     get_salesperson();
      }
    },[])



  return (
    <>
    <MiniDrawer/>
    {data_load ? 
        <img src={report_loader} id={styles.loader_sample} />
       : 
    <div className='container-fluid pt-3'>
     
        <div  className='row'>
        <MUIDataTable
                title={<h5 style={{ textAlign: "left" }}>FnB Order Details
                             <button style={{marginLeft:"10px"}} id={styles.leads_container}>
                  Total OH Value : {total_oh_value}
                </button>
                <button style={{marginLeft:"10px"}} id={styles.leads_container}>
                  Total OH Count : {fnb_details?.length}
                </button>
                </h5>}
                data={fnb_details}
                columns={columns}
                options={{
                  options: options,
                  selectableRows: "none",
                  viewColumns: false,
                  filter: false,
                  print: false,
                  responsive: "standard",
                  download: false,
                  fixedHeader: true,
                  pagination:true,
                  tableBodyMaxHeight: "50vh",
                  search:false,
                  rowsPerPageOptions:[10,30,50,100],
                  // rowsPerPage: "",
                  sortOrder: {
                    name: 'InvoiceAmount',
                    direction: 'desc'
                  },
                  setTableProps: () => {
                    return {
                      padding: 0,
                    };
                  },
                  textLabels: {
                    body: {
                      noMatch: "Data Not Available"
                    },
                  },
                  customToolbar: ()=>{
                    return(
                        <div style={{ display: "inline-flex", gap: "5px" }}>
                            <div style={{width:"200px"}}>
                      <Select2
                      isMulti
                  name="label"
                  options={salesperson_list}
               className="basic-multi-select"
                        classNamePrefix="select"
                  styles={customStyles}
                  style={{width:"200px",backgroundColor:"white",height:"34px"}}
                  value={selected_salesperson}
                  onChange={(e)=>setSelected_salesperson(e)}
                  placeholder="Select Salespersons"
                  isClearable={true}
                  maxMenuHeight="180px"
                  required
                />

                </div>
                        <Box
                          sx={{
                            width: "160px",
                            backgroundColor: "white",
                            height: "37px",
                            borderRadius: "5px",
                          }}
                        >
                          <FormControl size="small" fullWidth>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              style={{
                                width: "160px",
                                height: "37px",
                                fontSize: "14px",
                                textAlign: "left",
                              }}
                              onChange={handleDateRange}
                              value={params}
                            >
                              <MenuItem value="" disabled>Please Select</MenuItem>
                              <MenuItem value="Today">Today</MenuItem>
                              <MenuItem value="MTD">MTD</MenuItem>
                              <MenuItem value="QTD">QTD</MenuItem>
                              <MenuItem value="YTD">YTD</MenuItem>
                              <MenuItem value="FYTD">FYTD</MenuItem>
                              <MenuItem value="Custom Date">Custom Date</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                
                        {range_flag ? (
                          <>
                            <DateRangePicker
                              // defaultValue={[new Date(), new Date()]}
                              format="dd MMM yyyy"
                              value={date_change_flag?[selectedFromDate, selectedToDate]:[]}
                              onChange={handleSelectDateRange}
                              size="md"
                              placement="bottomEnd"
                              placeholder="Select Date Range"
                              preventOverflow
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    )
                  }
         
                 
                }}
              />
        </div>
     
    </div>}
    <WebPage_info
     data={
      ["This dashboard displays the details of orders created, with data for the current month shown by default. Use the date picker to select and view data for a different date range."]
     }
    />
    </>
  )
}

export default Fnb_order_details
