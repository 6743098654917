import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { useDispatch, useSelector } from "react-redux";
import report_loader from "../Media/ezgif.com-optimize.gif";
import styles from "../Styles/Table.module.css";
import MiniDrawer from "../components/Minidrawer";
import { useNavigate } from "react-router-dom";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Swal from "sweetalert2";
import Category_leadSource_Revenue from "../components/Category_leadSource_Revenue";
import Category_leadSource_UpdatedOn from "../components/Category_leadSource_UpdatedOn";
import {
  category_data_marketing,
  category_revenue_marketing,
  category_updated_on_marketing,
  get_leadsource_stage_wise_data,
} from "../Latest_addition_redux/actions";
import WebPage_info from "../components/WebPage_info";
import Stagewise_leads_tbl from "../components/Stagewise_leads_tbl";

function Marketing_leadsource() {
  const storage = window.sessionStorage;

  const [cat_data, setCat_Data] = useState([]);
  const data = useSelector((state) => state.data.data);
  const dispatch = useDispatch();
  const [cat_drop, setCat_Drop] = useState([]);
  const [source_drop, setSource_Drop] = useState([]);
  const [selected_cat, setSelected_Cat] = useState("All");
  const [selected_source, setSelected_Source] = useState("ALL");
  const [createdOn_flag, setCreatedOn_flag] = useState(true);
  const [updatedOn_flag, setUpdatedOn_flag] = useState(false);
  const [revenue_flag, setRevenue_flag] = useState(false);
  const [leadStage_drop, setLeadStage_drop] = useState([]);
  const [leadStatus_drop, setLeadStatus_drop] = useState([]);
  const [select_leadStage, setSelected_leadStage] = useState("ALL");
  const [select_leadStatus, setSelected_leadStatus] = useState("ALL");
  const [show_leadStage, setShow_leadStage] = useState(true);
  const [cat_load, setCat_Load] = useState(false);
  const [stage_load, setStage_Load] = useState(false);
  const [stage_data, setStage_Data] = useState([]);
  const [stage_wise_show, setStage_Wise_Show] = useState(false);

  // const check_loading = useSelector((state) => state.data.loading);

  const [show, setShow] = useState(false);

  let img = storage.getItem("profile_img");
  let USER_GUID = storage.getItem("USER_GUID");
  let Role_id = storage.getItem("Role_id");
  let DEPT_ID = storage.getItem("DEPT_ID");
  let DEPT = storage.getItem("DEPT");

  const navigate = useNavigate();

  // React.useEffect(() => {
  //   if (USER_GUID == "" || USER_GUID == null) {
  //     navigate("/");
  //   }
  // }, [storage]);

  useEffect(() => {
    // if(selected_cat != "" && selected_source != "" && createdOn_flag){
    //   dispatch(category_data(setCat_Data, DEPT,selected_cat,selected_source));
    // }
    if (Role_id != "" && Role_id != null && Role_id != "null") {
      if (
        selected_cat != "" &&
        selected_source != "" &&
        createdOn_flag &&
        !stage_wise_show
      ) {
        dispatch(
          category_data_marketing(
            setCat_Data,
            DEPT,
            selected_cat,
            selected_source,
            select_leadStage,
            setCat_Load
          )
        );
      }
      if (
        selected_cat != "" &&
        selected_source != "" &&
        updatedOn_flag &&
        !stage_wise_show
      ) {
        dispatch(
          category_updated_on_marketing(
            setCat_Data,
            DEPT,
            selected_cat,
            selected_source,
            select_leadStatus,
            setCat_Load
          )
        );
      }
      if (selected_cat != "" && selected_source != "" && stage_wise_show) {
        dispatch(
          get_leadsource_stage_wise_data(
            setStage_Load,
            setStage_Data,
            selected_cat,
            selected_source
          )
        );
      }
    }
  }, [
    selected_source,
    selected_cat,
    select_leadStage,
    select_leadStatus,
    stage_wise_show,
  ]);

  const get_category = async () => {
    try {
      let res = await fetch(
        "https://reportapi.equinoxlab.com/get_category_marketing"
      );
      let data = await res.json();
      if (data.response?.code == "200") {
        setCat_Drop(data?.Data);
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          text: data?.response?.message,
          customClass: {
            htmlContainer: 'custom-html-container',
          },
        });
      }
    } catch (error) {}
  };

  const get_source = async (val) => {
    try {
      let res = await fetch(
        "https://reportapi.equinoxlab.com/get_leadsource_by_category_marketing",
        {
          method: "POST",
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            CATEGORY: val,
          }),
        }
      );
      let data = await res.json();
      if (data.response?.code == "200") {
        setSource_Drop(data?.Data);
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          text: data?.response?.message,
          customClass: {
            htmlContainer: 'custom-html-container',
          },
        });
      }
    } catch (error) {}
  };

  const get_leadStage = async () => {
    try {
      let res = await fetch("https://reportapi.equinoxlab.com/get_stage");
      let data = await res.json();
      setLeadStage_drop(data.Data);
    } catch (error) {}
  };
  const get_leadStatus = async () => {
    try {
      let res = await fetch("https://reportapi.equinoxlab.com/get_lead_status");
      let data = await res.json();
      setLeadStatus_drop(data.Data);
    } catch (error) {}
  };
  const handleLeadStatusChange = (e) => {
    setSelected_leadStatus(e.target.value);
  };

  const handleCreatedOn = async () => {
    setCreatedOn_flag(true);
    setUpdatedOn_flag(false);
    setRevenue_flag(false);
    setCat_Data([]);
    setSelected_leadStage("ALL");
    if (selected_cat != "" && selected_source != "") {
      dispatch(
        category_data_marketing(
          setCat_Data,
          DEPT,
          selected_cat,
          selected_source,
          select_leadStage,
          setCat_Load
        )
      );
    }
    if (selected_cat == "ALL") {
      setSource_Drop([]);
    }
  };
  const handleUpdatedOn = async () => {
    setCreatedOn_flag(false);
    setUpdatedOn_flag(true);
    setRevenue_flag(false);
    setCat_Data([]);
    get_leadStatus();
    setSelected_leadStatus("ALL");
    if (selected_cat != "" && selected_source != "") {
      dispatch(
        category_updated_on_marketing(
          setCat_Data,
          DEPT,
          selected_cat,
          selected_source,
          select_leadStatus,
          setCat_Load
        )
      );
    }
    if (selected_cat == "ALL") {
      setSource_Drop([]);
    }
  };

  // const handleRevenue = async () => {
  //   setCreatedOn_flag(false);
  //   setUpdatedOn_flag(false);
  //   setRevenue_flag(true);
  //   setCat_Data([])
  //   setSelected_leadStage("ALL")
  //   if(selected_cat != "" && selected_source != ""){
  //     dispatch(category_revenue_marketing(setCat_Data, DEPT,selected_cat, selected_source,));
  //   }
  //   if (selected_cat == "ALL" && revenue_flag){
  //     get_source("ALL")
  //   }
  //   else {
  //     get_source(selected_cat)
  //   }
  // }

  useEffect(() => {
    if (Role_id != "" && Role_id != null && Role_id != "null") {
      get_category();
      get_leadStage();
    }
  }, []);

  const handleCategory = (e) => {
    setSelected_Cat(e.target.value);
    if (e.target.value == "") {
      setSource_Drop([]);
      setSelected_Cat("ALL");
      setSelected_Source("ALL");
    } else {
      setSelected_Source("ALL");
      get_source(e.target.value);
    }
  };

  const handleSource = (e) => {
    if (e.target.value != "") {
      setSelected_Source(e.target.value);
    }
  };

  const handleLeadStageChange = (e) => {
    setSelected_leadStage(e.target.value);
    // if(e.target.value == "ALL" || e.target.value == ""){
    //   setSelected_Cat("ALL")
    //   setSelected_Source("ALL")
    // }
    // else {
    //     setSelected_Source("ALL")
    //     setSelected_Cat("ALL")
    //   }
  };

  const get_total = (val) => {
    if (val == "M") {
      let total_count = cat_data?.reduce((acc, ele) => {
        return acc + Number(ele?.M);
      }, 0);
      //  return total_count;
      return revenue_flag
        ? new Intl.NumberFormat("en-IN").format(total_count)
        : total_count;
    } else if (val == "M1") {
      let total_count = cat_data?.reduce((acc, ele) => {
        return acc + Number(ele?.M1);
      }, 0);
      return revenue_flag
        ? new Intl.NumberFormat("en-IN").format(total_count)
        : total_count;
    } else if (val == "M2") {
      let total_count = cat_data?.reduce((acc, ele) => {
        return acc + Number(ele?.M2);
      }, 0);
      return revenue_flag
        ? new Intl.NumberFormat("en-IN").format(total_count)
        : total_count;
    } else if (val == "M3") {
      let total_count = cat_data?.reduce((acc, ele) => {
        return acc + Number(ele?.M3);
      }, 0);
      return revenue_flag
        ? new Intl.NumberFormat("en-IN").format(total_count)
        : total_count;
    } else if (val == "M4") {
      let total_count = cat_data?.reduce((acc, ele) => {
        return acc + Number(ele?.M4);
      }, 0);
      return revenue_flag
        ? new Intl.NumberFormat("en-IN").format(total_count)
        : total_count;
    } else if (val == "M5") {
      let total_count = cat_data?.reduce((acc, ele) => {
        return acc + Number(ele?.M5);
      }, 0);
      return revenue_flag
        ? new Intl.NumberFormat("en-IN").format(total_count)
        : total_count;
    } else if (val == "M6") {
      let total_count = cat_data?.reduce((acc, ele) => {
        return acc + Number(ele?.M6);
      }, 0);
      return revenue_flag
        ? new Intl.NumberFormat("en-IN").format(total_count)
        : total_count;
    } else if (val == "Q") {
      let total_count = cat_data?.reduce((acc, ele) => {
        return acc + Number(ele?.Q);
      }, 0);
      return revenue_flag
        ? new Intl.NumberFormat("en-IN").format(total_count)
        : total_count;
    } else if (val == "Q1") {
      let total_count = cat_data?.reduce((acc, ele) => {
        return acc + Number(ele?.Q1);
      }, 0);
      return revenue_flag
        ? new Intl.NumberFormat("en-IN").format(total_count)
        : total_count;
    } else if (val == "Q2") {
      let total_count = cat_data?.reduce((acc, ele) => {
        return acc + Number(ele?.Q2);
      }, 0);
      return revenue_flag
        ? new Intl.NumberFormat("en-IN").format(total_count)
        : total_count;
    } else if (val == "Q3") {
      let total_count = cat_data?.reduce((acc, ele) => {
        return acc + Number(ele?.Q3);
      }, 0);
      return revenue_flag
        ? new Intl.NumberFormat("en-IN").format(total_count)
        : total_count;
    } else if (val == "Q4") {
      let total_count = cat_data?.reduce((acc, ele) => {
        return acc + Number(ele?.Q4);
      }, 0);
      return revenue_flag
        ? new Intl.NumberFormat("en-IN").format(total_count)
        : total_count;
    } else if (val == "Q5") {
      let total_count = cat_data?.reduce((acc, ele) => {
        return acc + Number(ele?.Q5);
      }, 0);
      return revenue_flag
        ? new Intl.NumberFormat("en-IN").format(total_count)
        : total_count;
    } else if (val == "Q6") {
      let total_count = cat_data?.reduce((acc, ele) => {
        return acc + Number(ele?.Q6);
      }, 0);
      return revenue_flag
        ? new Intl.NumberFormat("en-IN").format(total_count)
        : total_count;
    } else if (val == "Y") {
      let total_count = cat_data?.reduce((acc, ele) => {
        return acc + Number(ele?.Y);
      }, 0);
      return revenue_flag
        ? new Intl.NumberFormat("en-IN").format(total_count)
        : total_count;
    } else if (val == "Y1") {
      let total_count = cat_data?.reduce((acc, ele) => {
        return acc + Number(ele?.Y1);
      }, 0);
      return revenue_flag
        ? new Intl.NumberFormat("en-IN").format(total_count)
        : total_count;
    } else if (val == "Y2") {
      let total_count = cat_data?.reduce((acc, ele) => {
        return acc + Number(ele?.Y2);
      }, 0);
      return revenue_flag
        ? new Intl.NumberFormat("en-IN").format(total_count)
        : total_count;
    } else if (val == "Y3") {
      let total_count = cat_data?.reduce((acc, ele) => {
        return acc + Number(ele?.Y3);
      }, 0);
      return revenue_flag
        ? new Intl.NumberFormat("en-IN").format(total_count)
        : total_count;
    } else if (val == "Y4") {
      let total_count = cat_data?.reduce((acc, ele) => {
        return acc + Number(ele?.Y4);
      }, 0);
      return revenue_flag
        ? new Intl.NumberFormat("en-IN").format(total_count)
        : total_count;
    } else if (val == "Y5") {
      let total_count = cat_data?.reduce((acc, ele) => {
        return acc + Number(ele?.Y5);
      }, 0);
      return revenue_flag
        ? new Intl.NumberFormat("en-IN").format(total_count)
        : total_count;
    } else if (val == "Y6") {
      let total_count = cat_data?.reduce((acc, ele) => {
        return acc + Number(ele?.Y6);
      }, 0);
      return revenue_flag
        ? new Intl.NumberFormat("en-IN").format(total_count)
        : total_count;
    } else {
      let x = 1;
    }
  };

  const options = {
    filterType: "checkbox",
  };
  const columns = [
    {
      name: "Leadcat",
      label: "Lead Category",
      options: {
        filter: true,
        sort: false,

        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 0,
            // minWidth:"200px",
            // maxWidth:"200px"
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "white",
            zIndex: 1000,
          },
        }),

        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "left", paddingRight: "20px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "LeadSource",
      label: "Lead Source",
      options: {
        filter: true,
        sort: false,

        // setCellProps: () => ({
        //   style: { minWidth: "250px", maxWidth: "auto" },
        // }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "left", paddingRight: "10px" }}>
              <p style={{ marginLeft: "30%" }}>{row}</p>
            </div>
          );
        },
      },
    },

    {
      name: "M",
      label: "M",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
            color:
              (Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600) ||
              Number(cellValue?.props?.children) >= 600
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          // return <>{row}</>;
          return (
            <>
              {revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}
            </>
          );
        },
      },
    },
    {
      name: "M1",
      label: "M1",
      options: {
        filter: false,
        sort: true,
        tooltips: false,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
            color:
              (Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600) ||
              Number(cellValue?.props?.children) >= 600
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return (
            <>
              {revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}
            </>
          );
        },
      },
    },
    {
      name: "M2",
      label: "M2",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
            color:
              (Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600) ||
              Number(cellValue?.props?.children) >= 600
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return (
            <>
              {revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}
            </>
          );
        },
      },
    },
    {
      name: "M3",
      label: "M3",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
            color:
              (Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600) ||
              Number(cellValue?.props?.children) >= 600
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return (
            <>
              {revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}
            </>
          );
        },
      },
    },
    {
      name: "M4",
      label: "M4",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
            color:
              (Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600) ||
              Number(cellValue?.props?.children) >= 600
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return (
            <>
              {revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}
            </>
          );
        },
      },
    },
    {
      name: "M5",
      label: "M5",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
            color:
              (Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600) ||
              Number(cellValue?.props?.children) >= 600
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return (
            <>
              {revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}
            </>
          );
        },
      },
    },
    {
      name: "M6",
      label: "M6",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
            color:
              (Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600) ||
              Number(cellValue?.props?.children) >= 600
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return (
            <>
              {revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}
            </>
          );
        },
      },
    },
    {
      name: "Q",
      label: "Q",
      options: {
        filter: false,
        sort: true,
        rowsPerPageOptions: [10, 25, 50, 100],
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
            color:
              (Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600) ||
              Number(cellValue?.props?.children) >= 600
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return (
            <>
              {revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}
            </>
          );
        },
      },
    },
    {
      name: "Q1",
      label: "Q1",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
            color:
              (Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600) ||
              Number(cellValue?.props?.children) >= 600
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return (
            <>
              {revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}
            </>
          );
        },
      },
    },
    {
      name: "Q2",
      label: "Q2",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
            color:
              (Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600) ||
              Number(cellValue?.props?.children) >= 600
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return (
            <>
              {revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}
            </>
          );
        },
      },
    },
    {
      name: "Q3",
      label: "Q3",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
            color:
              (Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600) ||
              Number(cellValue?.props?.children) >= 600
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return (
            <>
              {revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}
            </>
          );
        },
      },
    },
    {
      name: "Q4",
      label: "Q4",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
            color:
              (Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600) ||
              Number(cellValue?.props?.children) >= 600
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return (
            <>
              {revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}
            </>
          );
        },
      },
    },
    {
      name: "Q5",
      label: "Q5",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
            color:
              (Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600) ||
              Number(cellValue?.props?.children) >= 600
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return (
            <>
              {revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}
            </>
          );
        },
      },
    },
    {
      name: "Q6",
      label: "Q6",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
            color:
              (Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600) ||
              Number(cellValue?.props?.children) >= 600
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return (
            <>
              {revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}
            </>
          );
        },
      },
    },
    {
      name: "Y",
      label: "Y",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
            color:
              (Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600) ||
              Number(cellValue?.props?.children) >= 600
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return (
            <>
              {revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}
            </>
          );
        },
      },
    },
    {
      name: "Y1",
      label: "Y1",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
            color:
              (Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600) ||
              Number(cellValue?.props?.children) >= 600
                ? "white"
                : "black",
          },
        }),

        customBodyRender: (row) => {
          return (
            <>
              {revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}
            </>
          );
        },
      },
    },
    {
      name: "Y2",
      label: "Y2",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
            color:
              (Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600) ||
              Number(cellValue?.props?.children) >= 600
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return (
            <>
              {revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}
            </>
          );
        },
      },
    },
    {
      name: "Y3",
      label: "Y3",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
            color:
              (Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600) ||
              Number(cellValue?.props?.children) >= 600
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return (
            <>
              {revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}
            </>
          );
        },
      },
    },
    {
      name: "Y4",
      label: "Y4",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
            color:
              (Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600) ||
              Number(cellValue?.props?.children) >= 600
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return (
            <>
              {revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}
            </>
          );
        },
      },
    },
    {
      name: "Y5",
      label: "Y5",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
            color:
              (Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600) ||
              Number(cellValue?.props?.children) >= 600
                ? "white"
                : "black",
          },
        }),

        customBodyRender: (row) => {
          return (
            <>
              {revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}
            </>
          );
        },
      },
    },
    {
      name: "Y6",
      label: "Y6",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
            color:
              (Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600) ||
              Number(cellValue?.props?.children) >= 600
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return (
            <>
              {revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}
            </>
          );
        },
      },
    },
  ];

  return (
    <>
      <MiniDrawer />
      {cat_load ? (
        <img src={report_loader} id={styles.loader} />
      ) : (
        <div
          className="container-fluid pt-3"
          id={cat_data?.length == 0 ? "" : "table_s6_category_tbl"}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "2vh",
            }}
          >
            {stage_wise_show ? (
              <></>
            ) : (
              <>
                <button
                  className={
                    createdOn_flag
                      ? "btn btn-primary btn-sm"
                      : "btn btn-outline-primary btn-sm"
                  }
                  style={{ marginRight: "10px" }}
                  onClick={handleCreatedOn}
                >
                  Created On
                </button>
                <button
                  className={
                    updatedOn_flag
                      ? "btn btn-primary btn-sm"
                      : "btn btn-outline-primary btn-sm"
                  }
                  style={{ marginRight: "10px" }}
                  onClick={handleUpdatedOn}
                >
                  Updated On
                </button>
              </>
            )}
            {/* <button
                     className={revenue_flag? "btn btn-primary btn-sm" : "btn btn-outline-primary btn-sm"}
                      style={{marginRight:"10px"}} 
                    onClick={handleRevenue}
                    >
                      Revenue
                    </button> */}
            {stage_load ? (
              <></>
            ) : (
              <button
                className={
                  stage_wise_show
                    ? "btn btn-primary btn-sm"
                    : "btn btn-outline-primary btn-sm"
                }
                style={{ marginRight: "10px" }}
                onClick={() => setStage_Wise_Show(!stage_wise_show)}
              >
                Stage wise
              </button>
            )}
          </div>
          {stage_wise_show ? (
            <Stagewise_leads_tbl
              data={stage_data}
              load={stage_load}
              cat_drop={cat_drop}
              source_drop={source_drop}
              selected_cat={selected_cat}
              selected_source={selected_source}
              handleCategory={handleCategory}
              handleSource={handleSource}
            />
          ) : (
            <>
              {
                createdOn_flag ? (
                  <MUIDataTable
                    title={
                      <h5 style={{ textAlign: "left" }}>
                        Categorywise Leadsource - Marketing
                      </h5>
                    }
                    data={cat_data}
                    columns={columns}
                    options={{
                      options: options,
                      selectableRows: "none",
                      viewColumns: false,
                      filter: false,
                      print: false,
                      responsive: "standard",
                      download: false,
                      fixedHeader: true,
                      rowsPerPageOptions: [10, 25, 50, 100],
                      tableBodyMaxHeight: "57vh",
                      sortOrder: {
                        name: "Q",
                        direction: "desc",
                      },
                      setTableProps: () => {
                        return {
                          padding: 0,
                        };
                      },
                      customToolbar: () => {
                        return (
                          <>
                            {revenue_flag ? (
                              <></>
                            ) : (
                              <select
                                id={styles.payment_drop}
                                name=""
                                value={select_leadStage}
                                onChange={handleLeadStageChange}
                                style={{ marginRight: "20px" }}
                              >
                                <option value="All">All Lead Stage</option>
                                {leadStage_drop?.map((ele) => (
                                  <option value={ele.Lead_Stage}>
                                    {ele.Lead_Stage}
                                  </option>
                                ))}
                              </select>
                            )}

                            <select
                              id={styles.payment_drop}
                              name=""
                              value={selected_cat}
                              onChange={handleCategory}
                            >
                              {revenue_flag ? (
                                <option
                                  value="ALL"
                                  style={{
                                    display: revenue_flag ? "block" : "none",
                                  }}
                                >
                                  ALL
                                </option>
                              ) : (
                                <option
                                  value="ALL"
                                  disabled={revenue_flag ? true : false}
                                >
                                  All Category
                                </option>
                              )}
                              {cat_drop?.map((ele) => (
                                <option value={ele.Leadcat}>
                                  {ele.Leadcat}
                                </option>
                              ))}
                            </select>
                            <select
                              id={styles.source_drop}
                              name=""
                              value={selected_source}
                              onChange={handleSource}
                            >
                              {/* <option  value="ALL" disabled selected >Select Lead Source</option> */}
                              <option value="" selected>
                                Select Source
                              </option>
                              <option value="ALL" selected>
                                All Source
                              </option>
                              {source_drop?.map((ele) => (
                                <option value={ele.Leadsource}>
                                  {ele.Leadsource}
                                </option>
                              ))}
                            </select>
                          </>
                        );
                      },

                      customTableBodyFooterRender: (
                        count,
                        page,
                        rowsPerPage,
                        changeRowsPerPage,
                        changePage,
                        data
                      ) => {
                        const startIndex = page * rowsPerPage;
                        const endIndex = (page + 1) * rowsPerPage;
                        return (
                          <TableFooter id={styles.table_footer}>
                            <TableRow>
                              <TableCell id={styles.first_col}>
                                <p>Total</p>
                              </TableCell>
                              <TableCell></TableCell>

                              <TableCell>
                                <p>{get_total("M")}</p>
                              </TableCell>
                              <TableCell>
                                <p>{get_total("M1")}</p>
                              </TableCell>
                              <TableCell>
                                <p>{get_total("M2")}</p>
                              </TableCell>
                              <TableCell>
                                <p>{get_total("M3")}</p>
                              </TableCell>
                              <TableCell>
                                <p>{get_total("M4")}</p>
                              </TableCell>
                              <TableCell>
                                <p>{get_total("M5")}</p>
                              </TableCell>
                              <TableCell>
                                <p>{get_total("M6")}</p>
                              </TableCell>
                              <TableCell>
                                <p>{get_total("Q")}</p>
                              </TableCell>
                              <TableCell>
                                <p>{get_total("Q1")}</p>
                              </TableCell>
                              <TableCell>
                                <p>{get_total("Q2")}</p>
                              </TableCell>
                              <TableCell>
                                <p>{get_total("Q3")}</p>
                              </TableCell>
                              <TableCell>
                                <p>{get_total("Q4")}</p>
                              </TableCell>
                              <TableCell>
                                <p>{get_total("Q5")}</p>
                              </TableCell>
                              <TableCell>
                                <p>{get_total("Q6")}</p>
                              </TableCell>
                              <TableCell>
                                <p>{get_total("Y")}</p>
                              </TableCell>
                              <TableCell>
                                <p>{get_total("Y1")}</p>
                              </TableCell>
                              <TableCell>
                                <p>{get_total("Y2")}</p>
                              </TableCell>
                              <TableCell>
                                <p>{get_total("Y3")}</p>
                              </TableCell>
                              <TableCell>
                                <p>{get_total("Y4")}</p>
                              </TableCell>
                              <TableCell>
                                <p>{get_total("Y5")}</p>
                              </TableCell>
                              <TableCell>
                                <p>{get_total("Y6")}</p>
                              </TableCell>
                            </TableRow>
                          </TableFooter>
                        );
                      },
                      textLabels: {
                        body: {
                          noMatch: "Data Not Available",
                        },
                      },
                    }}
                  />
                ) : updatedOn_flag ? (
                  <>
                    <Category_leadSource_UpdatedOn
                      revenue_flag={revenue_flag}
                      updatedOn_flag={updatedOn_flag}
                      handleUpdatedOn={handleUpdatedOn}
                      selected_cat={selected_cat}
                      cat_data={cat_data}
                      check_loading={cat_load}
                      get_total={get_total}
                      handleCategory={handleCategory}
                      cat_drop={cat_drop}
                      selected_source={selected_source}
                      setSelected_Source={setSelected_Source}
                      setSelected_Cat={setSelected_Cat}
                      source_drop={source_drop}
                      select_leadStatus={select_leadStatus}
                      select_leadStage={select_leadStage}
                      setSelected_leadStage={setSelected_leadStage}
                      leadStage_drop={leadStage_drop}
                      handleLeadStageChange={handleLeadStageChange}
                      marketing_flag={true}
                      leadStatus_drop={leadStatus_drop}
                      handleLeadStatusChange={handleLeadStatusChange}
                    />
                  </>
                ) : (
                  <></>
                )
                //  <Category_leadSource_Revenue
                //  revenue_flag={revenue_flag}
                //  handleRevenue={handleRevenue}
                //  selected_cat={selected_cat}
                //  cat_data={cat_data}
                //  check_loading={check_loading}
                //  get_total={get_total}
                //  handleCategory={handleCategory}
                //  cat_drop={cat_drop}
                //  selected_source={selected_source}
                //  setSelected_Source={setSelected_Source}
                //  setSelected_Cat={setSelected_Cat}
                //  source_drop={source_drop}

                //  />
              }
            </>
          )}
        </div>
      )}
      <WebPage_info
        data={
          createdOn_flag
            ? [
                "This dashboard displays the count of leads based on category and lead source, filtered by the date they were created. It includes filters for lead stage, category, and lead source, allowing users to focus on specific data. Additionally, there is a toggle to switch to an updated version of the dashboard for further analysis. The data can also be downloaded in Excel format using the download icon.",
              ]
            : [
                "This dashboard displays the count of leads based on category and lead source, filtered by the date they were updated.It includes filters for lead status, category, and lead source, allowing users to focus on specific data. The data can also be downloaded in Excel format using the download icon.",
              ]
        }
      />
    </>
  );
}

export default Marketing_leadsource;
