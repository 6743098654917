import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { order_data } from "../Redux/actions";
import styles from "../Styles/Table.module.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Order_history_tbl from "../components/Order_history_tbl";
import report_loader from "../Media/ezgif.com-optimize.gif";
import MiniDrawer from "../components/Minidrawer";
import { useNavigate } from "react-router-dom";
import no_data_icon from "../Media/no_data_icon.gif";
import Order_history_tbl_Count from "../components/Order_history_tbl_Count";
import Weekly_client_tbl from "../components/Weekly_client_tbl";
import { get_weekly_client_data } from "../Latest_addition_redux/actions";
import { useTheme } from "../ThemeContext";
import dayjs from "dayjs";
import moment from "moment";
import WebPage_info from "../components/WebPage_info";
// import MomentUtils from "@date-io/moment";
// import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

function Order_history() {
  const storage = window.sessionStorage;
  const { theme, toggleTheme } = useTheme();
  // const data = useSelector((state) => state.data.data);
  const check_loading = useSelector((state) => state.data.loading);
  const check_error = useSelector((state) => state.data.error);
  const [new_repeat_data, setNew_Repeat_Data] = useState([]);

  let DEPT = storage.getItem("DEPT");
  const [isChangeView, setIsChangeView] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - new_repeat_data?.length)
      : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  let current_year = new Date().getFullYear();
  let next_year = current_year + 1;

  const [department, setDepartment] = useState(DEPT);
  const [year, setYear] = useState("2024-2025");
  // const [year, setYear] = useState(current_year + "-" + next_year);

  const [weekly_department, setWeekly_Department] = useState(DEPT);
  const [weekly_load, setWeekly_Load] = useState(false);
  const [weekly_data, setWeekly_Data] = useState([]);
  const [weekly_dates, setWeekly_Dates] = useState([]);

  const formattedMonth = dayjs(new Date());
  let new_month = moment(formattedMonth.$d).format("MM");
  let new_year = moment(formattedMonth.$d).format("YYYY");
  const [selectedMonth, setSelectedMonth] = useState(dayjs(new Date()));
  const [send_month, setSend_month] = useState(new_month);
  const [send_year, setSend_year] = useState(new_year);

  let img = storage.getItem("profile_img");
  let USER_GUID = storage.getItem("USER_GUID");
  let emp_code = storage.getItem("EMP_CODE");
  let Role_id = storage.getItem("Role_id");
  let DEPT_ID = storage.getItem("DEPT_ID");

  const navigate = useNavigate();

  //   React.useEffect(()=>{
  //     if(USER_GUID == "" || USER_GUID == null){
  //       navigate("/")
  //     }
  //  },[storage])

  const dispatch = useDispatch();

  const handleMonth = (date) => {
    if (date) {
      setSelectedMonth(date);
      let formattedMon = moment(date?.$d)?.format("MM");
      let formattedYear = moment(date?.$d)?.format("YYYY");
      setSend_year(formattedYear);
      setSend_month(formattedMon);
    } else {
      const formattedMonth = dayjs(new Date());
      let new_month = moment(formattedMonth.$d).format("MM");
      let new_year = moment(formattedMonth.$d).format("YYYY");
      setSelectedMonth(formattedMonth);
      setSend_month(new_month);
      setSend_year(new_year);
    }
  };

  useEffect(() => {
    if (Role_id != "" && Role_id != null && Role_id != "null") {
      dispatch(order_data(department, year, setNew_Repeat_Data,emp_code));
    }
  }, [department, year]);

  useEffect(() => {
    if (Role_id != "" && Role_id != null && Role_id != "null") {
      dispatch(
        get_weekly_client_data(
          weekly_department,
          setWeekly_Load,
          setWeekly_Data,
          setWeekly_Dates,
          send_month,
          send_year,
          emp_code
        )
      );
    }
  }, [weekly_department, send_month]);
  const renderYearContent = (year) => {
    const tooltipText = `Tooltip for year: ${year}`;
    return <span title={tooltipText}>{year}</span>;
  };

  const handleDate = (e) => {
    setYear(e.target.value);
  };

  const handleLoad = () => {
    window.location.reload();
  };

  return (
    <>
      <MiniDrawer />
      <div className="container-fluid pt-3">
        {/* <div
          className="row"
          style={{
            display: "flex",
            justifyContent: "right",
            gap: "2%",

          }}
        >
          <div className="col-sm-8 col-md-7" id={styles.headerName}>
              <h5 className="h5 mt-2" style={{marginLeft:"20px"}}>New vs Repeated Order</h5>
          </div>
          <div className="col-sm-4 col-md-2">
            <select
              name=""
              id=""
              className="form-select"
              onChange={(e) => setDepartment(e.target.value)}

            >
              <option value="">Please Select Department</option>
              <option value="Sales - Corporate">Sales - Corporate</option>
              <option value="Sales - Fnb">Sales - Fnb</option>
            </select>
          </div>
          <div className="col-sm-4 col-md-2" >

          </div>
        </div> */}

        <div
          className="row"
          style={{
            width: "100%",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
          }}
          id={styles.head_content}
        >
          {/* {checking_data?.map((ele,index)=>(
    <Order_history_tbl data={ele} year={year} index={index} />
      ))} */}
          {check_loading ? (
            <img
              src={report_loader}
              id={styles.loader}
              style={{ marginTop: "4em" }}
            />
          ) : new_repeat_data?.length == 0 || new_repeat_data == null ? (
            <div className={styles.noData}>
              <img
                src={no_data_icon}
                alt=""
                style={{ height: "200px", width: "200px" }}
              />
              <h4>No Data Available</h4>
              <button onClick={handleLoad} className="btn btn-primary mt-3">
                Reload
              </button>
            </div>
          ) : isChangeView ? (
            <Order_history_tbl_Count
              data={new_repeat_data}
              year={year}
              setDepartment={setDepartment}
              handleDate={handleDate}
              department={department}
              isChangeView={isChangeView}
              setIsChangeView={setIsChangeView}
              theme={theme}
            />
          ) : (
            <Order_history_tbl
              data={new_repeat_data}
              year={year}
              setDepartment={setDepartment}
              handleDate={handleDate}
              department={department}
              isChangeView={isChangeView}
              setIsChangeView={setIsChangeView}
              theme={theme}
            />
          )}
        </div>

        <div className="row mt-4 mb-5">
          {weekly_load ? (
            <img
              src={report_loader}
              id={styles.loader}
              style={{ marginTop: "2em" }}
            />
          ) : (
            <Weekly_client_tbl
              data={weekly_data}
              dept={weekly_department}
              setDept={setWeekly_Department}
              weekly_dates={weekly_dates}
              handleMonth={handleMonth}
              selectedMonth={selectedMonth}
            />
          )}
        </div>
      </div>
      <WebPage_info
        data={
          ["New vs Repeated - Business : This dashboard shows the revenue generated from new and repeat orders quarterly, broken down by salesperson and department for a selected financial year. It includes a 'View Count' button to display the number of orders linked to the revenue. Filters for department and financial year allow to focus on specific areas or time periods. The dashboard helps analyze trends and make informed decisions by comparing new customer sales with repeat business.", "Weekly New Client Added : This dashboard shows the number of new customers added to the system each month, categorized by department. A month filter is available to view the customer count for a specific month. It helps track the growth of new clients and provides insights."]
        }
      />
    </>
  );
}

export default Order_history;
