import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import MiniDrawer from "../components/Minidrawer";
import report_loader from "../Media/ezgif.com-optimize.gif";
import { useDispatch, useSelector } from "react-redux";
import { individual_data, user_data } from "../Redux/actions";
import { useNavigate } from "react-router-dom";
import styles from "../Styles/Table.module.css";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import WebPage_info from "../components/WebPage_info";

function Individual_sales_user_performance_dashboard() {
  const storage = window.sessionStorage;
  let DEPT = storage.getItem("DEPT");
  let user_id = storage.getItem("USER_GUID");
  let emp_code = storage.getItem("EMP_CODE");

  const [department, setDepartment] = useState(
    DEPT == "Corporate Sales"
      ? "C697279E-946B-4402-A428-B4E22E6073C8"
      : DEPT == "FnB Sales"
      ? "C697279E-946B-4402-A428-B4E22E6073C7"
      : ""
  );
  const [user, setUser] = useState(emp_code);
  const [user_list, setUserList] = useState([]);
  const [user_load, setUser_Load] = useState(false);
  const [performance_data, setPerformance_Data] = useState([]);
  const dispatch = useDispatch();

  const check_loading = useSelector((state) => state.data.loading);
  const check_error = useSelector((state) => state.data.error);

  let img = storage.getItem("profile_img");
  let USER_GUID = storage.getItem("USER_GUID");
  let Role_id = storage.getItem("Role_id");

  const navigate = useNavigate();

  // React.useEffect(() => {
  //   if (USER_GUID == "" || USER_GUID == null) {
  //     navigate("/");
  //   }
  // }, [storage]);

  // const user_performance_data = useSelector((state=>))

  useEffect(() => {
    if (
      Role_id != "" &&
      Role_id != null &&
      Role_id != "null" &&
      user_id != ""
    ) {
      dispatch(
        individual_data(user, department, setPerformance_Data, setUser_Load)
      );
    }
  }, [user]);

  useEffect(() => {
    if (Role_id != "" && Role_id != null && Role_id != "null") {
      if (DEPT == "Corporate Sales") {
        setDepartment("C697279E-946B-4402-A428-B4E22E6073C8");
        dispatch(
          user_data("C697279E-946B-4402-A428-B4E22E6073C8", setUserList)
        );
      } else if (DEPT == "FnB Sales") {
        setDepartment("C697279E-946B-4402-A428-B4E22E6073C7");
        dispatch(
          user_data("C697279E-946B-4402-A428-B4E22E6073C7", setUserList)
        );
      } else {
        let i;
      }
    }
  }, []);

  const handleDept = (e) => {
    setDepartment(e.target.value);
    if (e.target.value == "") {
      setUserList([]);
      setUser(user_id);
      // dispatch(
      //   individual_data(user_id, "", setPerformance_Data, setUser_Load)
      // );
    } else {
      dispatch(user_data(e.target.value, setUserList));
    }
  };

  const options = {
    filterType: "checkbox",
  };
  const columns = [
    {
      name: "NAME",
      label: "Entity",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "left", marginLeft: "10px" }}>
              <p style={{ marginLeft: "20px" }}>{row}</p>
            </div>
          );
        },
      },
    },

    {
      name: "Leads_Added",
      label: "Leads Added",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
            color:
              (Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600) ||
              Number(cellValue?.props?.children) >= 600
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "Leads_Assigned",
      label: "Leads Assigned",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
            color:
              (Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600) ||
              Number(cellValue?.props?.children) >= 600
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "Leads_Called",
      label: "Leads Called",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
            color:
              (Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600) ||
              Number(cellValue?.props?.children) >= 600
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "Leads_Discussion",
      label: "Leads Discussed",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
            color:
              (Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600) ||
              Number(cellValue?.props?.children) >= 600
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "Interested",
      label: "Interested",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
            color:
              (Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600) ||
              Number(cellValue?.props?.children) >= 600
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "Meeting_Scheduled",
      label: "Meeting Scheduled",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
            color:
              (Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600) ||
              Number(cellValue?.props?.children) >= 600
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "Meeting_Done",
      label: "Meeting Done",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
            color:
              (Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600) ||
              Number(cellValue?.props?.children) >= 600
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "Proposal_Sent",
      label: "Proposal Sent",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
            color:
              (Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600) ||
              Number(cellValue?.props?.children) >= 600
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },

    {
      name: "Converted",
      label: "Converted",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
            color:
              (Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600) ||
              Number(cellValue?.props?.children) >= 600
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "revenue",
      label: "Revenue",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
              Number(cellValue?.props?.children?.replaceAll(",", "")) < 5000
                ? "#dff3ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    5000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    10000
                ? "#9fdcff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    10000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    15000
                ? "#60c5ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    15000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    20000
                ? "#20aeff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    20000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <=
                    35000
                ? "#008edf"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >
                  35000
                ? "#1D5D9B"
                : "white",
            padding: "10px",
            color:
              (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                20000 &&
                Number(cellValue?.props?.children?.replaceAll(",", "")) <=
                  35000) ||
              Number(cellValue?.props?.children?.replaceAll(",", "")) > 35000
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "outstanding",
      label: "Outstanding",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
              Number(cellValue?.props?.children?.replaceAll(",", "")) < 5000
                ? "#dff3ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    5000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    10000
                ? "#9fdcff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    10000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    15000
                ? "#60c5ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    15000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    20000
                ? "#20aeff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    20000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <=
                    35000
                ? "#008edf"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >
                  35000
                ? "#1D5D9B"
                : "white",
            padding: "10px",
            color:
              (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                20000 &&
                Number(cellValue?.props?.children?.replaceAll(",", "")) <=
                  35000) ||
              Number(cellValue?.props?.children?.replaceAll(",", "")) > 35000
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
  ];

  return (
    <>
      <MiniDrawer />
      <div className="container-fluid pt-3">
        {user_load ? (
          <img
            src={report_loader}
            id={styles.loader}
            style={{ marginTop: "6em" }}
          />
        ) : (
          <div style={{ margin: "auto" }}>
            {" "}
            <MUIDataTable
              title={
                <h5 style={{ textAlign: "left" }}>
                  Individual Sales Performance Leads
                </h5>
              }
              data={performance_data}
              columns={columns}
              options={{
                options: options,
                selectableRows: "none",
                viewColumns: false,
                filter: false,
                print: false,
                responsive: "standard",
                download: false,
                fixedHeader: true,
                tableBodyMaxHeight: "60vh",
                // rowsPerPageOptions:[28,50],
                // count: "28",
                pagination: false,
                setTableProps: () => {
                  return {
                    padding: 0,
                  };
                },
                textLabels: {
                  body: {
                    noMatch: "Data Not Available",
                  },
                },

                customToolbar: () => {
                  return (
                    <div style={{ display: "inline-flex", gap: "15px" }}>
                      {user_id == "1dc4da5a-c0ab-4d12-ad55-f13d8ce1ad5a" ||
                      user_id == "a07b4768-cbab-4269-829b-bfbb17722385" ||
                      user_id == "dd95dec7-893c-45ec-a30f-7aacc506400c" ? (
                        <select
                          name=""
                          onChange={handleDept}
                          id={styles.dept_drop}
                          value={department}
                        >
                          <option value="">All Department</option>
                          <option value="C697279E-946B-4402-A428-B4E22E6073C8">
                            Sales - Corporate
                          </option>
                          <option value="C697279E-946B-4402-A428-B4E22E6073C7">
                            Sales - Fnb
                          </option>
                        </select>
                      ) : (
                        //   <Box
                        //   sx={{
                        //     width: "160px",
                        //     backgroundColor: "white",
                        //     height: "36px",
                        //     borderRadius: "5px",
                        //   }}
                        // >
                        //   <FormControl size="small" fullWidth>

                        //     <Select
                        //       labelId="demo-simple-select-label"
                        //       id="demo-simple-select"
                        //       label="Department"
                        //       style={{
                        //         width: "160px",
                        //         height: "36px",
                        //         fontSize: "14px",
                        //         textAlign: "left",
                        //         backgroundColor:"white"
                        //       }}
                        //     onChange={handleDept}
                        //     value={department}
                        //     >
                        //         <MenuItem value="" >All Department</MenuItem>
                        //     <MenuItem value="C697279E-946B-4402-A428-B4E22E6073C8">
                        //       Sales - Corporate
                        //     </MenuItem>
                        //     <MenuItem value="C697279E-946B-4402-A428-B4E22E6073C7">
                        //       Sales - Fnb
                        //     </MenuItem>
                        //     </Select>
                        //   </FormControl>

                        <></>
                      )}
                      {
                        (user_id == "1dc4da5a-c0ab-4d12-ad55-f13d8ce1ad5a" ||
                          user_id == "a07b4768-cbab-4269-829b-bfbb17722385" ||
                          user_id == "dd95dec7-893c-45ec-a30f-7aacc506400c") &&
                        department == "" ? (
                          <></>
                        ) : (
                          <select
                            name=""
                            id={styles.user_drop}
                            onChange={(e) => setUser(e.target.value)}
                            value={user}
                          >
                            <option value="" defaultValue>
                              Select User
                            </option>
                            {user_list?.map((ele) => (
                              // <option value={[ele.id, ele.text]}>{ele.text}</option>
                              <option value={ele.id}>{ele.text}</option>
                            ))}
                          </select>
                        )

                        //   <FormControl size="small" fullWidth>
                        //   <Select
                        //     labelId="demo-simple-select-label"
                        //     id="demo-simple-select"
                        //     style={{
                        //       width: "160px",
                        //       height: "36px",
                        //       fontSize: "14px",
                        //       textAlign: "left",
                        //       backgroundColor:"white"
                        //     }}
                        //         onChange={(e)=>setUser(e.target.value)}
                        //       value={user}
                        //   >
                        // <MenuItem value="" defaultValue selected  >Select User</MenuItem>
                        //           {user_list?.map((ele) => (
                        //         //<MenuItem value={[ele.id, ele.text]}>{ele.text}</MenuItem>
                        //         <MenuItem value={ele.id}>{ele.text}</MenuItem>
                        //       ))}
                        //   </Select>
                        // </FormControl>
                      }
                    </div>
                  );
                },
              }}
            />
          </div>
        )}
      </div>
      <WebPage_info
        data={[
          "This dashboard shows the overall performance of individual salespersons, including the number of leads added, leads assigned, and the revenue generated by each salesperson, categorized by department. First select a department and then choose a specific salesperson to view their detailed performance.",
        ]}
      />
    </>
  );
}

export default Individual_sales_user_performance_dashboard;
