import React, { useEffect, useState } from "react";
import MiniDrawer from "../components/Minidrawer";
import { DateRangePicker, Stack } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import moment from "moment";
import dayjs from "dayjs";
import Salesperson_invoice_graph from "../components/Salesperson_invoice_graph";
import Salesperson_invoice_tbl from "../components/Salesperson_invoice_tbl";
import styles from "../Styles/Table.module.css";
import report_loader from "../Media/ezgif.com-optimize.gif";
import WebPage_info from "../components/WebPage_info";

function Salespersonwise_invoice() {
  const storage = window.sessionStorage;
  let Role_id = storage.getItem("Role_id");
  let emp_code = storage.getItem("EMP_CODE");
  let dept = storage.getItem("DEPT");
  if(window.location.pathname == "/salesperson_wise_invoice"){
    storage.setItem("admin_param","2")
  }else{
    storage.setItem("admin_param","1")
  }
  let admin_param = storage.getItem("admin_param");
  
  const [selectedFromDate, setSelectedFromDate] = useState(new Date());
  const [selectedToDate, setSelectedToDate] = useState(new Date());

  const [invoice_details, setInvoice_Details] = useState([]);
  const [invoice_amount, setInvoice_Amount] = useState([]);
  const [xLabels, setxLabels] = useState([]);

  const [today_count, setToday_Count] = useState(0);
  const [today_amount, setToday_Amount] = useState(0);
  const [mtd_count, setMtd_Count] = useState(0);
  const [mtd_amount, setMtd_Amount] = useState(0);

  const [graph_load,setGraph_Load] = useState(false);
  const [tbl_load,setTbl_Load] = useState(false);

  const handleDateChange = (new_val) => {
    if (new_val !== null) {
      setSelectedFromDate(new_val[0]);
      setSelectedToDate(new_val[1]);
    } else {
      //   setParams("MTD");
      //   setRange_Flag(false);
      setSelectedFromDate(new Date());
      setSelectedToDate(new Date());
      const fromattedDate = dayjs(new Date());
      let new_date = moment(fromattedDate.$d).format("YYYY-MM-DD");
    }
  };

  const get_salesperson_invoice = async () => {
    setTbl_Load(true)
    try {
      let res = await fetch(
        `https://reportapi.equinoxlab.com/overall_invoice_details_by_dept_tl`
        // `https://reportapi.equinoxlab.com/overall_invoice_details_by_dept`
      ,{
        method:"POST",
        headers:{"Content-Type": "Application/Json"},
        body: JSON.stringify({
          dept_id: (dept == "Tech" || dept == "Admin" || dept == "Management")? admin_param :dept == "FnB Sales"? "1":dept == "Corporate Sales" ? "2":"all",
          emp_code_input:emp_code
        })
      });
      // let res = await fetch(
      //   `https://reportapi.equinoxlab.com/corporate_invoice_details`
      // );
      let data = await res.json();
      setTbl_Load(false)
      setInvoice_Details(data?.data);
    } catch (error) {setTbl_Load(false)}
  };

  const get_invoice_amount = async () => {
    setGraph_Load(true)
    try {
      let res = await fetch(
        `https://reportapi.equinoxlab.com/invoice_amt_by_date_deptwise_tl`,{
        // `https://reportapi.equinoxlab.com/invoice_amt_by_date_deptwise`,{
          method:"POST",
          headers: {"Content-Type":"Application/Json"},
          body:JSON.stringify({
            dept_id: (dept == "Tech" || dept == "Admin" || dept == "Management")? admin_param :dept == "FnB Sales"? "1":dept == "Corporate Sales" ? "2":"all",
            emp_code_input:emp_code
          })
        }
      );
      // let res = await fetch(
      //   `https://reportapi.equinoxlab.com/corporate_amt_by_date`
      // );
      let data = await res.json();
      setGraph_Load(false)
      let value_arr = [];
      let label_arr = [];
      for (let i = 0; i < data?.data?.length; i++) {
        value_arr.push(data?.data?.[i]?.Credit_note_amt);
        label_arr.push(data?.data?.[i]?.Invoice_Date);
      }
      setxLabels(label_arr);
      setInvoice_Amount(value_arr);
    } catch (error) {setGraph_Load(false)}
  };

  useEffect(() => {
    if(Role_id != "" && Role_id != null && Role_id != "null"){
    get_salesperson_invoice();
    get_invoice_amount();
    }
  }, []);

  return (
    <>
      <MiniDrawer />
      <div className="container-fluid pt-3">
        <div className="row">
          <div className="col-9">
          {admin_param == "1"?<h4 style={{ textAlign: "left" }}>Invoice Report Sales Person Wise - ( FnB Sales )</h4>:<h4 style={{ textAlign: "left" }}>Invoice Report Sales Person Wise - ( Corporate Sales )</h4>}
            {/* <h4 style={{ textAlign: "left" }}>
              Invoice Report Sales Person Wise - (Monthly Digest)
            </h4> */}
          </div>
          <div className="col-3">
            {/* <DateRangePicker
                            format = "dd MMM yyyy"
                            value={[selectedFromDate, selectedToDate]}
                            onChange={handleDateChange}
                            size='md'
                          placement='bottomEnd'
                          preventOverflow
                          /> */}
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-sm-12 col-md-4">
           {tbl_load? <img src={report_loader} id={styles.graph_tbl_loader} />:<> <div className="row" id={styles.invoice_info_container}>
              <div className="col-sm-12 col-md-5">
                <div>
                  <p>Today's Invoice</p>
                </div>

                <p>Total Count</p>
                <h5>{today_count}</h5>
              </div>
              <div className="col-sm-12 col-md-6">
                <div>
                  <p>Today's Invoice Amount</p>
                </div>
                <p>Total Amount</p>
                <h5>₹ {today_amount}</h5>
              </div>
            </div>
            <div className="row mt-4" id={styles.invoice_info_container}>
              <div className="col-sm-12 col-md-5">
                <div>
                  <p>MTD Invoice Count</p>
                </div>
                <p>Total Count</p>
                <h5>{mtd_count}</h5>
              </div>
              <div className="col-sm-12 col-md-6">
                <div>
                  <p>MTD Invoice Amount</p>
                </div>
                <p>Total Amount</p>
                <h5>₹ {mtd_amount}</h5>
              </div>
            </div></>}
          </div>
          <div className="col-sm-12 col-md-8">
            <div style={{ width: "60vw", overflow: "auto", margin: "auto" }}>
             {graph_load?<img src={report_loader} id={styles.graph_loader} />: <Salesperson_invoice_graph
                data={invoice_amount}
                xLabels={xLabels}
              />}
            </div>
          </div>
        </div>

        <div className="row mt-3 mb-5">
         {tbl_load?<img src={report_loader} id={styles.graph_loader} />: <Salesperson_invoice_tbl
            data={invoice_details}
            setToday_Count={setToday_Count}
            setToday_Amount={setToday_Amount}
            setMtd_Count={setMtd_Count}
            setMtd_Amount={setMtd_Amount}
          />}
        </div>
      </div>
      <WebPage_info
       data={
        ["This dashboard displays the invoice amounts for the last seven days and provides detailed salesperson-wise invoice data. It includes each salesperson's invoice count and amount for today, the current month, and the previous month."]
       }
      />
    </>
  );
}

export default Salespersonwise_invoice;
