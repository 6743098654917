import React, { useEffect, useState } from 'react'
import MiniDrawer from '../components/Minidrawer'
import { useDispatch } from 'react-redux'
import { get_testing_location_data } from '../Latest_addition_redux/actions'
import MUIDataTable from "mui-datatables";
import styles from "../Styles/Table.module.css";
import report_loader from "../Media/ezgif.com-optimize.gif";
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { useNavigate } from 'react-router-dom';
import WebPage_info from '../components/WebPage_info';

function Testing_location() {
  const storage = window.sessionStorage;
  let Role_id = storage.getItem("Role_id");

    const [testing_load,setTesting_Load] = useState(false)
    const [testing_data,setTesting_Data] = useState([])
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(()=>{
      if(Role_id != "" && Role_id != null && Role_id != "null"){
     dispatch(get_testing_location_data(setTesting_Load,setTesting_Data))
      }
    },[])
  
  const get_total =(val)=>{

   if(val == "M"){
      let total_count = testing_data?.reduce((acc,ele)=>{
        return acc+Number(ele?.M)
     },0)
     return total_count;
   }
   else if(val == "M1"){
      let total_count = testing_data?.reduce((acc,ele)=>{
        return acc+Number(ele?.M1)
     },0)
     return total_count;
   }
   else if(val == "M2"){
      let total_count = testing_data?.reduce((acc,ele)=>{
        return acc+Number(ele?.M2)
     },0)
     return total_count;
   }
   else if(val == "M3"){
      let total_count = testing_data?.reduce((acc,ele)=>{
        return acc+Number(ele?.M3)
     },0)
     return total_count;
   }
   else if(val == "M4"){
      let total_count = testing_data?.reduce((acc,ele)=>{
        return acc+Number(ele?.M4)
     },0)
     return total_count;
   }
   else  if(val == "M5"){
      let total_count = testing_data?.reduce((acc,ele)=>{
        return acc+Number(ele?.M5)
     },0)
     return total_count;
   }
   else if(val == "M6"){
      let total_count = testing_data?.reduce((acc,ele)=>{
        return acc+Number(ele?.M6)
     },0)
     return total_count;
   }
   else if(val == "M7"){
      let total_count = testing_data?.reduce((acc,ele)=>{
        return acc+Number(ele?.M7)
     },0)
     return total_count;
   }
   else if(val == "M8"){
      let total_count = testing_data?.reduce((acc,ele)=>{
        return acc+Number(ele?.M8)
     },0)
     return total_count;
   }
   else if(val == "M9"){
      let total_count = testing_data?.reduce((acc,ele)=>{
        return acc+Number(ele?.M9)
     },0)
     return total_count;
   }
   else if(val == "M10"){
      let total_count = testing_data?.reduce((acc,ele)=>{
        return acc+Number(ele?.M10)
     },0)
     return total_count;
   }
   else if(val == "M11"){
      let total_count = testing_data?.reduce((acc,ele)=>{
        return acc+Number(ele?.M11)
     },0)
     return total_count;
   }
   else if(val == "M12"){
      let total_count = testing_data?.reduce((acc,ele)=>{
        return acc+Number(ele?.M12)
     },0)
     return total_count;
   }
   else if(val == "Q"){
    let total_count = testing_data?.reduce((acc,ele)=>{
      return acc+Number(ele?.Q)
   },0)
   return total_count;
  }
  else if(val == "Q1"){
    let total_count = testing_data?.reduce((acc,ele)=>{
      return acc+Number(ele?.Q1)
   },0)
   return total_count;
  }
  else if(val == "Q2"){
    let total_count = testing_data?.reduce((acc,ele)=>{
      return acc+Number(ele?.Q2)
   },0)
   return total_count;
  }
  else if(val == "Q3"){
    let total_count = testing_data?.reduce((acc,ele)=>{
      return acc+Number(ele?.Q3)
   },0)
   return total_count;
  }
  else if(val == "Q4"){
    let total_count = testing_data?.reduce((acc,ele)=>{
      return acc+Number(ele?.Q4)
   },0)
   return total_count;
  }
  else if(val == "Q5"){
    let total_count = testing_data?.reduce((acc,ele)=>{
      return acc+Number(ele?.Q5)
   },0)
   return total_count;
  }
  else if(val == "Q6"){
    let total_count = testing_data?.reduce((acc,ele)=>{
      return acc+Number(ele?.Q6)
   },0)
   return total_count;
  }
  else if(val == "Y"){
    let total_count = testing_data?.reduce((acc,ele)=>{
      return acc+Number(ele?.Y)
   },0)
   return total_count;
  }
  else if(val == "Y1"){
    let total_count = testing_data?.reduce((acc,ele)=>{
      return acc+Number(ele?.Y1)
   },0)
   return total_count;
  }
  else if(val == "Y2"){
    let total_count = testing_data?.reduce((acc,ele)=>{
      return acc+Number(ele?.Y2)
   },0)
   return total_count;
  }
  else if(val == "Y3"){
    let total_count = testing_data?.reduce((acc,ele)=>{
      return acc+Number(ele?.Y3)
   },0)
   return total_count;
  }
  else if(val == "Y4"){
    let total_count = testing_data?.reduce((acc,ele)=>{
      return acc+Number(ele?.Y4)
   },0)
   return total_count;
  }
  else if(val == "Y5"){
    let total_count = testing_data?.reduce((acc,ele)=>{
      return acc+Number(ele?.Y5)
   },0)
   return total_count;
  }
  else if(val == "Y6"){
    let total_count = testing_data?.reduce((acc,ele)=>{
      return acc+Number(ele?.Y6)
   },0)
   return total_count;
  }
  
  else{
    let x= 1;
  }
  
    }

    const options = {
        filterType: "checkbox",
    
      };
      const columns = [
        {
            name: "Testing_Location",
            label: "Testing Location",
            options: {
              filter: true,
              sort: false,
       
              setCellProps: () => ({
                style: {
                  whiteSpace: "nowrap",
                  position: "sticky",
                  left: "0",
                  background: "white",
                  zIndex: 0,
                  minWidth:"210px",
                  maxWidth:"210px"
                }
              }),
              setCellHeaderProps: () => ({
                style: {
                  whiteSpace: "nowrap",
                  position: "sticky",
                  left: 0,
                  background: "white",
                  zIndex: 1000
                }
              }),
        
              customBodyRender: (row) => {
                return (
                  <div style={{ textAlign: "left" }}>
                    <p style={{ marginLeft: "20%" }}>{row}</p>
                  </div>
                );
              },
            },
          },
        {
            name: "M",
            label: "M",
            options: {
              filter: false,
              sort: true,
              setCellProps: (cellValue) => ({
                style: {
                  backgroundColor:
                    Number(cellValue?.props?.children) >= 0 &&
                    Number(cellValue?.props?.children) < 20
                      ? "#dff3ff"
                      : Number(cellValue?.props?.children) >= 20 &&
                        Number(cellValue?.props?.children) < 40
                      ? "#9fdcff"
                      : Number(cellValue?.props?.children) >= 40 &&
                        Number(cellValue?.props?.children) < 60
                      ? "#60c5ff"
                      : Number(cellValue?.props?.children) >= 60 &&
                        Number(cellValue?.props?.children) < 80
                      ? "#20aeff"
                      : Number(cellValue?.props?.children) >= 80 &&
                        Number(cellValue?.props?.children) <= 600
                      ? "#008edf"
                      : Number(cellValue?.props?.children) >= 600
                      ? "#1D5D9B"
                      : "white",
                       padding:"10px",
                       color:((Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
                      
                },
              }),
              customBodyRender: (row) => {
                return <>{row}</>;
              },
            },
          },
          {
            name: "M1",
            label: "M1",
            options: {
              filter: false,
              sort: true,
              setCellProps: (cellValue) => ({
                style: {
                  backgroundColor:
                    Number(cellValue?.props?.children) >= 0 &&
                    Number(cellValue?.props?.children) < 20
                      ? "#dff3ff"
                      : Number(cellValue?.props?.children) >= 20 &&
                        Number(cellValue?.props?.children) < 40
                      ? "#9fdcff"
                      : Number(cellValue?.props?.children) >= 40 &&
                        Number(cellValue?.props?.children) < 60
                      ? "#60c5ff"
                      : Number(cellValue?.props?.children) >= 60 &&
                        Number(cellValue?.props?.children) < 80
                      ? "#20aeff"
                      : Number(cellValue?.props?.children) >= 80 &&
                        Number(cellValue?.props?.children) <= 600
                      ? "#008edf"
                      : Number(cellValue?.props?.children) >= 600
                      ? "#1D5D9B"
                      : "white",
                                   padding:"10px",
                       color:((Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
                },
              }),
              customBodyRender: (row) => {
                return <>{row}</>;
              },
            },
          },
          {
            name: "M2",
            label: "M2",
            options: {
              filter: false,
              sort: true,
              setCellProps: (cellValue) => ({
                style: {
                  backgroundColor:
                    Number(cellValue?.props?.children) >= 0 &&
                    Number(cellValue?.props?.children) < 20
                      ? "#dff3ff"
                      : Number(cellValue?.props?.children) >= 20 &&
                        Number(cellValue?.props?.children) < 40
                      ? "#9fdcff"
                      : Number(cellValue?.props?.children) >= 40 &&
                        Number(cellValue?.props?.children) < 60
                      ? "#60c5ff"
                      : Number(cellValue?.props?.children) >= 60 &&
                        Number(cellValue?.props?.children) < 80
                      ? "#20aeff"
                      : Number(cellValue?.props?.children) >= 80 &&
                        Number(cellValue?.props?.children) <= 600
                      ? "#008edf"
                      : Number(cellValue?.props?.children) >= 600
                      ? "#1D5D9B"
                      : "white",
                                   padding:"10px",
                       color:((Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
                },
              }),
              customBodyRender: (row) => {
                return <>{row}</>;
              },
            },
          },
          {
            name: "M3",
            label: "M3",
            options: {
              filter: false,
              sort: true,
              setCellProps: (cellValue) => ({
                style: {
                  backgroundColor:
                    Number(cellValue?.props?.children) >= 0 &&
                    Number(cellValue?.props?.children) < 20
                      ? "#dff3ff"
                      : Number(cellValue?.props?.children) >= 20 &&
                        Number(cellValue?.props?.children) < 40
                      ? "#9fdcff"
                      : Number(cellValue?.props?.children) >= 40 &&
                        Number(cellValue?.props?.children) < 60
                      ? "#60c5ff"
                      : Number(cellValue?.props?.children) >= 60 &&
                        Number(cellValue?.props?.children) < 80
                      ? "#20aeff"
                      : Number(cellValue?.props?.children) >= 80 &&
                        Number(cellValue?.props?.children) <= 600
                      ? "#008edf"
                      : Number(cellValue?.props?.children) >= 600
                      ? "#1D5D9B"
                      : "white",
                                   padding:"10px",
                       color:((Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
                },
              }),
              customBodyRender: (row) => {
                return <>{row}</>;
              },
            },
          },
          {
            name: "M4",
            label: "M4",
            options: {
              filter: false,
              sort: true,
              setCellProps: (cellValue) => ({
                style: {
                  backgroundColor:
                    Number(cellValue?.props?.children) >= 0 &&
                    Number(cellValue?.props?.children) < 20
                      ? "#dff3ff"
                      : Number(cellValue?.props?.children) >= 20 &&
                        Number(cellValue?.props?.children) < 40
                      ? "#9fdcff"
                      : Number(cellValue?.props?.children) >= 40 &&
                        Number(cellValue?.props?.children) < 60
                      ? "#60c5ff"
                      : Number(cellValue?.props?.children) >= 60 &&
                        Number(cellValue?.props?.children) < 80
                      ? "#20aeff"
                      : Number(cellValue?.props?.children) >= 80 &&
                        Number(cellValue?.props?.children) <= 600
                      ? "#008edf"
                      : Number(cellValue?.props?.children) >= 600
                      ? "#1D5D9B"
                      : "white",
                                   padding:"10px",
                       color:((Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
                },
              }),
              customBodyRender: (row) => {
                return <>{row}</>;
              },
            },
          },
          {
            name: "M5",
            label: "M5",
            options: {
              filter: false,
              sort: true,
              setCellProps: (cellValue) => ({
                style: {
                  backgroundColor:
                    Number(cellValue?.props?.children) >= 0 &&
                    Number(cellValue?.props?.children) < 20
                      ? "#dff3ff"
                      : Number(cellValue?.props?.children) >= 20 &&
                        Number(cellValue?.props?.children) < 40
                      ? "#9fdcff"
                      : Number(cellValue?.props?.children) >= 40 &&
                        Number(cellValue?.props?.children) < 60
                      ? "#60c5ff"
                      : Number(cellValue?.props?.children) >= 60 &&
                        Number(cellValue?.props?.children) < 80
                      ? "#20aeff"
                      : Number(cellValue?.props?.children) >= 80 &&
                        Number(cellValue?.props?.children) <= 600
                      ? "#008edf"
                      : Number(cellValue?.props?.children) >= 600
                      ? "#1D5D9B"
                      : "white",
                                   padding:"10px",
                       color:((Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
                },
              }),
              customBodyRender: (row) => {
                return <>{row}</>;
              },
            },
          },
          {
            name: "M6",
            label: "M6",
            options: {
              filter: false,
              sort: true,
              setCellProps: (cellValue) => ({
                style: {
                  backgroundColor:
                    Number(cellValue?.props?.children) >= 0 &&
                    Number(cellValue?.props?.children) < 20
                      ? "#dff3ff"
                      : Number(cellValue?.props?.children) >= 20 &&
                        Number(cellValue?.props?.children) < 40
                      ? "#9fdcff"
                      : Number(cellValue?.props?.children) >= 40 &&
                        Number(cellValue?.props?.children) < 60
                      ? "#60c5ff"
                      : Number(cellValue?.props?.children) >= 60 &&
                        Number(cellValue?.props?.children) < 80
                      ? "#20aeff"
                      : Number(cellValue?.props?.children) >= 80 &&
                        Number(cellValue?.props?.children) <= 600
                      ? "#008edf"
                      : Number(cellValue?.props?.children) >= 600
                      ? "#1D5D9B"
                      : "white",
                                   padding:"10px",
                       color:((Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
                },
              }),
              customBodyRender: (row) => {
                return <>{row}</>;
              },
            },
          },
          {
            name: "M7",
            label: "M7",
            options: {
              filter: false,
              sort: true,
              setCellProps: (cellValue) => ({
                style: {
                  backgroundColor:
                    Number(cellValue?.props?.children) >= 0 &&
                    Number(cellValue?.props?.children) < 20
                      ? "#dff3ff"
                      : Number(cellValue?.props?.children) >= 20 &&
                        Number(cellValue?.props?.children) < 40
                      ? "#9fdcff"
                      : Number(cellValue?.props?.children) >= 40 &&
                        Number(cellValue?.props?.children) < 60
                      ? "#60c5ff"
                      : Number(cellValue?.props?.children) >= 60 &&
                        Number(cellValue?.props?.children) < 80
                      ? "#20aeff"
                      : Number(cellValue?.props?.children) >= 80 &&
                        Number(cellValue?.props?.children) <= 600
                      ? "#008edf"
                      : Number(cellValue?.props?.children) >= 600
                      ? "#1D5D9B"
                      : "white",
                                   padding:"10px",
                       color:((Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
                },
              }),
              customBodyRender: (row) => {
                return <>{row}</>;
              },
            },
          },
          {
            name: "M8",
            label: "M8",
            options: {
              filter: false,
              sort: true,
              setCellProps: (cellValue) => ({
                style: {
                  backgroundColor:
                    Number(cellValue?.props?.children) >= 0 &&
                    Number(cellValue?.props?.children) < 20
                      ? "#dff3ff"
                      : Number(cellValue?.props?.children) >= 20 &&
                        Number(cellValue?.props?.children) < 40
                      ? "#9fdcff"
                      : Number(cellValue?.props?.children) >= 40 &&
                        Number(cellValue?.props?.children) < 60
                      ? "#60c5ff"
                      : Number(cellValue?.props?.children) >= 60 &&
                        Number(cellValue?.props?.children) < 80
                      ? "#20aeff"
                      : Number(cellValue?.props?.children) >= 80 &&
                        Number(cellValue?.props?.children) <= 600
                      ? "#008edf"
                      : Number(cellValue?.props?.children) >= 600
                      ? "#1D5D9B"
                      : "white",
                                   padding:"10px",
                       color:((Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
                },
              }),
              customBodyRender: (row) => {
                return <>{row}</>;
              },
            },
          },
          {
            name: "M9",
            label: "M9",
            options: {
              filter: false,
              sort: true,
              setCellProps: (cellValue) => ({
                style: {
                  backgroundColor:
                    Number(cellValue?.props?.children) >= 0 &&
                    Number(cellValue?.props?.children) < 20
                      ? "#dff3ff"
                      : Number(cellValue?.props?.children) >= 20 &&
                        Number(cellValue?.props?.children) < 40
                      ? "#9fdcff"
                      : Number(cellValue?.props?.children) >= 40 &&
                        Number(cellValue?.props?.children) < 60
                      ? "#60c5ff"
                      : Number(cellValue?.props?.children) >= 60 &&
                        Number(cellValue?.props?.children) < 80
                      ? "#20aeff"
                      : Number(cellValue?.props?.children) >= 80 &&
                        Number(cellValue?.props?.children) <= 600
                      ? "#008edf"
                      : Number(cellValue?.props?.children) >= 600
                      ? "#1D5D9B"
                      : "white",
                                   padding:"10px",
                       color:((Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
                },
              }),
              customBodyRender: (row) => {
                return <>{row}</>;
              },
            },
          },
          {
            name: "M10",
            label: "M10",
            options: {
              filter: false,
              sort: true,
              setCellProps: (cellValue) => ({
                style: {
                  backgroundColor:
                    Number(cellValue?.props?.children) >= 0 &&
                    Number(cellValue?.props?.children) < 20
                      ? "#dff3ff"
                      : Number(cellValue?.props?.children) >= 20 &&
                        Number(cellValue?.props?.children) < 40
                      ? "#9fdcff"
                      : Number(cellValue?.props?.children) >= 40 &&
                        Number(cellValue?.props?.children) < 60
                      ? "#60c5ff"
                      : Number(cellValue?.props?.children) >= 60 &&
                        Number(cellValue?.props?.children) < 80
                      ? "#20aeff"
                      : Number(cellValue?.props?.children) >= 80 &&
                        Number(cellValue?.props?.children) <= 600
                      ? "#008edf"
                      : Number(cellValue?.props?.children) >= 600
                      ? "#1D5D9B"
                      : "white",
                                   padding:"10px",
                       color:((Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
                },
              }),
              customBodyRender: (row) => {
                return <>{row}</>;
              },
            },
          },
          {
            name: "M11",
            label: "M11",
            options: {
              filter: false,
              sort: true,
              setCellProps: (cellValue) => ({
                style: {
                  backgroundColor:
                    Number(cellValue?.props?.children) >= 0 &&
                    Number(cellValue?.props?.children) < 20
                      ? "#dff3ff"
                      : Number(cellValue?.props?.children) >= 20 &&
                        Number(cellValue?.props?.children) < 40
                      ? "#9fdcff"
                      : Number(cellValue?.props?.children) >= 40 &&
                        Number(cellValue?.props?.children) < 60
                      ? "#60c5ff"
                      : Number(cellValue?.props?.children) >= 60 &&
                        Number(cellValue?.props?.children) < 80
                      ? "#20aeff"
                      : Number(cellValue?.props?.children) >= 80 &&
                        Number(cellValue?.props?.children) <= 600
                      ? "#008edf"
                      : Number(cellValue?.props?.children) >= 600
                      ? "#1D5D9B"
                      : "white",
                                   padding:"10px",
                       color:((Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
                },
              }),
              customBodyRender: (row) => {
                return <>{row}</>;
              },
            },
          },
          {
            name: "M12",
            label: "M12",
            options: {
              filter: false,
              sort: true,
              setCellProps: (cellValue) => ({
                style: {
                  backgroundColor:
                    Number(cellValue?.props?.children) >= 0 &&
                    Number(cellValue?.props?.children) < 20
                      ? "#dff3ff"
                      : Number(cellValue?.props?.children) >= 20 &&
                        Number(cellValue?.props?.children) < 40
                      ? "#9fdcff"
                      : Number(cellValue?.props?.children) >= 40 &&
                        Number(cellValue?.props?.children) < 60
                      ? "#60c5ff"
                      : Number(cellValue?.props?.children) >= 60 &&
                        Number(cellValue?.props?.children) < 80
                      ? "#20aeff"
                      : Number(cellValue?.props?.children) >= 80 &&
                        Number(cellValue?.props?.children) <= 600
                      ? "#008edf"
                      : Number(cellValue?.props?.children) >= 600
                      ? "#1D5D9B"
                      : "white",
                                   padding:"10px",
                       color:((Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
                },
              }),
              customBodyRender: (row) => {
                return <>{row}</>;
              },
            },
          },
          {
            name: "Q",
            label: "Q",
            options: {
              filter: false,
              sort: true,
              // customHeadLabelRender: () => {
              //   return (
              //     <>
              //       {!show ? (
              //         <div style={{ display: "flex" }} onClick={() => setShow(true)}>
              //           <p
              //             style={{display: "flex",
              //             alignItems: "center" ,fontSize: "15px",
              //             marginTop: "8px",
              //             marginBottom: "0.4rem"}}
              //           >
              //             Q{" "}
              //             <ArrowDownwardIcon sx={{height: "15px"}} />
              //           </p>{" "}
              //         </div>
              //       ) : (
              //         <p style={{ verticalAlign: "middle",   marginTop: "0",
              //         marginBottom: "0.1rem" }}>Q</p>
              //       )}
              //     </>
              //   );
              // },
              setCellProps: (cellValue) => ({
                style: {
                  backgroundColor:
                    Number(cellValue?.props?.children) >= 0 &&
                    Number(cellValue?.props?.children) < 20
                      ? "#dff3ff"
                      : Number(cellValue?.props?.children) >= 20 &&
                        Number(cellValue?.props?.children) < 40
                      ? "#9fdcff"
                      : Number(cellValue?.props?.children) >= 40 &&
                        Number(cellValue?.props?.children) < 60
                      ? "#60c5ff"
                      : Number(cellValue?.props?.children) >= 60 &&
                        Number(cellValue?.props?.children) < 80
                      ? "#20aeff"
                      : Number(cellValue?.props?.children) >= 80 &&
                        Number(cellValue?.props?.children) <= 600
                      ? "#008edf"
                      : Number(cellValue?.props?.children) >= 600
                      ? "#1D5D9B"
                      : "white",
                                   padding:"10px",
                       color:((Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
                },
              }),
              customBodyRender: (row) => {
                return <>{row}</>;
              },
            },
          },
          {
            name: "Q1",
            label: "Q1",
            options: {
              filter: false,
              sort: true,
              setCellProps: (cellValue) => ({
                style: {
                  backgroundColor:
                    Number(cellValue?.props?.children) >= 0 &&
                    Number(cellValue?.props?.children) < 20
                      ? "#dff3ff"
                      : Number(cellValue?.props?.children) >= 20 &&
                        Number(cellValue?.props?.children) < 40
                      ? "#9fdcff"
                      : Number(cellValue?.props?.children) >= 40 &&
                        Number(cellValue?.props?.children) < 60
                      ? "#60c5ff"
                      : Number(cellValue?.props?.children) >= 60 &&
                        Number(cellValue?.props?.children) < 80
                      ? "#20aeff"
                      : Number(cellValue?.props?.children) >= 80 &&
                        Number(cellValue?.props?.children) <= 600
                      ? "#008edf"
                      : Number(cellValue?.props?.children) >= 600
                      ? "#1D5D9B"
                      : "white",
                                   padding:"10px",
                       color:((Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
                },
              }),
              customBodyRender: (row) => {
                return <>{row}</>;
              },
            },
          },
          {
            name: "Q2",
            label: "Q2",
            options: {
              filter: false,
              sort: true,
              setCellProps: (cellValue) => ({
                style: {
                  backgroundColor:
                    Number(cellValue?.props?.children) >= 0 &&
                    Number(cellValue?.props?.children) < 20
                      ? "#dff3ff"
                      : Number(cellValue?.props?.children) >= 20 &&
                        Number(cellValue?.props?.children) < 40
                      ? "#9fdcff"
                      : Number(cellValue?.props?.children) >= 40 &&
                        Number(cellValue?.props?.children) < 60
                      ? "#60c5ff"
                      : Number(cellValue?.props?.children) >= 60 &&
                        Number(cellValue?.props?.children) < 80
                      ? "#20aeff"
                      : Number(cellValue?.props?.children) >= 80 &&
                        Number(cellValue?.props?.children) <= 600
                      ? "#008edf"
                      : Number(cellValue?.props?.children) >= 600
                      ? "#1D5D9B"
                      : "white",
                                   padding:"10px",
                       color:((Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
                },
              }),
              customBodyRender: (row) => {
                return <>{row}</>;
              },
            },
          },
          {
            name: "Q3",
            label: "Q3",
            options: {
              filter: false,
              sort: true,
              setCellProps: (cellValue) => ({
                style: {
                  backgroundColor:
                    Number(cellValue?.props?.children) >= 0 &&
                    Number(cellValue?.props?.children) < 20
                      ? "#dff3ff"
                      : Number(cellValue?.props?.children) >= 20 &&
                        Number(cellValue?.props?.children) < 40
                      ? "#9fdcff"
                      : Number(cellValue?.props?.children) >= 40 &&
                        Number(cellValue?.props?.children) < 60
                      ? "#60c5ff"
                      : Number(cellValue?.props?.children) >= 60 &&
                        Number(cellValue?.props?.children) < 80
                      ? "#20aeff"
                      : Number(cellValue?.props?.children) >= 80 &&
                        Number(cellValue?.props?.children) <= 600
                      ? "#008edf"
                      : Number(cellValue?.props?.children) >= 600
                      ? "#1D5D9B"
                      : "white",
                                   padding:"10px",
                       color:((Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
                },
              }),
              customBodyRender: (row) => {
                return <>{row}</>;
              },
            },
          },
          {
            name: "Q4",
            label: "Q4",
            options: {
              filter: false,
              sort: true,
              setCellProps: (cellValue) => ({
                style: {
                  backgroundColor:
                    Number(cellValue?.props?.children) >= 0 &&
                    Number(cellValue?.props?.children) < 20
                      ? "#dff3ff"
                      : Number(cellValue?.props?.children) >= 20 &&
                        Number(cellValue?.props?.children) < 40
                      ? "#9fdcff"
                      : Number(cellValue?.props?.children) >= 40 &&
                        Number(cellValue?.props?.children) < 60
                      ? "#60c5ff"
                      : Number(cellValue?.props?.children) >= 60 &&
                        Number(cellValue?.props?.children) < 80
                      ? "#20aeff"
                      : Number(cellValue?.props?.children) >= 80 &&
                        Number(cellValue?.props?.children) <= 600
                      ? "#008edf"
                      : Number(cellValue?.props?.children) >= 600
                      ? "#1D5D9B"
                      : "white",
                                   padding:"10px",
                       color:((Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
                },
              }),
              customBodyRender: (row) => {
                return <>{row}</>;
              },
            },
          },
          {
            name: "Q5",
            label: "Q5",
            options: {
              filter: false,
              sort: true,
              setCellProps: (cellValue) => ({
                style: {
                  backgroundColor:
                    Number(cellValue?.props?.children) >= 0 &&
                    Number(cellValue?.props?.children) < 20
                      ? "#dff3ff"
                      : Number(cellValue?.props?.children) >= 20 &&
                        Number(cellValue?.props?.children) < 40
                      ? "#9fdcff"
                      : Number(cellValue?.props?.children) >= 40 &&
                        Number(cellValue?.props?.children) < 60
                      ? "#60c5ff"
                      : Number(cellValue?.props?.children) >= 60 &&
                        Number(cellValue?.props?.children) < 80
                      ? "#20aeff"
                      : Number(cellValue?.props?.children) >= 80 &&
                        Number(cellValue?.props?.children) <= 600
                      ? "#008edf"
                      : Number(cellValue?.props?.children) >= 600
                      ? "#1D5D9B"
                      : "white",
                                   padding:"10px",
                      color:((Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
                },
              }),
              customBodyRender: (row) => {
                return <>{row}</>;
              },
            },
          },
          {
            name: "Q6",
            label: "Q6",
            options: {
              filter: false,
              sort: true,
              setCellProps: (cellValue) => ({
                style: {
                  backgroundColor:
                    Number(cellValue?.props?.children) >= 0 &&
                    Number(cellValue?.props?.children) < 20
                      ? "#dff3ff"
                      : Number(cellValue?.props?.children) >= 20 &&
                        Number(cellValue?.props?.children) < 40
                      ? "#9fdcff"
                      : Number(cellValue?.props?.children) >= 40 &&
                        Number(cellValue?.props?.children) < 60
                      ? "#60c5ff"
                      : Number(cellValue?.props?.children) >= 60 &&
                        Number(cellValue?.props?.children) < 80
                      ? "#20aeff"
                      : Number(cellValue?.props?.children) >= 80 &&
                        Number(cellValue?.props?.children) <= 600
                      ? "#008edf"
                      : Number(cellValue?.props?.children) >= 600
                      ? "#1D5D9B"
                      : "white",
                                   padding:"10px",
                      color:((Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
                },
              }),
              customBodyRender: (row) => {
                return <>{row}</>;
              },
            },
          },
          {
            name: "Y",
            label: "Y",
            options: {
              filter: false,
              sort: true,
              setCellProps: (cellValue) => ({
                style: {
                  backgroundColor:
                    Number(cellValue?.props?.children) >= 0 &&
                    Number(cellValue?.props?.children) < 20
                      ? "#dff3ff"
                      : Number(cellValue?.props?.children) >= 20 &&
                        Number(cellValue?.props?.children) < 40
                      ? "#9fdcff"
                      : Number(cellValue?.props?.children) >= 40 &&
                        Number(cellValue?.props?.children) < 60
                      ? "#60c5ff"
                      : Number(cellValue?.props?.children) >= 60 &&
                        Number(cellValue?.props?.children) < 80
                      ? "#20aeff"
                      : Number(cellValue?.props?.children) >= 80 &&
                        Number(cellValue?.props?.children) <= 600
                      ? "#008edf"
                      : Number(cellValue?.props?.children) >= 600
                      ? "#1D5D9B"
                      : "white",
                                   padding:"10px",
                      color:((Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
                },
              }),
              customBodyRender: (row) => {
                return <>{row}</>;
              },
            },
          },
          {
            name: "Y1",
            label: "Y1",
            options: {
              filter: false,
              sort: true,
              setCellProps: (cellValue) => ({
                style: {
                  backgroundColor:
                    Number(cellValue?.props?.children) >= 0 &&
                    Number(cellValue?.props?.children) < 20
                      ? "#dff3ff"
                      : Number(cellValue?.props?.children) >= 20 &&
                        Number(cellValue?.props?.children) < 40
                      ? "#9fdcff"
                      : Number(cellValue?.props?.children) >= 40 &&
                        Number(cellValue?.props?.children) < 60
                      ? "#60c5ff"
                      : Number(cellValue?.props?.children) >= 60 &&
                        Number(cellValue?.props?.children) < 80
                      ? "#20aeff"
                      : Number(cellValue?.props?.children) >= 80 &&
                        Number(cellValue?.props?.children) <= 600
                      ? "#008edf"
                      : Number(cellValue?.props?.children) >= 600
                      ? "#1D5D9B"
                      : "white",
                                   padding:"10px",
                      color:((Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
                },
              }),
      
              customBodyRender: (row) => {
                return <>{row}</>;
              },
            },
          },
          {
            name: "Y2",
            label: "Y2",
            options: {
              filter: false,
              sort: true,
              setCellProps: (cellValue) => ({
                style: {
                  backgroundColor:
                    Number(cellValue?.props?.children) >= 0 &&
                    Number(cellValue?.props?.children) < 20
                      ? "#dff3ff"
                      : Number(cellValue?.props?.children) >= 20 &&
                        Number(cellValue?.props?.children) < 40
                      ? "#9fdcff"
                      : Number(cellValue?.props?.children) >= 40 &&
                        Number(cellValue?.props?.children) < 60
                      ? "#60c5ff"
                      : Number(cellValue?.props?.children) >= 60 &&
                        Number(cellValue?.props?.children) < 80
                      ? "#20aeff"
                      : Number(cellValue?.props?.children) >= 80 &&
                        Number(cellValue?.props?.children) <= 600
                      ? "#008edf"
                      : Number(cellValue?.props?.children) >= 600
                      ? "#1D5D9B"
                      : "white",
                                   padding:"10px",
                      color:((Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
                },
              }),
              customBodyRender: (row) => {
                return <>{row}</>;
              },
            },
          },
          {
            name: "Y3",
            label: "Y3",
            options: {
              filter: false,
              sort: true,
              setCellProps: (cellValue) => ({
                style: {
                  backgroundColor:
                    Number(cellValue?.props?.children) >= 0 &&
                    Number(cellValue?.props?.children) < 20
                      ? "#dff3ff"
                      : Number(cellValue?.props?.children) >= 20 &&
                        Number(cellValue?.props?.children) < 40
                      ? "#9fdcff"
                      : Number(cellValue?.props?.children) >= 40 &&
                        Number(cellValue?.props?.children) < 60
                      ? "#60c5ff"
                      : Number(cellValue?.props?.children) >= 60 &&
                        Number(cellValue?.props?.children) < 80
                      ? "#20aeff"
                      : Number(cellValue?.props?.children) >= 80 &&
                        Number(cellValue?.props?.children) <= 600
                      ? "#008edf"
                      : Number(cellValue?.props?.children) >= 600
                      ? "#1D5D9B"
                      : "white",
                                   padding:"10px",
                      color:((Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
                },
              }),
              customBodyRender: (row) => {
                return <>{row}</>;
              },
            },
          },
          {
            name: "Y4",
            label: "Y4",
            options: {
              filter: false,
              sort: true,
              setCellProps: (cellValue) => ({
                style: {
                  backgroundColor:
                    Number(cellValue?.props?.children) >= 0 &&
                    Number(cellValue?.props?.children) < 20
                      ? "#dff3ff"
                      : Number(cellValue?.props?.children) >= 20 &&
                        Number(cellValue?.props?.children) < 40
                      ? "#9fdcff"
                      : Number(cellValue?.props?.children) >= 40 &&
                        Number(cellValue?.props?.children) < 60
                      ? "#60c5ff"
                      : Number(cellValue?.props?.children) >= 60 &&
                        Number(cellValue?.props?.children) < 80
                      ? "#20aeff"
                      : Number(cellValue?.props?.children) >= 80 &&
                        Number(cellValue?.props?.children) <= 600
                      ? "#008edf"
                      : Number(cellValue?.props?.children) >= 600
                      ? "#1D5D9B"
                      : "white",
                                   padding:"10px",
                      color:((Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
                },
              }),
              customBodyRender: (row) => {
                return <>{row}</>;
              },
            },
          },
          {
            name: "Y5",
            label: "Y5",
            options: {
              filter: false,
              sort: true,
              setCellProps: (cellValue) => ({
                style: {
                  backgroundColor:
                    Number(cellValue?.props?.children) >= 0 &&
                    Number(cellValue?.props?.children) < 20
                      ? "#dff3ff"
                      : Number(cellValue?.props?.children) >= 20 &&
                        Number(cellValue?.props?.children) < 40
                      ? "#9fdcff"
                      : Number(cellValue?.props?.children) >= 40 &&
                        Number(cellValue?.props?.children) < 60
                      ? "#60c5ff"
                      : Number(cellValue?.props?.children) >= 60 &&
                        Number(cellValue?.props?.children) < 80
                      ? "#20aeff"
                      : Number(cellValue?.props?.children) >= 80 &&
                        Number(cellValue?.props?.children) <= 600
                      ? "#008edf"
                      : Number(cellValue?.props?.children) >= 600
                      ? "#1D5D9B"
                      : "white",
                                   padding:"10px",
                      color:((Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
                },
              }),
      
              customBodyRender: (row) => {
                return <>{row}</>;
              },
            },
          },
          {
            name: "Y6",
            label: "Y6",
            options: {
              filter: false,
              sort: true,
              setCellProps: (cellValue) => ({
                style: {
                  backgroundColor:
                    Number(cellValue?.props?.children) >= 0 &&
                    Number(cellValue?.props?.children) < 20
                      ? "#dff3ff"
                      : Number(cellValue?.props?.children) >= 20 &&
                        Number(cellValue?.props?.children) < 40
                      ? "#9fdcff"
                      : Number(cellValue?.props?.children) >= 40 &&
                        Number(cellValue?.props?.children) < 60
                      ? "#60c5ff"
                      : Number(cellValue?.props?.children) >= 60 &&
                        Number(cellValue?.props?.children) < 80
                      ? "#20aeff"
                      : Number(cellValue?.props?.children) >= 80 &&
                        Number(cellValue?.props?.children) <= 600
                      ? "#008edf"
                      : Number(cellValue?.props?.children) >= 600
                      ? "#1D5D9B"
                      : "white",
                                   padding:"10px",
                      color:((Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
                },
              }),
              customBodyRender: (row) => {
                return <>{row}</>;
              },
            },
          },
    ]
  return (
    <>
    <MiniDrawer/>

    {testing_load ? (
        <img src={report_loader} id={styles.loader} />
      ) : (
        <div className="container-fluid pt-3" id={testing_data.length == 0?"":"table_s6"}>
          <MUIDataTable
            title={
              <h5 style={{ textAlign: "left" }}>Testing Location S6</h5>
            }
            data={testing_data}
            columns={columns}
            options={{
              options: options,
              selectableRows: "none",
              viewColumns: false,
              filter: false,
              print: false,
              responsive: "standard",
              download: false,
              fixedHeader: true,
              pagination:false,
              // tableBodyMaxHeight: "60vh",
              sortOrder: {
                name: 'Q',
                direction: 'desc'
              },
              setTableProps: () => {
                return {
                  padding: 0,
                };
              },
              customToolbar: () => {
                return (
    
                  <>
                  <button onClick={()=>navigate("/testing_location_graph")} id={styles.insights_btn}>Get Insights</button>
                  </>
                  )
              },
              customTableBodyFooterRender: (
                count,
                page,
                rowsPerPage,
                changeRowsPerPage,
                changePage,
                data
              ) => {
                const startIndex = page * rowsPerPage;
                const endIndex = (page + 1) * rowsPerPage;
                return (
                  <TableFooter id={styles.table_footer}>
                    <TableRow>

                    <TableCell  
                     id={styles.first_col}>
                          <p>Total</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("M")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("M1")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("M2")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("M3")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("M4")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("M5")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("M6")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("M7")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("M8")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("M9")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("M10")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("M11")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("M12")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("Q")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("Q1")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("Q2")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("Q3")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("Q4")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("Q5")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("Q6")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("Y")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("Y1")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("Y2")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("Y3")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("Y4")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("Y5")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("Y6")}</p> 
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                );
              },
              textLabels: {
                body: {
                  noMatch: "Data Not Available",
                },
              },
            }}
          />
    </div>)}
    <WebPage_info
    data={[
      "This dashboard displays the count of samples tested at each testing location. It includes a search option to find a specific testing location. Additionally, a toggle is available to switch to a graphical representation of the data for better insights."
    ]}
    />
    </>
  )

}

export default Testing_location