import React, { useEffect } from "react";
import MUIDataTable from "mui-datatables";
import MiniDrawer from "../components/Minidrawer";
import styles from "../Styles/Table.module.css";
import report_loader from "../Media/ezgif.com-optimize.gif";
import { useState } from "react";
import ReactReadMoreReadLess from "react-read-more-read-less";
import dayjs from "dayjs";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { Chip } from "@mui/material";
import { DatePicker, Space } from "antd";
import {
  daily_invoice_report,
  get_user_list,
} from "../Latest_addition_redux/actions";
import Oh_pop_up from "../Modals/Oh_pop_up";

import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import WebPage_info from "../components/WebPage_info";
const { RangePicker } = DatePicker;

function Invoice_daily_report() {
  const storage = window.sessionStorage;
  let dept = storage.getItem("DEPT");
  let user_id = storage.getItem("USER_GUID");
  let Role_id = storage.getItem("Role_id");
  let emp_code = storage.getItem("EMP_CODE");

  if (window.location.pathname == "/invoice_daily") {
    storage.setItem("admin_param", "2");
  } else {
    storage.setItem("admin_param", "1");
  }
  let admin_param = storage.getItem("admin_param");

  const [daily_data, setDaily_Data] = useState([]);
  const [invoice_date, setInvoice_Date] = useState(dayjs(new Date()));
  const check_loading = useSelector((state) => state.data2?.loading);
  const [department, setDepartment] = useState("");
  const [user, setUser] = useState("");
  const [user_data, setUser_Data] = useState([]);
  const [total_amt, setTotal_Amt] = useState(0);

  const [oh_data, setOh_Data] = useState([]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (Role_id != "" && Role_id != null && Role_id != "null") {
      dispatch(
        daily_invoice_report(setDaily_Data, setTotal_Amt, dept, emp_code)
      );
    }
  }, []);

  const handleOH = (new_data) => {
    setOh_Data(new_data);
    handleOpen();
  };

  //   const get_total =()=>{
  //     let total_count = daily_data?.reduce((acc,ele)=>{
  //        return acc+Number(ele?.total_amount)
  //     },0)
  //     return new Intl.NumberFormat("en-IN").format(total_count);
  // }

  const columns = [
    {
      name: "salesperson",
      label: "salesperson",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "oh_number",
      label: "OH Number",
      options: {
        filter: false,
        sort: false,
        download: false,
        customHeadLabelRender: () => {
          return (
            <>
              <p style={{ textAlign: "center" }}>OH Number</p>
            </>
          );
        },

        setCellProps: () => ({
          style: { width: "200px", maxWidth: "200px" },
        }),
        customBodyRender: (row, data) => {
          if (row == "" || row == null) {
            return <>--</>;
          }
          let new_data = row?.split(",");

          if (new_data?.length > 2) {
            return (
              <>
                <p
                  onClick={() => handleOH(new_data)}
                  style={{ color: "#007cc3", cursor: "pointer" }}
                >
                  View
                </p>
              </>
            );
          }

          return (
            <>
              {new_data?.map((ele) => (
                <>
                  <Chip
                    label={ele}
                    color="primary"
                    sx={{
                      marginLeft: "4px",
                      marginTop: "5px",
                      borderRadius: "7px",
                      height: "25px",
                    }}
                    variant="outlined"
                  />
                </>
              ))}
            </>
          );
        },
      },
    },
    {
      name: "total_invoice",
      label: "invoice count",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "total_amount",
      label: "total amount (₹)",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
  };

  return (
    <>
      <MiniDrawer />
      {check_loading ? (
        <img src={report_loader} id={styles.loader} />
      ) : (
        <div
          style={{
            marginLeft: "2%",
            marginTop: "2%",
            marginBottom: "3%",
            width: "96%",
          }}
          id="corporate_tbl"
        >
          <MUIDataTable
            title={
              dept == "Tech" || dept == "Admin" || dept == "Management" ? (
                <h4 style={{ textAlign: "left" }}>
                  Invoice Daily Report ({" "}
                  {admin_param == "1" ? "FnB" : "Corporate"} )
                </h4>
              ) : (
                <h4 style={{ textAlign: "left" }}>Invoice Daily Report</h4>
              )
            }
            data={daily_data}
            columns={columns}
            options={{
              options: options,
              selectableRows: "none",
              viewColumns: false,
              print: false,
              search: false,
              filter: false,
              responsive: "standard",
              download: false,
              fixedHeader: true,
              tableBodyMaxHeight: "600px",
              rowsPerPageOptions: [10, 25, 50, 100],
              setTableProps: () => {
                return {
                  padding: "default",
                };
              },
              customTableBodyFooterRender: (
                count,
                page,
                rowsPerPage,
                changeRowsPerPage,
                changePage,
                data
              ) => {
                const startIndex = page * rowsPerPage;
                const endIndex = (page + 1) * rowsPerPage;
                return (
                  <TableFooter id={styles.table_footer}>
                    <TableRow>
                      {/* <TableCell colSpan={4}></TableCell> */}
                      <TableCell colSpan={3} id={styles.first_col}>
                        {/* <p style={{textAlign:"right"}}>Total (₹)</p> */}
                      </TableCell>
                      <TableCell>
                        <p style={{ textAlign: "center" }}>
                          Total (₹) :{" "}
                          {new Intl.NumberFormat("en-IN").format(total_amt)}
                        </p>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                );
              },

              textLabels: {
                body: {
                  noMatch: "Data Not Available",
                },
              },
            }}
          />
        </div>
      )}

      <Oh_pop_up
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        data={oh_data}
      />

      <WebPage_info
        data={[
          "This dashboard displays the daily invoices created by each salesperson, including the corresponding order count and order amount.",
        ]}
      />
    </>
  );
}

export default Invoice_daily_report;
